import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginLeft: theme.spacing(4),
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
    section: {
        width: '100%',
        display: 'table',
    },
    image: {
        width: '90%',
        float: 'right',
    },
    note: {
        width: '90%',
        textAlign: 'right',
        fontSize: '0.85rem',
        fontStyle: 'italic',
        color: theme.palette.text.disabled,
        marginTop: theme.spacing(4),
    }
}))

export const WelcomeImage: React.FC = () => {
    const classes = useStyles();
    const darkMode = window.localStorage.getItem('dark-mode');

    return (
        <div className={classes.root}>
            <img className={classes.image} src={`../assets/welcome_screen_${darkMode === 'true' ? 'dark' : 'light'}.svg`} />
            <Typography className={classes.note}>From top to bottom: Security rating, Industry Benchmark, Threat intelligence</Typography>
        </div>
    )
}

