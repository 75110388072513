import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { createStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  loading: {
    height: "100%"
  }
}))

export const FullReportLoading:React.FC = () => {
  const classes = useStyles();

  return <Skeleton animation="wave" className={classes.loading} />
}