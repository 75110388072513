import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { ExposureMap } from '../features/exposure-map/ExposureMap';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    }
  })
);

export const ThreatExposurePage: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExposureMap />
    </div>
  );
}
