import { createStyles, Fab, makeStyles } from "@material-ui/core";
import React, {useContext} from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import {useApi} from "src/services/api-provider";
import {SubscriptionContext} from "src/services/subscription-provider/SubscriptionProvider";


type Props = {
    reportName: "attack_surface" | "digital_supply_chain" | "social_threat_intelligence";
}

const useStyles = makeStyles(() =>
    createStyles({
        fab: {
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
        },
    })
);

export const DownloadReportBtn: React.FC<Props> = ({
    reportName
}) => {
    const classes = useStyles();
    const api = useApi();
    const subscriptionId = useContext(SubscriptionContext).current.uuid;

    const requestReport = () => {
        api.Request({
            url: "/api/report",
            method: "POST",
            data: {
                "report_name": reportName,
                "subscription": subscriptionId
            },
            headers: {
                "content-type": "application/pdf",
            },
            responseType: "arraybuffer"
        }).then(res => {
            if (res.type === "success") {
                if (res.data instanceof ArrayBuffer) {
                    const a = window.document.createElement('a');
                    const file = new Blob([res.data], {type: 'application/pdf'});
                    a.href = window.URL.createObjectURL(file);
                    a.download = `${reportName}_${subscriptionId}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            }
        });
    }

    return (
        <Fab color="primary" aria-label="download" className={classes.fab}>
            <GetAppIcon 
                onClick={() => requestReport()}
            />
        </Fab>
    );
};
