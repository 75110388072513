import { createStyles, makeStyles, SvgIconTypeMap, Theme } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import React, { FC } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
        }
    })
);

type Props = {
    icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">>,
}

export const Notice: FC<Props> = ({ icon: Icon, children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Icon fontSize="small" />
            <span>{children}</span>
        </div>
    )
}