import React, { createContext, useState, FC, ReactNode, useCallback } from "react";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";

export type Colour = "primary" | "secondary" | "success" | "warning" | "error" | "info";

type Notice = {
    id: string;
    colour: Colour;
    content: ReactNode;
}

type NoticeService = {
    notices: Notice[];
    add: (colour: Colour, content: ReactNode) => () => void;
}

export const NoticeContext = createContext<NoticeService>({
    notices: [],
    add: () => () => undefined,
});

export const NoticeProvider: FC = ({ children }) => {

    const [notices, setNotices] = useState<Notice[]>([]);

    const add = useCallback((colour: Colour, content: ReactNode) => {
        const id = uuidv4();
        setNotices(notices => notices.concat(notices, { id, content, colour }));
        // Return a function which can be used to remove the notice.
        return () => setNotices(notices => notices.filter(n => n.id !== id));
    }, [setNotices]);

    return (
        <NoticeContext.Provider value={{ notices, add }}>
            {children}
        </NoticeContext.Provider>
    );
}

export const useNotices = (): NoticeService => {
    return useContext(NoticeContext);
}