import React, { FC, useMemo, useState } from 'react';
import { Card, CircularProgress, createStyles, Link, makeStyles, Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Bubble, Bubbles } from './Bubbles';
import { Tree } from './Tree';
import { makeTree } from './make-tree';
import Splash from '../splash/Splash';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { usePostgrest } from 'src/services/postgrest-provider';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
    infoWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%"
    },
    info: {
      width: "60%",
      minHeight: "180px",
      padding: `${theme.spacing(4)}px`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: theme.palette.action.hover
    },
    infoIcon: {
      color: theme.palette.info.main
    }
  })
);

type State = { state: "loading" }
           | { state: "bubbles", bubbles: Bubble[] }
           | { state: "error", error: string }
           | { state: "selected", target: string, bubbles: Bubble[] }


export const ExposureMap: FC = () => {
  const classes = useStyles();
  const postgrest = usePostgrest();

  const [state, setState] = useState<State>({ state: "loading" });

  const fetchTargets = useCallback(() => {
    setState({ state: "loading" });
    postgrest.GetTableSlice("target_domains_validity", ["domain"], "passed=eq.true").then(res => {
      if (res.type === "success") {
        setState({ state: "bubbles", bubbles: res.data.map(v => ({ name: v.domain, size: 1 })) });
      } else {
        setState({ state: "error", error: "couldn't fetch target domains" });
        console.error("fetch target domains", res.message);
      }
    })
  }, [postgrest]);

  useEffect(() => fetchTargets(), [fetchTargets]);

  const selectTarget = (selected: Bubble) => {
    if (state.state !== "bubbles") return;
    setState({ state: "selected", target: selected.name, bubbles: state.bubbles });
  }

  const back = () => {
    if (state.state !== "selected") return;
    setState({ state: "bubbles", bubbles: state.bubbles });
  }


  return (
    <div className={classes.container}>
      {(() => {
        switch (state.state) {
          case "loading":
            return (
              <Splash>
                <CircularProgress />
              </Splash>
            )
          case "error":
            return (
              <Splash>
                <Typography>Error: {state.error}.</Typography>
                <Typography>Click <Link onClick={fetchTargets} href="javascript:;">here</Link> to retry</Typography>
              </Splash>
            )
          case "bubbles":
            return <>
              { 
                state.bubbles.length 
                  ? <Bubbles bubbles={state.bubbles} select={selectTarget} />
                  : <ExposureMapNoData />
              }
            </>
          case "selected":
            return <TreeWithData target={state.target} back={back} />
        }
      })()}
    </div>
  );
}

type TreeWithDataProps = {
  target: string;
  back: () => void;
}

const TreeWithData: FC<TreeWithDataProps> = ({ target, back }) => {
  const postgrest = usePostgrest();
  const tree = useMemo(() => makeTree(postgrest, target), [postgrest, target]);
  const tooltip = useMemo(() => <p>Findings for {target}.</p>, [target]);
  return <Tree name={target} tooltip={tooltip} left={tree[0]} right={tree[1]} back={back}/>
}


const ExposureMapNoData: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.infoWrapper}>
      <Card className={classes.info}>
        <InfoRoundedIcon fontSize="large" className={classes.infoIcon}/>
        <Typography align="center" variant="h5">
          Diagram will be displayed after target domains are verified
        </Typography>
      </Card>
    </div>
  )
}