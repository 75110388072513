import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react"
import { Nav } from "./Nav";
import { Logo } from './Logo';
import { Controls } from "./Controls";
import AuthView from "../auth-view/AuthView";
import { NoticeBanner } from "../notice-banner/NoticeBanner";


type Props = {
    children: JSX.Element | Array<JSX.Element>
}

const leftWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            overflow: 'hidden',
            flexGrow: 1,
        },
        fullscreen: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
        },
        left: {
            width: `${leftWidth}px`,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            alignItems: 'stretch',
            background: theme.palette.background.component,
        },
        user: {
            marginLeft: theme.spacing(2),
            alignSelf: 'flex-start',
        },
        controls: {
            borderTop: `1px solid ${theme.palette.divider}`,
            paddingTop: theme.spacing(1),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2.5),
            marginRight: theme.spacing(2),
        },
        content: {
            flexGrow: 1,
            overflow: 'auto',
            flexShrink: 0,
        },
        logo: {
            width: '100%',
            height: 'auto',
            padding: theme.spacing(2),
        },
        logoAppBar: {
            height: '1.1em',
            width: 'auto',
        },
        expand: {
            flexGrow: 1,
        },
        placeholder: {
            backgroundColor: theme.palette.supplementary.main,
            color: theme.palette.text.primary,
            padding: theme.spacing(1),
            minHeight: 100,
        },
    }),
);

export const Layout: React.FC<Props> = ({
    children,
}: Props) => {
    const classes = useStyles();

    const contentWidth = `calc(100% - ${leftWidth}px)`;

    return (
        <div className={classes.fullscreen}>
            <NoticeBanner />
            <div className={classes.root}>
                <div className={classes.left}>
                    <Logo className={classes.logo} />
                    <Nav />
                    <div className={classes.expand}></div>
                    <AuthView className={classes.user} />
                    <Controls className={classes.controls} />
                </div>

                <div className={classes.content} style={{ width: contentWidth }}>
                    {children}
                </div>
            </div>
        </div>
    );
}
