import React from "react";
import {
    AuBankAccountElement,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    FpxBankElement,
    IbanElement,
    IdealBankElement,
} from "@stripe/react-stripe-js";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import StripeInput from "./StripeInput";

export type StripeElement =
    | typeof AuBankAccountElement
    | typeof CardCvcElement
    | typeof CardExpiryElement
    | typeof CardNumberElement
    | typeof FpxBankElement
    | typeof IbanElement
    | typeof IdealBankElement;

type StripeTextFieldProps<T extends StripeElement> = Omit<
    TextFieldProps,
    "onChange" | "inputComponent" | "inputProps"
> & {
    inputProps?: React.ComponentProps<T>;
    labelErrorMessage?: string;
    onChange?: React.ComponentProps<T>["onChange"];
    stripeElement?: T;
};

const StripeTextDefaultProps = {
    inputProps: {},
    labelErrorMessage: "",
    onChange: () => {
        console.log("onChange");
    },
    stripeElement: null,
};

export const StripeTextField = <T extends StripeElement>(
    props: StripeTextFieldProps<T>
): JSX.Element => {
    const {
        InputLabelProps,
        InputProps = {},
        inputProps,
        error,
        stripeElement,
        ...other
    } = props;

    return (
        <TextField
            fullWidth
            variant="outlined"
            InputLabelProps={{
                ...InputLabelProps,
                shrink: true,
            }}
            error={error}
            InputProps={{
                ...InputProps,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                inputProps: {
                    ...inputProps,
                    ...InputProps.inputProps,
                    component: stripeElement,
                },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                inputComponent: StripeInput,
            }}
            {...other}
        />
    );
};

StripeTextField.defaultProps = StripeTextDefaultProps;

export function StripeTextFieldNumber(
    props: StripeTextFieldProps<typeof CardNumberElement>
): JSX.Element {
    return (
        <StripeTextField
            {...props}
            label="Card Number"
            stripeElement={CardNumberElement}
        />
    );
}

StripeTextFieldNumber.defaultProps = StripeTextDefaultProps;

export function StripeTextFieldExpiry(
    props: StripeTextFieldProps<typeof CardExpiryElement>
): JSX.Element {
    return (
        <StripeTextField
            {...props}
            label="Expiry Date"
            stripeElement={CardExpiryElement}
        />
    );
}

StripeTextFieldExpiry.defaultProps = StripeTextDefaultProps;

export function StripeTextFieldCVC(
    props: StripeTextFieldProps<typeof CardCvcElement>
): JSX.Element {
    return (
        <StripeTextField
            {...props}
            label="CVC/CVV"
            stripeElement={CardCvcElement}
        />
    );
}

StripeTextFieldCVC.defaultProps = StripeTextDefaultProps;
