import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react"
import { Logo } from './Logo';
import { Link } from "react-router-dom";
import { DarkModeSwitch, LogoutButton } from "./Controls";
import AuthView from "../auth-view/AuthView";

type Props = {
    children: JSX.Element | Array<JSX.Element>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        header: {
            display: 'flex',
            paddingRight: theme.spacing(2),
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(2),
                marginRight: 0,
            }
        },
        logo: {
            width: 220,
            height: 'auto',
        },
        content: {
            background: theme.palette.background.paper,
            flexGrow: 1,
        }
    }),
);

export const BasicLayout: React.FC<Props> = ({ children }: Props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Link to="/">
                    <Logo className={classes.logo}/>
                </Link>
                <div style={{flexGrow: 1}}/>
                <DarkModeSwitch />
                <AuthView/>
                <LogoutButton/>
            </div>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    );
}