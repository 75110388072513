import { createStyles, FormControlLabel, IconButton, makeStyles, Switch, Tooltip, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import React, {useContext} from "react";
import { useAuth } from "../../services/auth-provider/AuthProvider";
import { LayoutContext } from "./LayoutProvider";


const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
        alignSelf: 'stretch',
    },
}));

type Props = {
    className?: string;
}

export const Controls: React.FC<Props> = ({ className }: Props) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} ${className}`}>
            <DarkModeSwitch/>
            <div style={{ flexGrow: 1 }}></div>
            <LogoutButton/>
        </div>
    );
}


export const DarkModeSwitch: React.FC = () => {
    const { darkMode, toggleDarkMode } = useContext(LayoutContext);

    return (
        <FormControlLabel 
            control={<Switch size="small" checked={darkMode} onChange={toggleDarkMode} name="Dark Mode" />}
            label={<Typography variant="caption">Dark Mode</Typography>}
        />
    )
}

export const LogoutButton: React.FC = () => {
    const auth = useAuth();
    return (
        <Tooltip title="Logout">
            <IconButton size="small" color="inherit" onClick={() => auth.logout()}>
                <ExitToApp fontSize="small"/>
            </IconButton>
        </Tooltip>
    );
}