import { createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { InvitationTable } from './InvitationTable';
import DeleteSubscriptionDialog from './SubscriptionDeleteDialog';
import { SubscriptionContext } from '../../services/subscription-provider/SubscriptionProvider';
import { UserTable } from './UserTable';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            flexGrow: 1,
            display: 'flex',
            minHeight: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: theme.palette.background.default,
            '& > *': {
               marginBottom: theme.spacing(1.5)
            }
        },
        spacer: {
            height: theme.spacing(3)
        },
        label: {
            marginLeft: theme.spacing(0.2),
            lineHeight: '1.5rem',
        },
        value: {
           padding: `${theme.spacing(0.1)}px ${theme.spacing(0.6)}px`,
        }
    })
);

export const SubscriptionPage: React.FC = (): JSX.Element => {

    const classes = useStyles();
    const subscription = useContext(SubscriptionContext).current;

    return (
        <Paper elevation={0} className={classes.root}>
            <div>
                <Typography component="h2" variant="h6">Subscription Name</Typography>
                <Typography variant='h3' className={classes.value}>{subscription.name}</Typography>
            </div>
            <div className={classes.spacer}/>
            <Typography component="h2" variant="h6">Users</Typography>
            <UserTable/>
            <div className={classes.spacer}/>
            <Typography component="h2" variant="h6">Invitations</Typography>
            <Typography variant="body2">
                Invite a user by pressing the add button below and entering the user&apos;s email address.
                Send the resulting link to the user, who should follow the link to sign up and accept the invite.
                The user must sign up with the given email address.
            </Typography>
            <InvitationTable/>
            <div className={classes.spacer}/>
            <DeleteSubscriptionDialog />
        </Paper>
    );
}