import React, { useCallback, useEffect, useState } from "react";
import { startOfYesterday } from "date-fns/esm";
import { compareAsc, formatRFC3339, startOfToday } from "date-fns";
import {
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
    useTheme,
    Typography,
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import Splash from "../../splash/Splash";
import { ChartData, DonutChart } from "./DonutChart";
import { RowSlice, usePostgrest } from "src/services/postgrest-provider";
import { LinkButton } from "src/elements/LinkButton/LinkButton";

type DataRow = RowSlice<
    "issues",
    "resolved" | "severity" | "first_seen" | "title" | "category"
>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: "flex",
            alignItems: "center",
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.background.component,
            padding: theme.spacing(1),
            userSelect: "none",
        },
        legend: {
            fontSize: "1rem",
            color: theme.palette.text.primary,
            lineHeight: "30px",
            marginLeft: "20px",
            marginTop: "-7%",
        },
        line: {
            strokeWidth: "1px",
            stroke: theme.palette.divider,
        },
    })
);

const issueCategory = {
    CategoryAttackSurfaceExposure: "Attack Surface Exposure",
    CategoryDigitalSupplyChain: "Digital Supply Chain",
    CategorySocialThreatIntelligence: "Social Threat Intelligence",
};

const [lowSeverity, mediumSeverity, highSeverity, criticalSeverity] = [
    {
        name: "Low",
        value: 3,
        color: "",
        light_color: "",
    },
    {
        name: "Medium",
        value: 6,
        color: "",
        light_color: "",
    },
    {
        name: "High",
        value: 8,
        color: "",
        light_color: "",
    },
    {
        name: "Critical",
        value: 9,
        color: "",
        light_color: "",
    },
];

export const HeroWidget: React.FC = () => {
    const postgrest = usePostgrest();
    const classes = useStyles();

    const theme = useTheme();
    criticalSeverity.color = theme.palette.severity.critical.main;
    criticalSeverity.light_color = alpha(
        theme.palette.severity.critical.main,
        0.5
    );
    highSeverity.color = theme.palette.severity.high.main;
    highSeverity.light_color = alpha(theme.palette.severity.high.main, 0.5);
    mediumSeverity.color = theme.palette.severity.medium.main;
    mediumSeverity.light_color = alpha(theme.palette.severity.medium.main, 0.5);
    lowSeverity.color = theme.palette.severity.low.main;
    lowSeverity.light_color = alpha(theme.palette.severity.low.main, 0.5);

    const legendBackground = theme.palette.background.mask;

    const legendColor = theme.palette.text.primary;

    type State =
        | { key: "loading" }
        | { key: "ok"; data: DataRow[] }
        | { key: "error"; error: string };

    const [state, setState] = useState<State>({ key: "loading" });

    const getIssues = useCallback(() => {
        setState({ key: "loading" });
        // Most Recent issues
        const yesterday = encodeURIComponent(formatRFC3339(startOfYesterday()));
        postgrest
            .GetTableSlice(
                "issues",
                ["resolved", "severity", "first_seen", "title", "category"],
                `or=(resolved.is.null,resolved.gt.${yesterday})`
            )
            .then((res) => {
                if (res.type === "error") {
                    setState({ key: "error", error: res.message });
                } else {
                    setState({ key: "ok", data: res.data });
                }
            });
    }, [postgrest]);

    useEffect(() => getIssues(), [getIssues]);

    if (state.key === "loading") {
        return (
            <Splash>
                <CircularProgress />
            </Splash>
        );
    }

    if (state.key === "error") {
        return (
            <Splash>
                <Typography style={{ margin: "30px 0" }}>
                    <LinkButton onClick={() => getIssues()}>
                        Try again.
                    </LinkButton>
                </Typography>
            </Splash>
        );
    }

    const totalDataChart = provideDataChart(true, state.data, "");

    return (
        <div>
            <div className={classes.content}>
                <svg
                    viewBox="0 0 200 208"
                    width="24%"
                    className={classes.legend}
                >
                    <g fill={legendColor}>
                        <rect
                            width="200"
                            height="208"
                            fill={legendBackground}
                        ></rect>
                        <text x="25" y="40" style={{ fontWeight: "bold" }}>
                            Severity summary
                        </text>
                        {totalDataChart.issueSeverities.map(
                            (severity, index) => (
                                <text key={index}>
                                    <tspan
                                        x="35"
                                        y={45 + 30 * (index + 1)}
                                        textAnchor="end"
                                        fill={severity.color}
                                    >
                                        {severity.value}
                                    </tspan>
                                    <tspan x="42" y={45 + 30 * (index + 1)}>
                                        {severity.name} Issue{severity.value === 1 ? "" : "s"}
                                    </tspan>
                                </text>
                            )
                        )}
                    </g>
                </svg>
                <svg
                    viewBox="0 0 100 85"
                    style={{ width: "80%", marginLeft: "-2%" }}
                >
                    <DonutChart data={totalDataChart} width={52} x={0} y={-5} />
                    <DonutChart
                        data={provideDataChart(
                            false,
                            state.data,
                            issueCategory.CategoryAttackSurfaceExposure
                        )}
                        width={27}
                        x={46}
                        y={-28}
                    />
                    <line
                        x1="47.1"
                        y1="23.7"
                        x2="49"
                        y2="22.3"
                        className={classes.line}
                    />
                    <DonutChart
                        data={provideDataChart(
                            false,
                            state.data,
                            issueCategory.CategoryDigitalSupplyChain
                        )}
                        width={38}
                        x={59}
                        y={2}
                    />
                    <line
                        className={classes.line}
                        x1="51.2"
                        y1="38.8"
                        x2="60.2"
                        y2="40.1"
                    />
                    <DonutChart
                        data={provideDataChart(
                            false,
                            state.data,
                            issueCategory.CategorySocialThreatIntelligence
                        )}
                        width={25}
                        x={40}
                        y={28}
                    />
                    <line
                        className={classes.line}
                        x1="40.6"
                        y1="58"
                        x2="44"
                        y2="61.9"
                    />
                </svg>
            </div>
        </div>
    );
};

function provideDataChart(
    isTotalInfo: boolean,
    rows: DataRow[],
    category: string
) {
    const today = startOfToday();

    // Issues open right now: issues with resolved = NULL
    const todayIssues =
        category !== ""
            ? rows.filter((row) => !row.resolved && row.category === category)
            : rows.filter((row) => !row.resolved);

    // Issues open yesterday: (issues with resolved NULL OR resolved > yesterday) AND first_seen <= yesterday
    const previousIssues =
        category !== ""
            ? rows.filter(
                  (row) =>
                      compareAsc(row.first_seen, today) === -1 &&
                      row.category === category
              )
            : rows.filter((row) => compareAsc(row.first_seen, today) === -1);

    const todayScore =
        todayIssues.length > 0
            ? todayIssues.reduce((sum, issue) => sum + issue.severity, 0) /
              todayIssues.length
            : 0;

    const data: ChartData = {
        isTotalChart: isTotalInfo,
        category,
        count: todayIssues.length,
        differenceWithPrevious: todayIssues.length - previousIssues.length,
        score: todayScore.toFixed(1),
        issueSeverities: [],
    };

    data.issueSeverities.push(
        {
            name: criticalSeverity.name,
            value:
                todayIssues.length > 0
                    ? todayIssues.filter(
                          (issue) =>
                              issue.severity > highSeverity.value &&
                              issue.severity <= criticalSeverity.value
                      ).length
                    : 0,
            color: data.isTotalChart
                ? criticalSeverity.color
                : criticalSeverity.light_color,
        },
        {
            name: highSeverity.name,
            value:
                todayIssues.length > 0
                    ? todayIssues.filter(
                          (issue) =>
                              issue.severity > mediumSeverity.value &&
                              issue.severity <= highSeverity.value
                      ).length
                    : 0,
            color: data.isTotalChart
                ? highSeverity.color
                : highSeverity.light_color,
        },
        {
            name: mediumSeverity.name,
            value:
                todayIssues.length > 0
                    ? todayIssues.filter(
                          (issue) =>
                              issue.severity > lowSeverity.value &&
                              issue.severity <= mediumSeverity.value
                      ).length
                    : 0,
            color: data.isTotalChart
                ? mediumSeverity.color
                : mediumSeverity.light_color,
        },
        {
            name: lowSeverity.name,
            value:
                todayIssues.length > 0
                    ? todayIssues.filter(
                          (issue) => issue.severity <= lowSeverity.value
                      ).length
                    : 0,
            color: data.isTotalChart
                ? lowSeverity.color
                : lowSeverity.light_color,
        }
    );

    return data;
}
