import React from "react";
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { WhatsNewRecord } from "./whats-new";
import { WhatsNewCarousel } from "./WhatsNewCarousel";
import { WhatsNewLoading } from "./WhatsNewLoading";
import { RequestStatus } from "../../../../src/shared/enums";

type Props = {
  status: number;
  data: WhatsNewRecord[]; 
  getData: () => void; 
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    full: {
      width: "100%",
      height: "100%",
      color: theme.palette.text.primary
    },
    whatsNew: {
      maxWidth: "300px",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      minHeight: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '201px'
    }
  })
);

export const WhatsNew: React.FC<Props> = ({
  status,
  data,
  getData
}) => {
  const classes = useStyles();

  let content;

  if (status === RequestStatus.Pending) {
    content = (
      <WhatsNewLoading />
    );
  } else if (status === RequestStatus.Success) {
    content = (
      <WhatsNewCarousel
        whatsNewRecords={data}
      />
    );
  } else if (status === RequestStatus.Error) {
    content = (
        <Button onClick={getData}>
          Refresh
        </Button>
    );
  }
  return (
    <div className={classes.whatsNew}>
      {content}
    </div>
  );
};
