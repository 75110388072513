import React from "react";
import {IssueHistoryInSevenDays} from "src/pages/IssueHistoryInSevenDays";

export const WeeklyReport: React.FC = () => {
    return (
        <>
            <IssueHistoryInSevenDays />
            {/* Further widgets implement here */}
        </>
    )
}
