import { ThemeOptions } from "@material-ui/core";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

// Additional color palette for severity levels
declare module "@material-ui/core/styles/createPalette" {
    interface TypeBackground {
        default: string;
        paper: string;
        mask: string;
        component: string;
    }
    interface Palette {
        supplementary: Palette["primary"];
        severity: {
            low: Palette["primary"];
            medium: Palette["primary"];
            high: Palette["primary"];
            critical: Palette["primary"];
        };
        table: {
            head: {
                background: string;
                text: string;
            };
        };
        fold: {
            background: string;
            text: string;
        };
        filter: {
            background: string;
            outline: string;
            title: string;
            text: string;
        }
    }
    interface PaletteOptions {
        supplementary: PaletteOptions["primary"];
        severity: {
            low: PaletteOptions["primary"];
            medium: PaletteOptions["primary"];
            high: PaletteOptions["primary"];
            critical: PaletteOptions["primary"];
        };
        table: {
            head: {
                background: string;
                text: string;
            };
        };
        fold: {
            background: string;
            text: string;
        };
        filter: {
            background: string;
            outline: string;
            title: string;
            text: string;
        }
    }
}

const customTypography: TypographyOptions = {
    fontFamily: "'Poppins', sans-serif",
    h1: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "2.25rem",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: 0,
    },
    h2: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "1.25rem",
        fontWeight: 700,
        lineHeight: "36px",
        letterSpacing: 0,
    },
    h3: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "1rem",
        fontWeight: 600,
        lineHeight: "19px",
        letterSpacing: 0,
    },
    h4: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "36px",
        letterSpacing: 0,
    },
    h5: {
        fontFamily: "'Poppins', sans-serif",
    },
    h6: {
        fontFamily: "'Poppins', sans-serif",
    },
    subtitle1: {
        fontFamily: "'Poppins', sans-serif",
    },
    subtitle2: {
        fontFamily: "'Poppins', sans-serif",
    },
    body1: {
        fontFamily: "'Poppins', sans-serif",
    },
    body2: {
        fontFamily: "'Poppins', sans-serif",
    },
    caption: {
        fontFamily: "'Poppins', sans-serif",
    },
    button: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: 0,
    },
    overline: {
        fontFamily: "'Poppins', sans-serif",
    },
};

export const dark: ThemeOptions = {
    typography: customTypography,
    palette: {
        type: "dark",
        primary: {
            main: "#E95B48",
            light: "#EA897C",
            dark: "#F14C27",
        },
        secondary: {
            main: "#4F5458",
            light: "#797C80",
            dark: "#32383D",
        },
        background: {
            default: "#0E151B",
            paper: "#181D23",
            mask: "rgba(66, 70, 74, 0.7)",
            component: "#181D23",
        },
        text: {
            primary: "rgba(255,255,255,0.87)",
            secondary: "rgba(255,255,255,0.78)",
            disabled: "rgba(255,255,255,0.6)",
        },
        error: {
            main: "#E9C159",
            light: "#EAD08D",
            dark: "#F0B92C",
        },
        warning: {
            main: "#E9C159",
            light: "#EAD08D",
            dark: "#F0B92C",
        },
        info: {
            main: "#3697D7",
            light: "#73B5E1",
            dark: "#2C84BE",
        },
        success: {
            main: "#6AC996",
            light: "#97D0B1",
            dark: "#3CD482",
        },
        divider: "rgba(255,255,255,0.15)",
        supplementary: {
            main: "#213070",
            light: "#414B77",
            dark: "#25305F",
        },
        severity: {
            low: {
                main: "#DFE264",
                light: "#CBCD76",
                dark: "#D9DE21",
            },
            medium: {
                main: "#FCE258",
                light: "#F8E47D",
                dark: "#F5D52D",
            },
            high: {
                main: "#F2943E",
                light: "#F9B06C",
                dark: "#F08C30",
            },
            critical: {
                main: "#E95B48",
                light: "#EA897C",
                dark: "#F84922",
            },
        },
        table: {
            head: {
                background: "#464A4F",
                text: "rgba(255,255,255,0.78)",
            },
        },
        fold: {
            background: "#2A2E34",
            text: "rgba(255,255,255,0.78)",
        },
        filter: {
            background: "#181D23",
            outline: "rgba(255,255,255,0.3)",
            title: "rgba(255,255,255,0.6)",
            text: "rgba(255,255,255,0.78)",
        }
    },
};

export const light: ThemeOptions = {
    typography: customTypography,
    palette: {
        type: "light",
        primary: {
            main: "#E24D2C",
            light: "#F14C27",
            dark: "#C3482D",
        },
        secondary: {
            main: "#B1B1B1",
            light: "#D5D7DA",
            dark: "#8C8C8C",
        },
        background: {
            default: "#FFF",
            paper: "#FAFAFA",
            mask: "rgba(242,242,242,0.7)",
            component: "#FAFAFA",
        },
        text: {
            primary: "rgba(0,0,0,0.87)",
            secondary: "rgba(0,0,0,0.78)",
            disabled: "rgba(0,0,0,0.6)",
        },
        error: {
            main: "#DFAB25",
            light: "#F0B92C",
            dark: "#C69A29",
        },
        warning: {
            main: "#DFAB25",
            light: "#F0B92C",
            dark: "#C69A29",
        },
        info: {
            main: "#2687CE",
            light: "#1597F5",
            dark: "#196CA8",
        },
        success: {
            main: "#3FC17B",
            light: "#3CD482",
            dark: "#2E9D61",
        },
        divider: "rgba(0,0,0,0.15)",
        supplementary: {
            main: "#4D5D9E",
            light: "#9DA9D7",
            dark: "#414B76",
        },
        severity: {
            low: {
                main: "#B7BB2A",
                light: "#CBCD76",
                dark: "#7A7D28",
            },
            medium: {
                main: "#D3B930",
                light: "#F8E47D",
                dark: "#A6932E",
            },
            high: {
                main: "#DA802E",
                light: "#F9B06C",
                dark: "#B7702F",
            },
            critical: {
                main: "#E24D2C",
                light: "#F14C27",
                dark: "#C3482D",
            },
        },
        table: {
            head: {
                background: "#E1E0E0",
                text: "rgba(0,0,0,0.78)",
            },
        },
        fold: {
            background: "#BCBCBC",
            text: "rgba(0,0,0,0.78)",
        },
        filter: {
            background: "#FAFAFA",
            outline: "rgba(0,0,0,0.3)",
            title: "rgba(0,0,0,0.6)",
            text: "rgba(0,0,0,0.78)",
        }
    },
};
