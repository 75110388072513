import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DetectedServiceProvider } from '../services/full-report';
import { RequestStatus } from 'src/shared/enums';
import { FullReportLoading } from '../molecules/FullReportLoading';
import { Fade, Typography } from '@material-ui/core';
import { DownloadReportBtn } from './DownloadReportBtn';


type Props = {
  data: DetectedServiceProvider[];
  status: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: theme.palette.table.head.background,
      color: theme.palette.table.head.text,
    }
  })
);

export const ServiceProviders:React.FC<Props> = ({
  data,
  status
}) => {
  const classes = useStyles();

  if (status === RequestStatus.Pending) {
    return <FullReportLoading />
  }

  return (
      <Fade in={!!data}>
          <>
              <DownloadReportBtn reportName="digital_supply_chain"/>
              <TableContainer>
                  <Table
                      className={classes.table}
                      aria-label="Service Providers table"
                  >
                      <TableHead className={classes.head}>
                          <TableRow>
                              <TableCell>
                                  <Typography variant="subtitle2">
                                      Domain
                                  </Typography>
                              </TableCell>
                              <TableCell>
                                  <Typography variant="subtitle2">
                                      Provider
                                  </Typography>
                              </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {data.map((row, index) => (
                              <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                      {row.domain}
                                  </TableCell>
                                  <TableCell>{row.service_provider}</TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                  </Table>
              </TableContainer>
          </>
      </Fade>
  );
}