import { createStyles, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { PageData } from "src/services/postgrest-provider";
import { Loader } from "../Loader/Loader";
import { PageControl } from "./PageControl";


type Props<T> = {
    pages: PageData<T>;
    render: (data: T) => JSX.Element;
    item?: string;
};

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        "& > *:first-child": {
            flexGrow: 1,
        }
    },
}));

type GetPage<T> = PageData<T>["pages"][number]["load"];

export const Paging = <T,>({ pages: _pages, render, item = "item" }: Props<T>): JSX.Element => {

    // Pages is used to render the buttons for each page. We get it from the props
    // by default, but override it whenever we fetch a new page (we receive updated
    // paging headers from the API).
    const [pages, setPages] = useState<PageData<T>>(_pages);
    useEffect(() => setPages(_pages), [_pages]);

    // We pass getPage is a function to fetch the selected page. It defaults to the pages
    // from the props, and is overriden when the user clicks on a page button.
    const getInitialPage: GetPage<T> = useCallback(async () => ({ type: "success", status: 200, headers: [], data: _pages }), [_pages]);
    const [getPage, setGetPage] = useState(() => getInitialPage);
    useEffect(() => setGetPage(() => getInitialPage), [getInitialPage]);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Loader fetch={getPage} callback={setPages} render={page => render(page.current.data)}/>
            <PageControl item={item} data={pages} pageSelected={page => setGetPage(() => page.load)}/>
        </div>
    );
}

