import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import {IssuesByDomainDownloadReportBtn} from "./IssuesByDomainDownloadReportBtn";

export type CountBySeverity = {
    critical: number;
    high: number;
    medium: number;
    low: number;
};

export type IssuesByDomainListItemProps = {
    domain: string;
    domain_id: number;
    maxCount: number;
    counts: CountBySeverity;
};

export const DEFAULT_LIST_ITEM: IssuesByDomainListItemProps = {
    domain: "",
    domain_id: 0,
    maxCount: 0,
    counts: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
    },
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
        },
        labels: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        bar: {
            position: "relative",
            "& > div": {
                height: 6,
                borderRadius: 3,
                display: "inline-block",
                position: "absolute",
                "&.critical": {
                    background: theme.palette.severity.critical.main,
                },
                "&.high": {
                    background: theme.palette.severity.high.main,
                },
                "&.medium": {
                    background: theme.palette.severity.medium.main,
                },
                "&.low": {
                    background: theme.palette.severity.low.main,
                },
            },
        },
    })
);

export const IssuesByDomainListItem: React.FC<IssuesByDomainListItemProps> = ({
    domain,
    domain_id,
    maxCount,
    counts,
}) => {
    const classes = useStyles();

    const totalCount = Object.values(counts).reduce((prev, count) => {
        return prev + count;
    }, 0);

    return (
        <div className={classes.root}>
            <div className={classes.labels}>
                <div style={{ display: "inline-flex", alignItems: "center"}}>
                    <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                        {domain}
                    </Typography>
                    <IssuesByDomainDownloadReportBtn domain={domain} domain_id={domain_id}/>
                </div>
                <Typography variant="h4" style={{ fontWeight: 700 }}>
                    {totalCount} issue{totalCount === 1 ? "" : "s"}
                </Typography>
            </div>
            <div className={classes.bar}>
                <div
                    className="low"
                    style={{
                        width: `${
                            ((counts.low +
                                counts.medium +
                                counts.high +
                                counts.critical) /
                                maxCount) *
                            100
                        }%`,
                    }}
                ></div>
                <div
                    className="medium"
                    style={{
                        width: `${
                            ((counts.medium + counts.high + counts.critical) /
                                maxCount) *
                            100
                        }%`,
                    }}
                ></div>
                <div
                    className="high"
                    style={{
                        width: `${
                            ((counts.high + counts.critical) / maxCount) * 100
                        }%`,
                    }}
                ></div>
                <div
                    className="critical"
                    style={{ width: `${(counts.critical / maxCount) * 100}%` }}
                ></div>
            </div>
        </div>
    );
};
