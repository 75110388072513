import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { add, endOfToday, formatRFC3339, startOfDay, sub } from "date-fns/esm";
import React, { useCallback, useEffect, useState } from "react";
import { StrokeStyle } from "src/elements/Charts/LineChart/const";
import { 
    CriticalRiskIssuesWidget,
    HighRiskIssuesWidget,
    MediumRiskIssuesWidget,
    LowRiskIssuesWidget,
    NewIssuesWidget,
    RemainingIssuesWidget,
    TotalIssuesWidget,
    ClosedIssuesWidget
} from "src/features/issue-history";
import { IssueHistoryChartData } from "src/features/issue-history/IssueHistory";
import {
    IssueHistoryData,
    IssueHistoryWidgetProps,
} from "src/features/issue-history/const";
import {
    isCriticalRiskIssue,
    isHighRiskIssue,
    isLowRiskIssue,
    isMediumRiskIssue,
} from "src/features/issue-history/utils";
import { usePostgrest } from "src/services/postgrest-provider";

const DAYS_IN_PERIOD = 7;

const CURR_PERIOD_STYLE_TEMPLATE: IssueHistoryChartData = {
    values: [],
    stroke: {
        style: "normal",
        width: "2",
    },
    label: "This 7 days",
    showValue: true,
    display: true,
};

const PREV_PERIOD_STYLE_TEMPLATE: IssueHistoryChartData = {
    values: [],
    stroke: {
        style: "dot",
        width: "1",
    },
    label: "Last 7 days",
    showValue: false,
    display: true,
};

type GroupedIssueHistoryData = {
    date: string;
    data: IssueHistoryData[];
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        historyCharts: {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            gap: theme.spacing(0.5),
            width: "100%",
        },
        severityCharts: {
            width: "50%",
            "& > div": {
                margin: "0.3125rem 0",
            },
        },
        statusCharts: {
            width: "50%",
            "& > div": {
                margin: "0.3125rem 0",
                backgroundColor: theme.palette.background.paper,
            },
        },
    })
);

/**
 *
 * @param startDate The start date of the issue history
 * @param endDate The end date of the issue history
 * @returns Struct data for grouping issues by date
 *  E.g.
 *  [{
 *      date: "01/01/2022"      // Locale Date String
 *      data: [],
 *  }, ...]
 */
const initialGroupedData = (
    startDate: Date,
    endDate: Date
): GroupedIssueHistoryData[] => {
    const initialDataSet: GroupedIssueHistoryData[] = [];

    let targetDate = startDate;

    while (targetDate <= endDate) {
        const dateString = targetDate.toLocaleDateString();
        initialDataSet.push({
            date: dateString,
            data: [],
        });

        targetDate = add(targetDate, { days: 1 });
    }

    return initialDataSet;
};

/**
 * Get line style base on category
 * @param type Data type represent [current] or [previous]
 * @param isSeverity Determine if line is for severity
 * @returns Line style for rendering line in chart
 */
const getLineStyle = (
    type: "current" | "previous",
    isSeverity: boolean
): IssueHistoryChartData => {
    const currPeriodStroke: StrokeStyle = {
        color: isSeverity ? "#0E151B" : "",
        style: "normal",
        width: "2",
    };
    const prevPeriodStroke: StrokeStyle = {
        color: isSeverity ? "#0E151B" : "",
        style: "dot",
        width: "1",
    };

    if (type === "current") {
        return Object.assign({}, CURR_PERIOD_STYLE_TEMPLATE, {
            stroke: currPeriodStroke,
        });
    } else {
        return Object.assign({}, PREV_PERIOD_STYLE_TEMPLATE, {
            stroke: prevPeriodStroke,
        });
    }
};

/**
 * Split data of whole period into two arrays
 * @param data Counts of data in time range
 * @returns Two separated data represented every 7 days data
 */
const divideData = (data: number[]): [number[], number[]] => {
    const middleIndex = Math.ceil(data.length / 2);
    const prevPeriodData = data.slice().splice(0, middleIndex);
    const currPeriodData = data.slice().splice(-middleIndex);

    return [prevPeriodData, currPeriodData];
};

const filterData = (
    data: GroupedIssueHistoryData[],
    severity: "critical" | "high" | "medium" | "low"
): GroupedIssueHistoryData[] => {
    const result: GroupedIssueHistoryData[] = [];

    data.forEach((item) => {
        const filteredData = Object.assign(
            {},
            {
                ...item,
                data: item.data.filter((issue) => {
                    if (severity === "critical") {
                        return isCriticalRiskIssue(issue);
                    } else if (severity === "high") {
                        return isHighRiskIssue(issue);
                    } else if (severity === "medium") {
                        return isMediumRiskIssue(issue);
                    } else {
                        return isLowRiskIssue(issue);
                    }
                }),
            }
        );
        result.push(filteredData);
    });

    return result;
};

const filterCriticalRiskData = (
    data: GroupedIssueHistoryData[]
): GroupedIssueHistoryData[] => {
    return filterData(data, "critical");
};

const filterHighRiskData = (
    data: GroupedIssueHistoryData[]
): GroupedIssueHistoryData[] => {
    return filterData(data, "high");
};
const filterMediumRiskData = (
    data: GroupedIssueHistoryData[]
): GroupedIssueHistoryData[] => {
    return filterData(data, "medium");
};
const filterLowRiskData = (
    data: GroupedIssueHistoryData[]
): GroupedIssueHistoryData[] => {
    return filterData(data, "low");
};
export const IssueHistoryInSevenDays: React.FC = () => {
    const classes = useStyles();
    const postgrest = usePostgrest();
    const theme = useTheme();
    const [dataReady, setDataReady] = useState<boolean>(false);

    const [criticalRiskIssuesDataSet, setCriticalRiskIssuesDataSet] =
        useState<IssueHistoryWidgetProps>();
    const [highRiskIssuesDataSet, setHighRiskIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [mediumRiskIssuesDataSet, setMediumRiskIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [lowRiskIssuesDataSet, setLowRiskIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [totalIssuesDataSet, setTotalIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [newIssuesDataSet, setNewIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [remainingIssuesDataSet, setRemainingIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [closedIssuesDataSet, setClosedIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();

    const getIssuesData = useCallback(
        async (startDate: Date, endDate: Date): Promise<IssueHistoryData[]> => {
            const encodedStartDate = encodeURIComponent(
                formatRFC3339(startDate)
            );
            const encodedEndDate = encodeURIComponent(formatRFC3339(endDate));

            const response = await postgrest.GetTableSlice(
                "issues",
                ["severity", "first_seen", "last_seen", "resolved"],
                `last_seen=gte.${encodedStartDate}&last_seen=lte.${encodedEndDate}&order=last_seen`
            );

            if (response.type === "success") {
                console.log(response.data);
                return response.data;
            } else {
                console.error("Failed retrieving data");
                return [];
            }
        },
        [postgrest]
    );

    /**
     * Construct Props structure for widget IssueHistoryWidget
     */
    const constructWidgetProps = useCallback(
        (data: number[], isSeverity = false) => {
            const [prevPeriodData, currPeriodData] = divideData(data);

            // Get [total]
            const thisPeriodSum = currPeriodData.reduce((sum, prev) => sum + prev, 0);
            const thisPeriodAverage = thisPeriodSum === 0 ? 0 : Math.ceil(thisPeriodSum / prevPeriodData.length);

            const lastPeriodSum = prevPeriodData.reduce((sum, prev) => sum + prev, 0);
            const lastPeriodAverage = lastPeriodSum === 0 ? 0 : Math.ceil(lastPeriodSum / prevPeriodData.length);

            // Get [difference]
            const difference = thisPeriodAverage - lastPeriodAverage;

            // Get [dataSet]
            const currPeriodChartData = Object.assign(
                {},
                getLineStyle("current", isSeverity),
                { values: currPeriodData }
            );
            const prevPeriodChartData = Object.assign(
                {},
                getLineStyle("previous", isSeverity),
                { values: prevPeriodData }
            );

            const widgetProps: IssueHistoryWidgetProps = {
                total: thisPeriodAverage,
                difference: difference,
                numberOfDays: DAYS_IN_PERIOD,
                dataSet: [currPeriodChartData, prevPeriodChartData],
            };

            return widgetProps;
        },
        []
    );

    useEffect(() => {
        const fetchData = async () => {
            /**
             * Set date/time range
             */
            const today = endOfToday();

            /**
             * Note:
             *  - Require 14 days records but getting 15 days instead.
             *    The reason for getting extra 1 day is used to calculate first remaining issues
             * - Today Remaining = Yesterday's total - Today's closed
             */
            const startOfLastPeriod = startOfDay(sub(today, { weeks: 2 }));

            // Retrieve all issues in database
            const issuesData = await getIssuesData(startOfLastPeriod, today);

            const newIssuesDataSet: GroupedIssueHistoryData[] =
                initialGroupedData(startOfLastPeriod, today);
            const closedIssuesDataSet: GroupedIssueHistoryData[] =
                initialGroupedData(startOfLastPeriod, today);
            const totalIssuesDataSet: GroupedIssueHistoryData[] =
                initialGroupedData(startOfLastPeriod, today);

            issuesData.forEach((issue) => {
                /**
                 * New issues
                 */
                if (
                    new Date(issue.first_seen) >= startOfLastPeriod &&
                    new Date(issue.first_seen) <= today
                ) {
                    const firstSeenDateString = new Date(
                        issue.first_seen
                    ).toLocaleDateString();

                    const newIssueTargetDateGroup = newIssuesDataSet.find(
                        (item) => item.date === firstSeenDateString
                    );
                    if (newIssueTargetDateGroup) {
                        newIssueTargetDateGroup.data.push(issue);
                    }
                }

                if (issue.resolved) {
                    /**
                     * Closed issues
                     */
                    if (
                        new Date(issue.resolved) >= startOfLastPeriod &&
                        new Date(issue.resolved) <= today
                    ) {
                        const resolvedDateString = new Date(
                            issue.resolved
                        ).toLocaleDateString();

                        const closedIssueTargetDateGroup =
                            closedIssuesDataSet.find(
                                (item) => item.date === resolvedDateString
                            );
                        if (closedIssueTargetDateGroup) {
                            closedIssueTargetDateGroup.data.push(issue);
                        }
                    }
                } else {
                    /**
                     * Total daily issues
                     */
                    const lastSeenDateString = new Date(
                        issue.last_seen
                    ).toLocaleDateString();

                    console.log(lastSeenDateString);

                    const totalIssueTargetDateGroup = totalIssuesDataSet.find(
                        (item) => item.date === lastSeenDateString
                    );
                    if (totalIssueTargetDateGroup) {
                        totalIssueTargetDateGroup.data.push(issue);
                    }

                }
            });

            /**
             * Filter out issues by severity
             */
            const criticalRiskIssuesDataSet =
                filterCriticalRiskData(totalIssuesDataSet);
            const highRiskIssuesDataSet =
                filterHighRiskData(totalIssuesDataSet);
            const mediumRiskIssuesDataSet =
                filterMediumRiskData(totalIssuesDataSet);
            const lowRiskIssuesDataSet = filterLowRiskData(totalIssuesDataSet);

            /**
             * Transforming issues into counts
             */
            const newIssuesCounts = newIssuesDataSet.map(
                (data) => data.data.length
            );
            const closedIssuesCounts = closedIssuesDataSet.map(
                (data) => data.data.length
            );
            const totalIssuesCounts = totalIssuesDataSet.map(
                (data) => data.data.length
            );
            const criticalRiskIssuesCounts = criticalRiskIssuesDataSet.map(
                (data) => data.data.length
            );
            const highRiskIssuesCounts = highRiskIssuesDataSet.map(
                (data) => data.data.length
            );
            const mediumRiskIssuesCounts = mediumRiskIssuesDataSet.map(
                (data) => data.data.length
            );
            const lowRiskIssuesCounts = lowRiskIssuesDataSet.map(
                (data) => data.data.length
            );

            /**
             * Remaining issues is calculated as:
             * - Today Remaining = Yesterday's total - Today's closed + Today's new open issues
             */
            const remainingIssuesCounts: number[] = [];
            closedIssuesCounts.forEach((closedCount, index) => {
                if (index === 0) {
                    remainingIssuesCounts.push(0);
                } else {
                    remainingIssuesCounts.push(
                        totalIssuesCounts[index - 1] - closedCount + newIssuesCounts[index]
                    );
                }
            });

            /**
             * Removing first item which were used to calculate the first remaining issues count
             */
            criticalRiskIssuesCounts.shift();
            highRiskIssuesCounts.shift();
            mediumRiskIssuesCounts.shift();
            lowRiskIssuesCounts.shift();
            totalIssuesCounts.shift();
            newIssuesCounts.shift();
            remainingIssuesCounts.shift();
            closedIssuesCounts.shift();

            const criticalRiskIssuesWidgetProps: IssueHistoryWidgetProps =
                constructWidgetProps(criticalRiskIssuesCounts, true);
            const highRiskIssuesWidgetProps: IssueHistoryWidgetProps =
                constructWidgetProps(highRiskIssuesCounts, true);
            const mediumRiskIssuesWidgetProps: IssueHistoryWidgetProps =
                constructWidgetProps(mediumRiskIssuesCounts, true);
            const lowRiskIssuesWidgetProps: IssueHistoryWidgetProps =
                constructWidgetProps(lowRiskIssuesCounts, true);
            const totalIssuesWidgetProps: IssueHistoryWidgetProps =
                constructWidgetProps(totalIssuesCounts);
            const newIssuesWidgetProps: IssueHistoryWidgetProps =
                constructWidgetProps(newIssuesCounts);
            const remainingIssuesWidgetProps: IssueHistoryWidgetProps =
                constructWidgetProps(remainingIssuesCounts);
            const closedIssuesWidgetProps: IssueHistoryWidgetProps =
                constructWidgetProps(closedIssuesCounts);

            setTotalIssuesDataSet(totalIssuesWidgetProps);
            setNewIssuesDataSet(newIssuesWidgetProps);
            setRemainingIssuesDataSet(remainingIssuesWidgetProps);
            setClosedIssuesDataSet(closedIssuesWidgetProps);
            setCriticalRiskIssuesDataSet(criticalRiskIssuesWidgetProps);
            setHighRiskIssuesDataSet(highRiskIssuesWidgetProps);
            setMediumRiskIssuesDataSet(mediumRiskIssuesWidgetProps);
            setLowRiskIssuesDataSet(lowRiskIssuesWidgetProps);
            setDataReady(true);
        };

        fetchData().catch((error) => console.error(error));
    }, [constructWidgetProps, getIssuesData]);

    return (
        <div className={classes.root}>
            {dataReady && (
                <div className={classes.historyCharts}>
                    <div className={classes.severityCharts}>
                        <div>
                            {criticalRiskIssuesDataSet && (
                                <CriticalRiskIssuesWidget {...criticalRiskIssuesDataSet} />
                            )}
                        </div>
                        <div>
                            {highRiskIssuesDataSet && (
                                <HighRiskIssuesWidget {...highRiskIssuesDataSet} />
                            )}
                        </div>
                        <div>
                            {mediumRiskIssuesDataSet && (
                                <MediumRiskIssuesWidget {...mediumRiskIssuesDataSet} />
                            )}
                        </div>
                        <div>
                            {lowRiskIssuesDataSet && (
                                <LowRiskIssuesWidget {...lowRiskIssuesDataSet} />
                            )}
                        </div>
                    </div>
                    <div className={classes.statusCharts}>
                        <div>
                            {totalIssuesDataSet && (
                                <TotalIssuesWidget {...totalIssuesDataSet} backgroundColor={theme.palette.background.paper} />
                            )}
                        </div>
                        <div>
                            {newIssuesDataSet && (
                                <NewIssuesWidget {...newIssuesDataSet} backgroundColor={theme.palette.background.paper} />
                            )}
                        </div>
                        <div>
                            {remainingIssuesDataSet && (
                                <RemainingIssuesWidget {...remainingIssuesDataSet} backgroundColor={theme.palette.background.paper} />
                            )}
                        </div>
                        <div>
                            {closedIssuesDataSet && (
                                <ClosedIssuesWidget {...closedIssuesDataSet} backgroundColor={theme.palette.background.paper} />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
