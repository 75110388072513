import React, { useRef, useState } from "react";
import {
    Button,
    createStyles,
    FormControl,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    TextFieldProps,
    Theme,
    Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

type Props = {
    searchData: (text: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "80%",
        },
        form: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            flexDirection: "row",
            gap: theme.spacing(1),
        },
        inputField: {
            width: "70%",
            height: "3rem",
            "&::placeholder": {
                color: theme.palette.secondary.light,
                opacity: 1,
            },
            "& > *": {
                height: "100%",
            },
        },
        inputSearchIcon: {
            color: theme.palette.secondary.main,
        },
        searchButton: {
            width: "30%",
            textTransform: "none",
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
        },
        clearButton: {
            color: theme.palette.primary.main,
        },
    })
);

export const IssueSearchInput: React.FC<Props> = ({ searchData }) => {
    const classes = useStyles();
    const [searchText, setSearchText] = useState<string>("");
    const [focusState, setFocusState] = useState<boolean>(false);

    const inputRef = useRef<TextFieldProps>();

    const onSearch = () => {
        searchData(searchText);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        if (event.target.value === "") {
            searchData("");
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            searchData(searchText);
        }
    };

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocusState(event.type === "focus");
    };

    const handleClearBtnClick = () => {
        setSearchText("");
        searchData("");
    };

    const startAdornment = () => {
        if (focusState || searchText !== "") {
            return "";
        }

        return (
            <InputAdornment position="start">
                <SearchIcon className={classes.inputSearchIcon} />
            </InputAdornment>
        );
    };

    const endAdornment = () => {
        if (searchText === "") {
            return "";
        }

        return (
            <InputAdornment position="end">
                <IconButton
                    onClick={handleClearBtnClick}
                    color="primary"
                    disableRipple
                >
                    <ClearIcon />
                </IconButton>
            </InputAdornment>
        );
    };

    return (
        <div className={classes.root}>
            <FormControl fullWidth variant="outlined" className={classes.form}>
                <TextField
                    className={classes.inputField}
                    InputProps={{
                        startAdornment: startAdornment(),
                        endAdornment: endAdornment(),
                    }}
                    placeholder="Search for keywords"
                    inputRef={inputRef}
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleOnFocus}
                    onBlur={handleOnFocus}
                    value={searchText}
                />
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.searchButton}
                    onClick={onSearch}
                >
                    <Typography variant="h3">Search</Typography>
                </Button>
            </FormControl>
        </div>
    );
};
