import {IssueHistoryData} from "./const";

export const isCriticalRiskIssue = (data: IssueHistoryData): boolean => {
    return data.severity >= 9;
}

export const isHighRiskIssue = (data: IssueHistoryData): boolean => {
    return data.severity >= 7 && data.severity < 9;
}


export const isMediumRiskIssue = (data: IssueHistoryData): boolean => {
    return data.severity >= 4 && data.severity < 7;
}

export const isLowRiskIssue = (data: IssueHistoryData): boolean => {
    return data.severity >= 1 && data.severity < 4;
}

export const isClosedIssue = (data: IssueHistoryData): boolean => {
    return data.resolved !== null;
}

export const isOpenedIssue = (data: IssueHistoryData): boolean => {
    return !isClosedIssue(data);
}

