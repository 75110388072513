import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Chip, Popover, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import jwt from 'jsonwebtoken';
import { useAuth } from '../../services/auth-provider/AuthProvider';

const useStyles = makeStyles((theme: Theme) => createStyles({
  infoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  infoBox: {
    '& > * + *': {
      paddingTop: theme.spacing(1),
      marginTop: theme.spacing(1),
      borderTop: '1px solid ' + theme.palette.divider
    },
  }
}))

interface RefreshToken {
  exp: number
}

type Props = {
  className?: string;
}

export default function AuthView({className}: Props): JSX.Element {

  const classes = useStyles()
  const [ anchor, setAnchor ] = useState<HTMLElement | null>(null)
  const auth = useAuth();

  // Controls for auth info popover
  const close = () => setAnchor(null)
  const open = (event : React.MouseEvent<HTMLElement>) => setAnchor(event.currentTarget)

  const user = (
    <Button onClick={open} startIcon={<AccountCircle/>} color="inherit">
      <Typography variant="body2">
        {auth.user.profile.name}
      </Typography>
    </Button>
  )
  const expires = new Date(auth.user.expires_at * 1000)
  const badge = auth.user.expired ? <Chip label="expired" size="small" color="secondary" />  : <React.Fragment/>
  const accessInfo = (
    <Box>
      <div className={classes.infoHeader}>
        <Typography variant="subtitle2">
          Access Token
        </Typography>
        {badge}
      </div>
      <Typography>{auth.user.profile.name} ({auth.user.profile.email})</Typography>
      <Typography>Expire{auth.user.expired ? "d" : "s"}: {expires.toLocaleString()}</Typography>
    </Box>
  )

  let refreshInfo = <React.Fragment/>
    
  if (auth.user.refresh_token) {
    const refresh = jwt.decode(auth.user.refresh_token) as RefreshToken
    const expires = new Date(refresh.exp * 1000)
    const expired = expires < new Date()
    const badge = expired ? <Chip label="expired" size="small" color="secondary" />  : <React.Fragment/>
    refreshInfo = (
      <Box>
        <div className={classes.infoHeader}>
          <Typography variant="subtitle2">Refresh Token</Typography>
          {badge}
        </div>
        <Typography>Expire{expired ? "d" : "s"}: {expires.toLocaleString()}</Typography>
      </Box>
    )
  }

  const info = (
    <Popover open={anchor !== null} anchorEl={anchor} onClose={close}>
      <Card>
        <CardContent className={classes.infoBox}>
          {accessInfo}
          {refreshInfo}
        </CardContent>
      </Card>
    </Popover>
  )

  return <div className={className}>{user} {info}</div>
}


