import React, { FC, useMemo } from 'react';
import { Group } from '@visx/group';
import { LinkHorizontal } from '@visx/shape';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Node } from './Node';
import { TreeNode } from './TreeNode';
import { TinyColor, readability } from '@ctrl/tinycolor';
import { hierarchy, HierarchyPointNode } from 'd3-hierarchy';
import { flextree } from 'd3-flextree';
import { SVGContainer } from './SVGContainer';
import useForceUpdate from 'src/misc/forceUpdate';

/**
 * Lightens the given fg colour such that it has atleast the given
 * readable score against the given bg colour.
 * @param fg Foreground colour
 * @param bg Background colour
 * @param readable Target readability score (21 = black on white, 1 = black on black)
 * @returns Adjusted colour
 */
function lightenColour(fg: TinyColor, bg: TinyColor, readable = 2) {
  let c = new TinyColor(fg);
  while (readability(c, bg) < readable) {
    c = c.lighten(1);
  }
  return c.toHexString();
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      stroke: lightenColour(new TinyColor(theme.palette.supplementary.main), new TinyColor(theme.palette.background.paper)),
      strokeWidth: 2,
      fill: "none",
    },
    findingsLink: {
      stroke: lightenColour(new TinyColor(theme.palette.primary.main), new TinyColor(theme.palette.background.paper)),
    },
  }),
);

const layout = flextree<TreeNode>({
  nodeSize: d => {
    const size = d.data.getSize();
    return [size.height, size.width + 80];
  },
  spacing: (a, b) => {
    const pad = a.data.isCategory() || b.data.isCategory() ? 16 : 0
    return (a.path(b).length - 3) * 5 + 3 + pad
  }
});

export type Props = {
    name: string;
    tooltip?: JSX.Element;
    left: TreeNode[];
    right: TreeNode[];
    back: () => void;
};

export type Size = {
  width: number;
  height: number;
}

// Minimal interface which is fulfilled by d3.HierarchyPointNode.
interface HierarchyNode<T> {
  x: number;
  y: number;
  data: T;
}

export const Tree: FC<Props> = ({ name, tooltip, left, right, back }: Props) => {

  const classes = useStyles();

  const [leftRoot, rightRoot] = useMemo(() => [left, right].map(c => new TreeNode({
    name: name,
    tooltip: tooltip,
    getChildren: async () => ({ type: "success", children: c})
  })), [left, right, name, tooltip]);

  const [, forceUpdate] = useForceUpdate();
  leftRoot.setNotify(() => forceUpdate());
  rightRoot.setNotify(() => forceUpdate());

  // Layout trees for rendering
  const leftTree = layout(hierarchy(leftRoot, getChildren));
  const rightTree = layout(hierarchy(rightRoot, getChildren));

  // Swap x and y so we render horizontally
  rightTree.each(node => {
    [node.x, node.y] = [node.y, node.x];
  });
  leftTree.each(node => {
    [node.x, node.y] = [-node.y, node.x];
  });

  // Shift left children to account for reversed direction
  leftTree.children && leftTree.children.forEach(start => {
    start.each(node => {
      node.x = node.x - node.data.getSize().width + leftTree.data.getSize().width;
    });
  });

  const toggle = (node: HierarchyPointNode<TreeNode>) => () => {
    if (!node.parent) {
      leftTree.data.toggle();
      rightTree.data.toggle();
    } else {
      node.data.toggle();
    }
  }

  return (
    <SVGContainer back={back}>
      {({width, height, setTooltip}) => (
        <Group top={height/2} left={width/2 - leftTree.data.getSize().width/2}>
          {[leftTree, rightTree].map((tree, i) => (
            <Group key={i}>
              {tree.links().map((link, i) => (
                <LinkHorizontal
                  className={`${classes.link} ${link.target.data.isCategory() ? '' : classes.findingsLink}`}
                  path={generatePath}
                  key={`link-${i}`}
                  data={link}
                />
              ))}
              {tree.descendants().map((node) => (
                <Group
                  key={`node-${node.data.key}`}
                  left={node.x}
                  top={node.y}
                  onMouseOver={() => setTooltip({x: node.x + width/2 - leftTree.data.getSize().width/2, y: node.y + height/2 + node.data.getSize().height/2, content: node.data.tooltip()})}
                  onMouseLeave={() => setTooltip(null)}
                  onClick={toggle(node)}
                >
                  <Node 
                    node={node.data}
                    type={!node.parent ? "root" : node.data.isCategory() ? "category" : "finding"}
                  />
                </Group>
              ))}
            </Group>
          ))}
        </Group>
      )}
    </SVGContainer>
  );
}

interface HierarchyLink<T> {
  target: HierarchyNode<T>,
  source: HierarchyNode<T>
}

function generatePath({source: s, target: t}: HierarchyLink<TreeNode>): string {
  
  const reversed = s.x > t.x;

  const startX = reversed ? s.x : s.x + s.data.getSize().width;
  const startY = s.y;
  const endX = reversed ? t.x + t.data.getSize().width : t.x;
  const endY = t.y;

  // Values in case of top reversed and left reversed diagonals
  const xrvs = endX - startX < 0 ? -1 : 1;
  const yrvs = endY - startY < 0 ? -1 : 1;

  // Define preferred curve radius
  const rdef = 20;

  // Reduce curve radius, if source-target x space is smaller
  let r = Math.abs(endX - startX) / 2 < rdef ? Math.abs(endX - startX) / 2 : rdef;

  // Further reduce curve radius, is y space is more small
  r = Math.abs(endY - startY) / 2 < r ? Math.abs(endY - startY) / 2 : r;

  // Define width and height of links, excluding radius
  //let h = Math.abs(ey - y) / 2 - r;
  const w = Math.abs(endX - startX) / 2 - r;

  // Build and return custom arc command
  return `
      M ${startX} ${startY}
      L ${startX + w * xrvs} ${startY}
      C ${startX + w * xrvs + r * xrvs} ${startY} 
        ${startX + w * xrvs + r * xrvs} ${startY} 
        ${startX + w * xrvs + r * xrvs} ${startY + r * yrvs}
      L ${startX + w * xrvs + r * xrvs} ${endY - r * yrvs} 
      C ${startX + w * xrvs + r * xrvs}  ${endY} 
        ${startX + w * xrvs + r * xrvs}  ${endY} 
        ${endX - w * xrvs}  ${endY}
      L ${endX} ${endY}`
}

function getChildren(n: TreeNode) {
  const state = n.state();
  if (state.state === "expanded") {
    return state.children;
  }
  return null;
}