import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { Subscription, SubscriptionCodec } from "src/misc/codecs/stripe/Subscription";
import { parseData, useApi } from "src/services/api-provider";


type Props = {
    update: (updated: Subscription) => void;
    subscription: Subscription | null;
}

type State =
    | { key: "initial" }
    | { key: "pending" }
    | { key: "error", error: string }
    | { key: "complete" };

export const CancelPlan: FC<Props> = ({ update, subscription }) => {
    const api = useApi();
    const [state, setState] = useState<State>({ key: "initial" });

    const cancel = async (cancel: boolean) => {
        setState({ key: "pending" });
        const result = await api.Put("/api/stripe/subscription", { cancel_at_period_end: cancel }).then(parseData(SubscriptionCodec));
        if (result.type === "error") {
            setState({ key: "error", error: `couldn't cancel ${cancel ? "subscription" : "deletion"}` });
        } else {
            setState({ key: "complete" });
            update(result.data);
            window.setTimeout(() => setState({ key: "initial" }), 5000);
        }
    }

    if (!subscription) {
        return null;
    }

    const button = state.key === "pending"
        ? <CircularProgress color="inherit" size={14}/>
        : subscription.cancel_at_period_end
            ? "Cancel Deletion"
            : "Cancel Plan";
    
    const help = subscription.cancel_at_period_end
        ? `Your plan is scheduled to be deleted on ${subscription.current_period_end.toLocaleDateString()}. Click on "${button}" to cancel the scheduled deletion.`
        : "All future payments will be cancelled, and you will lose access to any paid plan features.";

    return (
        <div>
            <Button
                id="cancel"
                onClick={() => cancel(!subscription.cancel_at_period_end)}
                variant="outlined"
                endIcon={<Cancel />}
                style={{ marginBottom: "1em" }}
                disabled={state.key === "pending" || state.key === "complete"}
            >
                {button}
            </Button>
            { state.key === "error" && (
                <Typography variant="body2">
                    Error: {state.error}. Please try again later.
                </Typography>
            )}
            <Typography variant="body2">
                {help}
            </Typography>
        </div>
    );
}