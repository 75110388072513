import {
    createStyles,
    makeStyles,
    Theme,
    Typography,
	Button,
} from "@material-ui/core";
import React from "react";
import { InvitationTable } from "./InvitationTable";

type Props = {
    reload: () => void;
};


const useStyle = makeStyles((theme: Theme) =>
    createStyles({
		root: {
			margin: theme.spacing(2)
		},
        bold: {
            fontWeight: 600,
        },
		table: {
			marginTop: theme.spacing(2)
		},
        invitationContainer: {
            marginTop: theme.spacing(2),
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            minHeight: "30vh",
            "& > *": {
                alignSelf: "stretch",
            },
        },
        buttonWrapper: {
            marginTop: "auto",
            display: "flex",
            paddingTop: theme.spacing(2),
        },
        button: {
            cursor: "pointer",
            justifySelf: "flex-start",
        },
    })
);

export const InvitationPage: React.FC<Props> = ({
	reload
}: Props) => {
    const classes = useStyle();
    return (
		<div className={classes.root}>
            <Typography variant="h5" className={classes.bold}>
				Would you like to invite any other users to this subscription?
            </Typography>
            <div className={classes.invitationContainer}>
                <Typography className={classes.bold} variant="body1">
                    Account Email
                </Typography>
                <InvitationTable />
                <div className={classes.buttonWrapper}>
                    <Button className={classes.button} onClick={reload}>
                        Done
                    </Button>
                </div>
            </div>
        </div>
    );
};
