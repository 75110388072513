import React, { FC, useEffect, useRef, useState } from 'react';
import { Group } from '@visx/group';
import { IconButton, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Tooltip } from '@visx/tooltip';
import { Zoom } from '@visx/zoom';
import { Apps, CenterFocusWeak, ZoomIn, ZoomOut } from '@material-ui/icons';
import { Size } from './Tree';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      overflow: "hidden",
      backgroundColor: theme.palette.background.default,
      backgroundImage: `url(../assets/glow-${theme.palette.type}.png)`,
      width: '100%',
      height: '100%',
    },
    tooltip: {
      position: "absolute",
      minWidth: 100,
      maxWidth: 250,
      fontSize: "0.8rem",
      border: `1px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
      padding: theme.spacing(1),
      fontFamily: theme.typography.fontFamily,
      wordBreak: "break-word",
      '& > *': {
        margin: 0,
      },
      '& > * + *': {
        marginTop: theme.spacing(1),
      }
    },
    controls: {
      position: "absolute",
    }
  }),
);

type TooltipState = { 
  x: number;
  y: number;
  content: React.ReactNode;
}

export type Props = {
    back: () => void;
    children: (props: ChildProps) => React.ReactChild;
};

export type ChildProps = {
  width: number;
  height: number;
  setTooltip: (tooltip: TooltipState | null) => void;
}


export const SVGContainer: FC<Props> = ({ back, children }: Props) => {

  const classes = useStyles();
  const [tooltip, setTooltip] = useState<TooltipState | null>(null);

  // Track container size
  const [size, setSize] = useState<Size>({ width: 100, height: 100 });
  const ref = useRef<HTMLDivElement>(null);
  const syncSize = () => {
    if (!ref.current) return;
    setSize({ height: ref.current.clientHeight, width: ref.current.clientWidth });
  }
  useEffect(syncSize, [ref]);

  // The container will resize if the window size is changed
  useEffect(() => {
    window.addEventListener("resize", syncSize)
    return () => window.removeEventListener("resize", syncSize)
  }, []);
  
  const scales = {
    scaleXMin: 0.5,
    scaleYMin: 0.5,
    scaleXMax: 2,
    scaleYMax: 2,
  };

  return (
    <div ref={ref} className={classes.root}>
      { size && 
        <Zoom<SVGSVGElement> {...size} {...scales}>
          {zoom => (
            <div>
              <svg {...size} style={{ cursor: zoom.isDragging ? 'grabbing' : 'grab', touchAction: 'none', display: 'block' }} ref={zoom.containerRef}>
                <Group transform={zoom.toString()}>
                  {children({ ...size, setTooltip })}
                </Group>
              </svg>
              <div className={classes.controls} style={{ bottom: 8, right: 8 }}>
                <IconButton size="small" onClick={() => zoom.scale({ scaleX: 1.1, scaleY: 1.1 })}><ZoomIn/></IconButton>
                <IconButton size="small" onClick={() => zoom.scale({ scaleX: 0.9, scaleY: 0.9 })}><ZoomOut/></IconButton>
                <IconButton size="small" onClick={() => zoom.reset()}><CenterFocusWeak/></IconButton>
                <IconButton size="small" onClick={back}><Apps/></IconButton>
              </div>
              { tooltip &&
                <Tooltip
                  unstyled={true}
                  top={zoom.transformMatrix.translateY + tooltip.y * zoom.transformMatrix.scaleY}
                  left={zoom.transformMatrix.translateX + tooltip.x * zoom.transformMatrix.scaleX}
                  className={classes.tooltip}
                >
                  {tooltip.content}
                </Tooltip>
              }
            </div>
          )}
        </Zoom>
      }
    </div>
  );
}
