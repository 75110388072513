import {
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    makeStyles,
    Theme,
} from "@material-ui/core";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    getSeverityLevel,
    SEVERITY_CRITICAL,
    SEVERITY_HIGH_MAX,
    SEVERITY_LOW_MAX,
    SEVERITY_MEDIUM_MAX,
} from "src/misc/severity";
import { ChildrenProps, GroupByData, RefProps } from "./IssueFilterBy";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupListLabel: {
            margin: "0 -0.25rem",
            "& > span": {
                fontSize: "0.875rem",
                padding: "0.375rem 0.25rem",
            },
        },
        formControl: {
            marginBottom: theme.spacing(4),
            width: "100%",
        },
        formLabel: {
            marginBottom: theme.spacing(2),
        },
        severityLevel: {
            textTransform: "capitalize",
        },
    })
);

const severityLessThanEqualQuery = (max: number): string => {
    return `user_severity.lte.${max},and(user_severity.is.null,severity.lte.${max})`;
};

const severityGreaterThanQuery = (min: number): string => {
    return `user_severity.gt.${min},and(user_severity.is.null,severity.gt.${min})`;
};

const severityEqualQuery = (value: number): string => {
    return `user_severity.eq.${value},and(user_severity.is.null,severity.eq.${value})`;
};

export const IssueFilterBySeverityGroup = forwardRef<RefProps, ChildrenProps>(
    function IssueFilterBase(props: ChildrenProps, ref) {
        const classes = useStyles();
        const groupByData: GroupByData = {
            critical: [],
            high: [],
            medium: [],
            low: [],
        };
        const [state, setState] = useState<string[]>([]);

        const { data } = props;

        data.forEach((record) => {
            const severityLevel = getSeverityLevel(
                record.user_severity || record.severity
            );
            groupByData[severityLevel].push(record);
        });

        useImperativeHandle(ref, () => ({
            getQuery() {
                if (state.length > 0) {
                    const queryBySeverityLevel: string[] = [];

                    state.forEach((severityLevel) => {
                        if (severityLevel === "low") {
                            // Severity less than or equals 3 (SEVERITY_LOW_MAX)
                            queryBySeverityLevel.push(
                                `or=(${severityLessThanEqualQuery(
                                    SEVERITY_LOW_MAX
                                )})`
                            );
                        } else if (severityLevel === "medium") {
                            // Severity greater than 3 (SEVERITY_LOW_MAX) and less than or equals 6 (SEVERITY_MEDIUM_MAX)
                            queryBySeverityLevel.push(
                                `and=(or(${severityGreaterThanQuery(
                                    SEVERITY_LOW_MAX
                                )}),or(${severityLessThanEqualQuery(
                                    SEVERITY_MEDIUM_MAX
                                )}))`
                            );
                        } else if (severityLevel === "high") {
                            // Severity greater than 6 (SEVERITY_MEDIUM_MAX) and less than or equals 8 (SEVERITY_HIGH_MAX)
                            queryBySeverityLevel.push(
                                `and=(or(${severityGreaterThanQuery(
                                    SEVERITY_MEDIUM_MAX
                                )}),or(${severityLessThanEqualQuery(
                                    SEVERITY_HIGH_MAX
                                )}))`
                            );
                        } else if (severityLevel === "critical") {
                            // Severity equals 9
                            queryBySeverityLevel.push(
                                `or=(${severityEqualQuery(SEVERITY_CRITICAL)})`
                            );
                        }
                    });

                    if (queryBySeverityLevel.length === 1) {
                        return queryBySeverityLevel[0];
                    } else {
                        return `or=(${queryBySeverityLevel
                            .join(",")
                            .replaceAll("=", "")})`; // No "=" inside or/and operator
                    }
                }
                return "";
            },
            clear() {
                setState([]);
            },
        }));

        const handleOnChange = (severity: string) => {
            if (state.indexOf(severity) === -1) {
                const newState = [...state];
                newState.push(severity);
                setState(newState.sort());
            } else {
                const index = state.indexOf(severity);
                const newState = [...state];
                newState.splice(index, 1);
                setState(newState);
            }
        };

        return (
            <FormControl className={classes.formControl}>
                <FormLabel component="legend" className={classes.formLabel}>
                    Severity
                </FormLabel>
                <FormGroup>
                    {Object.keys(groupByData).map((severityLevel, index) => (
                        <FormControlLabel
                            key={index}
                            label={`${severityLevel} (${
                                groupByData[severityLevel].length
                            } issue${
                                groupByData[severityLevel].length === 1
                                    ? ""
                                    : "s"
                            })`}
                            className={`${classes.groupListLabel} ${classes.severityLevel}`}
                            control={
                                <Checkbox
                                    checked={
                                        state.indexOf(severityLevel) !== -1
                                    }
                                    onChange={() =>
                                        handleOnChange(severityLevel)
                                    }
                                    name={severityLevel}
                                    size="small"
                                />
                            }
                        />
                    ))}
                </FormGroup>
            </FormControl>
        );
    }
);
