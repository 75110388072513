import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import React, { FC } from "react";
import { useNotices } from "src/services/notice-provider/NoticeProvider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            padding: theme.spacing(1.5),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
    })
);

export const NoticeBanner: FC = () => {

    const classes = useStyles();
    const theme = useTheme();
    const { notices } = useNotices();

    if (notices.length < 1) {
        return null;
    }

    const { content, colour } = notices[notices.length - 1];

    const style = {
        background: theme.palette[colour].main,
        color: theme.palette[colour].contrastText,
    };

    return (
        <div className={classes.root} style={style}>
            {content}
        </div>
    );
}
