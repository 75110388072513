import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Paper,
  Divider,
  Button,
} from "@material-ui/core";
import { ScanDetailRecord } from "../services/scan-details";
import { RequestStatus } from "src/shared/enums";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Skeleton } from "@material-ui/lab";

type Props = {
  status: number;
  data: ScanDetailRecord[];
  getData: () => void;
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scanDetails: {
      maxWidth: "300px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "201px",
    },
    content: {
      minWidth: "270px",
      maxWidth: "270px",
    },
    paper: {
      padding: "6px 16px",
      backgroundColor: theme.palette.background.paper
    },
    loading: {
      width: "100%",
      height: "201px",
      transform: "none",
    },
  })
);

const getTimeDiff = (now: Date, past: Date): string => {
  let ret: string;

  let diff = now.getTime() - past.getTime();
  if (diff < 0) diff = 0;	// Avoid showing negative difference when accessing same subscription with different users

  const days_diff = Math.floor(diff / 1000 / 60 / 60 / 24);
  const hour_diff = Math.floor(diff / 1000 / 60 / 60);
  const minute_diff = Math.floor(diff / 1000 / 60);
  const second_diff = Math.floor(diff / 1000);


  if (days_diff > 0) {
    ret = days_diff > 1 ? `${days_diff} days ago` : `${days_diff} day ago`;
  } else if (hour_diff > 0) {
    ret = hour_diff > 1 ? `${hour_diff} hours ago` : `${hour_diff} hour ago`;
  } else if (minute_diff > 0) {
    ret = minute_diff > 1 ? `${minute_diff} minutes ago` : `${minute_diff} minute ago`;
  } else {
    ret = second_diff > 1 ? `${second_diff} seconds ago` : `${second_diff} second ago`;
  } 

  return ret;
}

export const ScanDetails: React.FC<Props> = ({ status, data, getData }) => {
  const classes = useStyles();
  const now = new Date();

  if (status === RequestStatus.Pending) {
    return (
      <div className={classes.scanDetails}>
        <Skeleton className={classes.loading} />
      </div>
    );
  }

  if (status === RequestStatus.Error) {
    return (
      <div className={classes.scanDetails}>
        <Button onClick={getData}>Refresh</Button>
      </div>
    );
  }

  if (status === RequestStatus.Success && data.length === 0) {
    return (
      <div className={classes.scanDetails}>
        <h3>No record found</h3>
      </div>
    );
  }

  return (
    <div className={classes.scanDetails}>
      <Timeline>
        {data.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot />
              {index !== data.length - 1 ? <TimelineConnector /> : ""}
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h3">
                  {getTimeDiff(now, new Date(item.timestamp))}
                </Typography>
                <Divider />
                <Typography variant="caption">{item.desc}</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};
