import { useTheme } from "@material-ui/core"
import React from "react"

type Props = {
    className: string | undefined
}

export const Logo: React.FC<Props> = ({ className }: Props) => {

    const { palette } = useTheme()
    const text = palette.text.primary
    const accent = palette.primary.main

    return (
        <svg className={className} width="420px" height="39px" viewBox="0 0 420 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Attack Bound Logo</title>
            <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="attackbound" transform="translate(-150.000000, -471.000000)">
                    <g id="attackbound-logo" transform="translate(150.000000, 471.000000)">
                        <polygon id="Fill-1" fill={text} points="36.885 0.6114 36.885 4.7214 48.606 4.7214 48.606 37.6629 53.2155 37.6629 53.2155 4.7214 64.992 4.7214 64.992 0.6114"></polygon>
                        <polygon id="Fill-2" fill={text} points="69.9357 0.6114 69.9357 4.7214 81.6567 4.7214 81.6567 37.6629 86.2662 37.6629 86.2662 4.7214 98.0427 4.7214 98.0427 0.6114"></polygon>
                        <path d="M112.87515,0.6114 L97.98915,37.6629 L103.26465,37.6629 L106.54215,29.4399 L124.98465,29.4399 L128.26065,37.6629 L133.53765,37.6629 L118.59615,0.6114 L112.87515,0.6114 Z M115.76415,5.3889 L123.59565,25.3314 L107.87415,25.3314 L115.76415,5.3889 Z" id="Fill-3" fill={text}></path>
                        <path d="M155.25795,38.3283 C149.85195,38.3283 145.32345,36.5433 141.67695,32.9688 C138.02895,29.3958 136.20495,24.7953 136.20495,19.1658 C136.20495,13.5378 138.02895,8.9343 141.67695,5.3598 C145.32345,1.7883 149.85195,0.0003 155.25795,0.0003 C161.18295,0.0003 165.86895,2.4438 169.31295,7.3338 L165.42345,9.4983 C164.34945,7.9083 162.90495,6.6108 161.09145,5.6103 C159.27645,4.6098 157.33095,4.1103 155.25795,4.1103 C151.18395,4.1103 147.78795,5.5278 145.06545,8.3598 C142.34295,11.1933 140.98245,14.7963 140.98245,19.1658 C140.98245,23.5353 142.34295,27.1353 145.06545,29.9703 C147.78795,32.8008 151.18395,34.2183 155.25795,34.2183 C157.33095,34.2183 159.27645,33.7293 161.09145,32.7468 C162.90495,31.7658 164.34945,30.4608 165.42345,28.8303 L169.36695,30.9963 C165.77595,35.8848 161.07195,38.3283 155.25795,38.3283" id="Fill-4" fill={text}></path>
                        <polygon id="Fill-7" fill={text} points="204.75135 37.66185 199.03035 37.66185 184.97685 20.99835 181.25385 25.21935 181.25385 37.66185 176.64435 37.66185 176.64435 0.61185 181.25385 0.61185 181.25385 19.77585 197.64135 0.61185 203.41785 0.61185 187.97535 18.22035"></polygon>
                        <path d="M243.07965,33.55155 C245.22765,33.55155 246.91215,32.97855 248.13465,31.83105 C249.35715,30.68355 249.96765,29.12805 249.96765,27.16305 C249.96765,25.34955 249.36465,23.83155 248.16315,22.60905 C246.95865,21.38805 245.26515,20.77455 243.07965,20.77455 L231.74865,20.77455 L231.74865,33.55155 L243.07965,33.55155 Z M242.85765,16.66605 C244.89315,16.66605 246.46815,16.10955 247.57965,14.99955 C248.68965,13.88805 249.24615,12.46155 249.24615,10.72155 C249.24615,8.98155 248.68065,7.54605 247.55115,6.41655 C246.42165,5.28705 244.85715,4.72155 242.85765,4.72155 L231.74865,4.72155 L231.74865,16.66605 L242.85765,16.66605 Z M244.02465,37.66155 L227.13765,37.66155 L227.13765,0.61155 L243.63615,0.61155 C246.82065,0.61155 249.34665,1.48155 251.21715,3.22305 C253.08765,4.96305 254.02215,7.24155 254.02215,10.05555 C254.02215,12.27555 253.39215,14.15655 252.13365,15.69405 C250.87515,17.23005 249.33765,18.18405 247.52415,18.55455 C249.48615,18.85005 251.17965,19.88805 252.60615,21.66405 C254.03115,23.44305 254.74515,25.44255 254.74515,27.66405 C254.74515,30.66255 253.79115,33.08055 251.88465,34.91355 C249.97665,36.74655 247.35765,37.66155 244.02465,37.66155 L244.02465,37.66155 Z" id="Fill-9" fill={text}></path>
                        <path d="M280.3518,34.218 C284.4258,34.218 287.7123,32.802 290.2128,29.97 C292.7118,27.135 293.9613,23.535 293.9613,19.1655 C293.9613,14.7585 292.7118,11.148 290.2128,8.3325 C287.7123,5.5185 284.4258,4.1115 280.3518,4.1115 C276.2418,4.1115 272.9463,5.5185 270.4653,8.3325 C267.9828,11.148 266.7438,14.7585 266.7438,19.1655 C266.7438,23.535 267.9828,27.135 270.4653,29.97 C272.9463,32.802 276.2418,34.218 280.3518,34.218 M280.3518,38.328 C274.9083,38.328 270.4833,36.5145 267.0768,32.8845 C263.6688,29.256 261.9663,24.684 261.9663,19.1655 C261.9663,13.647 263.6688,9.0735 267.0768,5.4435 C270.4833,1.815 274.9083,-7.1942452e-14 280.3518,-7.1942452e-14 C285.7578,-7.1942452e-14 290.1753,1.815 293.6013,5.4435 C297.0258,9.0735 298.7388,13.647 298.7388,19.1655 C298.7388,24.684 297.0258,29.256 293.6013,32.8845 C290.1753,36.5145 285.7578,38.328 280.3518,38.328" id="Fill-11" fill={text}></path>
                        <path d="M333.70665,34.30155 C331.13265,36.98805 327.38265,38.32905 322.45815,38.32905 C317.53215,38.32905 313.78215,36.98805 311.20815,34.30155 C308.63565,31.61805 307.34865,27.94155 307.34865,23.27505 L307.34865,0.61155 L311.95815,0.61155 L311.95815,23.16405 C311.95815,26.60805 312.86565,29.31255 314.68065,31.27305 C316.49565,33.23805 319.08765,34.21755 322.45815,34.21755 C325.82715,34.21755 328.42065,33.23805 330.23565,31.27305 C332.04915,29.31255 332.95665,26.60805 332.95665,23.16405 L332.95665,0.61155 L337.56765,0.61155 L337.56765,23.21955 C337.56765,27.92355 336.27915,31.61805 333.70665,34.30155" id="Fill-14" fill={text}></path>
                        <path d="M401.00295,33.55155 C405.37245,33.55155 408.83445,32.18205 411.39045,29.44155 C413.94495,26.70255 415.22295,23.27505 415.22295,19.16505 C415.22295,15.01905 413.96445,11.57355 411.44595,8.83305 C408.92745,6.09255 405.44595,4.72155 401.00295,4.72155 L392.94795,4.72155 L392.94795,33.55155 L401.00295,33.55155 Z M401.00295,37.66155 L388.33845,37.66155 L388.33845,0.61155 L401.00295,0.61155 C406.66845,0.61155 411.25245,2.37105 414.75195,5.88855 C418.24995,9.40755 420.00045,13.83255 420.00045,19.16505 C420.00045,24.53505 418.24995,28.96005 414.75195,32.44155 C411.25245,35.92305 406.66845,37.66155 401.00295,37.66155 L401.00295,37.66155 Z" id="Fill-16" fill={text}></path>
                        <polygon id="Fill-19" fill={text} points="347.6223 37.66155 352.2318 37.66155 352.2318 23.02155 347.6223 23.02155"></polygon>
                        <polygon id="Fill-21" fill={accent} points="373.6734 0.61155 373.6734 29.55255 352.3434 0.61155 347.6214 0.61155 347.6214 1.83555 352.2324 8.13555 352.2324 8.11155 373.8399 37.66155 378.2844 37.66155 378.2844 0.61155"></polygon>
                        <polygon id="Fill-22" fill={accent} points="26.052 37.66185 26.052 8.72235 4.722 37.66185 5.32907052e-15 37.66185 5.32907052e-15 36.43935 4.611 30.13935 4.611 30.16335 26.2185 0.61185 30.663 0.61185 30.663 37.66185"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}