import * as t from "io-ts";
import { date } from "src/misc/codecs";

export const IssueCategoryCodec = t.union([
    t.literal('Attack Surface Exposure'),
    t.literal('Digital Supply Chain'),
    t.literal('Social Threat Intelligence'),
]);

/**
 * Maps available api tables/views to their record type. These will all be available to query
 * via the Api.GetTable or Api.GetTableSlice.
 */
export const tables = {
    breached_credentials: t.interface({
        address: t.string,
        database_name: t.string,
        email: t.string,
        hashed_password: t.string,
        name: t.string,
        password: t.string,
        phone: t.string,
        username: t.string,
        vin: t.string,
        ip_address: t.string,
        cred_id: t.string,
        first_seen: date,
        last_seen: date,
        domain: t.string,
        id: t.number,
        domain_id: t.number,
        password_score: t.number,
    }),
    dns_records: t.interface({
        answer: t.string,
        first_seen: date,
        last_seen: date,
        name: t.string,
        target_domain: t.string,
        type: t.union([t.literal("A"), t.literal("CNAME")]),
        id: t.number,
        domain_id: t.number,
    }),
    email_config_issues: t.interface({
        domain: t.string,
        type: t.union([
            t.literal("missing_spf"),
            t.literal("multiple_spf"),
            t.literal("invalid_spf"),
            t.literal("missing_dmarc"),
            t.literal("invalid_dmarc"),
        ]),
        resolved: t.boolean,
        last_seen: date,
        name: t.string,
        desc: t.string,
        id: t.number,
        domain_id: t.number,
    }),
    ip_address_locations: t.interface({
        address: t.string,
        country: t.string,
        city: t.union([t.string, t.null]),
        subdomain: t.string,
        target_domain: t.string,
        last_seen: date,
        domain_id: t.number,
    }),
    invitations: t.interface({
        timestamp: date,
        subscription: t.string,
        secret: t.string,
        email: t.string,
        used: t.boolean,
        inviter: t.string,
    }),
    issues: t.interface({
        category: IssueCategoryCodec,
        data: t.unknown,
        desc: t.string,
        first_seen: date,
        id: t.number,
        last_seen: date,
        resolved: t.union([date, t.null]),
        severity: t.union([t.literal(1), t.literal(2), t.literal(3), t.literal(4), t.literal(5), t.literal(6), t.literal(7), t.literal(8), t.literal(9)]),
        target_domain: t.string,
        title: t.string,
        accepted: t.boolean,
        irrelevant: t.boolean,
        user_severity: t.union([t.literal(1), t.literal(2), t.literal(3), t.literal(4), t.literal(5), t.literal(6), t.literal(7), t.literal(8), t.literal(9), t.null]),
        type: t.union([
            t.literal('cert_trust'),
            t.literal('cert_expired'),
            t.literal('exposed_service'),
            t.literal('cms_vuln'),
            t.literal('self_signed_cert'),
            t.literal('email_config'),
            t.literal('wildcard_cert'),
            t.literal('subdomain_takeover'),
        ]),
        duration: t.number,
        target_domain_id: t.number,
    }),
    known_users: t.interface({
        domain: t.string,
        user: t.string,
        email: t.union([t.string, t.null]),
        name: t.union([t.string, t.null]),
        phone: t.union([t.string, t.null]),
        username: t.union([t.string, t.null]),
        address: t.union([t.string, t.null]),
        first_seen: date,
        id: t.number,
        domain_id: t.number,
    }),
    potential_takeover_counts: t.interface({
        target_domain: t.string,
        count: t.number,
        id: t.number,
    }),
    potential_takeovers: t.interface({
        answer: t.string,
        first_seen: date,
        last_seen: date,
        name: t.string,
        target_domain: t.string,
        domain_id: t.number,
        dns_records_id: t.number,
    }),
    scan_log: t.interface({
        timestamp: date,
        desc: t.string
    }),
    score_by_day: t.interface({
        date: date,
        score: t.number,
        subscription: t.string,
        category: IssueCategoryCodec,
        timezone: t.string,
    }),
    service_counts: t.interface({
        protocol: t.union([t.literal('tcp'), t.literal('udp')]),
        service: t.string,
        count: t.number,
        target_domain: t.string,
        domain_id: t.number,
    }),
    service_providers: t.interface({
        domain: t.string,
        service_provider: t.string,
        reasons: t.array(t.string),
        first_seen: date,
        last_seen: date,
        id: t.number,
        domain_id: t.number,
    }),
    services: t.interface({
        name: t.string,
        address: t.string,
        port: t.number,
        protocol: t.string,
        product: t.string,
        service: t.string,
        tunnel: t.string,
        seen: date,
        target_domain: t.string,
        id: t.number,
        subdomain_id: t.number,
        domain_id: t.number,
    }),
    subdomains_counts: t.interface({
        target_domain: t.string,
        count: t.number,
        id: t.number,
    }),
    subscriptions: t.interface({
        uuid: t.string,
        name: t.string,
        show_welcome: t.boolean
    }),
    subscriptions_with_features: t.interface({
        uuid: t.string,
        name: t.string,
        show_welcome: t.boolean,
        users_count: t.string,
        domains_count: t.string,
        interactive_threat_map: t.boolean,
        security_baseline_and_rating: t.boolean,
        executive_dashboard: t.boolean,
        reporting: t.boolean,
        api_integration: t.boolean,
        continuous_security_monitoring: t.boolean,
        dynamic_threat_updates: t.boolean,
        web_security: t.boolean,
        dns_security: t.boolean,
        certificate_strength: t.boolean,
        critical_network: t.boolean,
        critical_system: t.boolean,
        attack_surface_exposure_data_breach_report: t.boolean,
        third_party_hosted_services: t.boolean,
        cloud_services: t.boolean,
        digital_supply_chain_data_breach_report: t.boolean,
        data_sovereign_risk_management: t.boolean,
        data_web_exposures: t.boolean,
        people_social_threat_map: t.boolean,
        compromised_data_exposure: t.boolean,
        identity_breach_detection: t.boolean,
    }),
    target_domains_validity: t.interface({
        subscription: t.string,
        domain: t.string,
        passed: t.boolean,
        code: t.string,
        verified_until: t.union([date, t.null]),
        domain_id: t.number,
    }),
    threat_exposure: t.interface({
        timestamp: date,
        subscription: t.string,
        score: t.number,
        email_config_issues: t.array(t.string),
        email_config_issues_score: t.number,
        self_signed_certs: t.array(t.string),
        self_signed_certs_score: t.number,
        weak_tls_issues: t.array(t.string),
        weak_tls_issues_score: t.number,
        email_addresses: t.array(t.string),
        email_addresses_score: t.number,
        exposed_services: t.array(t.string),
        exposed_services_score: t.number,
        breached_credentials: t.array(t.string),
        breached_credentials_score: t.number,
    }),
    user_detail: t.interface({
        id: t.string,
        email: t.string,
        first_name: t.union([t.string, t.null]),
        last_name: t.union([t.string, t.null]),
        subscription: t.string,
    }),
    whats_new: t.interface({
        id: t.number,
        timestamp: date,
        title: t.string,
        body: t.string,
    }),
    domain_cipher_strength_score: t.interface({
        target_domain_id: t.number,
        target_domain: t.string,
        average: t.number,
        weakest: t.number,
    })
}
