import { Button, CircularProgress, TableContainer, Table, TableBody, makeStyles, createStyles, Theme, IconButton } from '@material-ui/core'
import { Add, Refresh } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Row, usePostgrest } from 'src/services/postgrest-provider';
import { SubscriptionContext } from '../../services/subscription-provider/SubscriptionProvider';
import TargetDomainCreateDialog from './TargetDomainCreateDialog';
import { TargetDomainsListItem } from './TargetDomainsListItem';

export type TargetDomain = Row<"target_domains_validity">;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2),
        background: theme.palette.background.default,
        height: "100%",
    },
    header: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        "& > * + *": {
            marginLeft: theme.spacing(2),
        }
    },
}));

export const TargetDomainsPage: React.FC = (): JSX.Element => {

    const classes = useStyles();
    const postgrest = usePostgrest();

    const [targetDomains, setTargetDomains] = useState<TargetDomain[]>([]);

    const [loading, setLoading] = useState(true)

    const subscriptionId = useContext(SubscriptionContext).current.uuid;

    const fetchData = useCallback(() => {
        setLoading(true)
        postgrest.GetTable("target_domains_validity", "order=passed.desc,domain&subscription=eq." + subscriptionId).then(res => {
            setLoading(false);
            if (res.type === "success") {
                setTargetDomains(res.data);
            } else {
                console.error(res.message);
            }
        })
    }, [postgrest, subscriptionId]);

    useEffect(() => fetchData(), [fetchData])

    const doPostAction = () => {
        fetchData()
    }

    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const handleOpenCreateDialog = () => {
        setOpenCreateDialog(true)
    }

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false)
    }

    const handleCheckDomainExists = (domain: string) => {
        return targetDomains.some(d => d.domain == domain)
    }

    return (<div className={classes.root}>
        <div className={classes.header}>
            <div style={{ flexGrow: 1 }} />
            <IconButton size="small" onClick={fetchData}><Refresh fontSize="small" /></IconButton>
            <Button onClick={handleOpenCreateDialog} variant="outlined" startIcon={<Add />}>Add Domain</Button>
            <TargetDomainCreateDialog open={openCreateDialog} closeDialog={handleCloseCreateDialog} postAction={doPostAction} checkDomainExists={handleCheckDomainExists} />
        </div>
        <TableContainer>
            {loading ? <CircularProgress />
                : <Table>
                    <TableBody>
                        {targetDomains.map((td: TargetDomain) => (
                            <TargetDomainsListItem key={td.code} targetDomain={td} postDeleteAction={doPostAction} />
                        ))}
                    </TableBody>
                </Table>
            }
        </TableContainer>
    </div>)
}