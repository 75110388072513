import React from "react";
import { PaymentMethod } from "src/misc/codecs/stripe/PaymentMethod";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { capitalize } from "src/misc/format/capitalize";
import { CardIcon } from "./CardIcon";
import { format } from "date-fns";
import { Source } from "src/misc/codecs/stripe/Source";

type Props = {
    method: PaymentMethod | Source
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        detail: {
            marginLeft: theme.spacing(1),
           '& p': {
                color: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily,
                fontSize: ".8rem",
                margin: 0,
                marginBottom: ".1rem"
           }
        }
    })
);

export const Card: React.FC<Props> = ({ method }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CardIcon height={40} type={cardType(method)} />
            <div className={classes.detail}>
                {detail(method).map((text, i) => (
                    <p key={i}>{text}</p>
                ))}
            </div>
        </div>
    );
}

type CardType = Parameters<typeof CardIcon>[0]["type"];

export const cardType = (method: PaymentMethod | Source): CardType => {
    switch (method.object) {
    case "payment_method":
        switch (method.type) {
        case "card":
            return method.card.brand;
        default:
            return "unknown";
        }
    case "card":
        switch (method.brand) {
        case "American Express":
            return "amex";
        case "Diners Club":
            return "diners";
        case "Discover":
            return "discover";
        case "JCB":
            return "jcb";
        case "MasterCard":
            return "mastercard";
        case "UnionPay":
            return "unionpay";
        case "Visa":
            return "visa";
        case "Unknown":
            return "unknown";
        }
    }
}

const detail = (method: PaymentMethod | Source): string[] => {

    // If it's not a card, we just list the type of method.
    if (method.object === "payment_method" && method.type !== "card") {
        return [capitalize(method.type)];
    }

    // Otherwise we show the masked card number and expiry
    const card = method.object === "card" ? method : method.card;

    return [
        `**** **** **** ${card.last4}`,
        format(new Date(card.exp_year, card.exp_month-1), "MMM yyyy"),
    ];
}
