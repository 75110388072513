import { useTheme } from "@material-ui/core";
import React from "react";

/** @typedef {object} LineProps React props for `<Line />` component*/
type LineProps = {
    /**
     * Color for svg polyline stroke
     */
    stroke?: string;
    /**
     * `strokeDasharray` for svg polyline
     */
    strokeDasharray: number;
    /**
     * Width of svg polyline
     */
    strokeWidth?: string;
    /**
     * Series of points for painting svg polyline
     */
    points: string;
}

/**
 * Polyline for svg
 *
 * @param {LineProps} {
 *     stroke,
 *     strokeDasharray,
 *     strokeWidth = "2",
 *     points,
 * }
 * @return {JSX.Element} 
 */
export const Line: React.FC<LineProps> = ({
    stroke,
    strokeDasharray,
    strokeWidth = "2",
    points,
}) => {
    const theme = useTheme();
    return (
        <polyline
            fill="none"
            stroke={stroke || theme.palette.text.primary}
            strokeDasharray={strokeDasharray}
            strokeWidth={strokeWidth}
            points={points}
        />
    )
}