import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { BreachRecord } from '../services/full-report';
import { RequestStatus } from 'src/shared/enums';
import { FullReportLoading } from '../molecules/FullReportLoading';
import { Fade, Typography } from '@material-ui/core';
import { DownloadReportBtn } from './DownloadReportBtn';

type Props = {
  data: BreachRecord[];
  status: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
      width: "100%",
      tableLayout: "fixed",
      "& th, td": {
        wordWrap: "break-word",
      }
    },
    head: {
      backgroundColor: theme.palette.table.head.background,
      color: theme.palette.table.head.text,
    }
  })
);


export const LeakCredentials:React.FC<Props> = ({
  data,
  status
}) => {
  const classes = useStyles();

  if (status === RequestStatus.Pending) {
    return <FullReportLoading />
  }

  return (
      <Fade in={true}>
          <>
              <DownloadReportBtn reportName="social_threat_intelligence"/>
              <TableContainer>
                  <Table
                      className={classes.table}
                      aria-label="Leak Credentials table"
                  >
                      <TableHead className={classes.head}>
                          <TableRow>
                              <TableCell width="20%">
                                  <Typography variant="subtitle2">
                                      Email
                                  </Typography>
                              </TableCell>
                              <TableCell width="20%">
                                  <Typography variant="subtitle2">
                                      Username
                                  </Typography>
                              </TableCell>
                              <TableCell width="40%">
                                  <Typography variant="subtitle2">
                                      Hashed password
                                  </Typography>
                              </TableCell>
                              <TableCell width="20%">
                                  <Typography variant="subtitle2">
                                      Source
                                  </Typography>
                              </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {data.map((row, index) => (
                              <TableRow key={index}>
                                  <TableCell component="th" scope="row" width="20%">
                                      {row.email}
                                  </TableCell>
                                  <TableCell width="20%">{row.username}</TableCell>
                                  <TableCell width="40%">{row.hashed_password}</TableCell>
                                  <TableCell width="20%">{row.database_name}</TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                  </Table>
              </TableContainer>
          </>
      </Fade>
  );
}