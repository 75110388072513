import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { Timeline } from "@material-ui/lab";
import { WelcomeProvideTargets } from "./WelcomeProvideTargets";
import { WelcomeVerifyDomain } from "./WelcomeVerifyDomain";
import { WelcomeInviteUsers } from "./WelcomeInviteUsers";
import { WelcomeCompleted } from "./WelcomeCompleted";
import { DomainStat, Step } from "./Welcome";

type Props = {
    step: Step;
    skipInvite: () => void;
    domainStat: DomainStat;
};

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: "100%",
        },
        timeline: {
            padding: "0",
        },
    })
);

export const WelcomeSteps: React.FC<Props> = ({
    step,
    skipInvite,
    domainStat,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Timeline className={classes.timeline}>
                <WelcomeProvideTargets step={step} />
                <WelcomeVerifyDomain step={step} domainStat={domainStat} />
                <WelcomeInviteUsers step={step} skipInvite={skipInvite} />
                <WelcomeCompleted step={step} />
            </Timeline>
        </div>
    );
};
