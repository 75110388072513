import {
    createStyles,
    makeStyles,
    Theme,
    Typography,
    Button,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { RowSlice, usePostgrest } from "src/services/postgrest-provider";
import { IssueFilterByCategoryGroup } from "./IssueFilterByCategoryGroup";
import { IssueFilterByDomainGroup } from "./IssueFilterByDomainGroup";
import {IssueFilterByDurationGroup} from "./IssueFilterByDurationGroup";
import { IssueFilterBySeverityGroup } from "./IssueFilterBySeverityGroup";

export type DataRow = RowSlice<
    "issues",
    "target_domain" | "category" | "severity" | "user_severity" | "duration"
>;

type State =
    | { key: "loading" }
    | { key: "ok"; data: DataRow[] }
    | { key: "error"; error: string };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            display: "flex",
            flexDirection: "column",
        },
        title: {
            flex: 1,
            marginBottom: "1rem",
        },
        groups: {
            flex: 1,
            padding: "1.25rem",
            background: theme.palette.background.paper,
        },
        groupListLabel: {
            margin: "0 -0.25rem",
            "& > span": {
                fontSize: "0.875rem",
                padding: "0.375rem 0.25rem",
            },
        },
        formControl: {
            marginBottom: theme.spacing(4),
            width: "100%",
        },
        formLabel: {
            marginBottom: theme.spacing(2),
        },
        sliderFormGroup: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        slider: {
            color: theme.palette.secondary.main,
            padding: "20px 0",
            width: "90%",
        },
        severityLevel: {
            textTransform: "capitalize",
        },
        applyButton: {
            width: "100%",
            height: "3rem",
            marginTop: "1rem",
            textTransform: "none",
        },
        clearButton: {
            marginTop: "1rem",
            color: theme.palette.filter.title,
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
    })
);

export type GroupByData = {
    [title: string]: DataRow[];
};

export type RefProps = {
    getQuery(): string;
    clear(): void;
};

export type ChildrenProps = {
    data: DataRow[];
};

type Props = {
    searchText?: string;
    setFilterQuery: (query: string) => void;
    className?: string;
    toggleDrawer?: (open: boolean) => void;
};

export const IssueFilterBy: React.FC<Props> = ({
    searchText,
    setFilterQuery,
    className,
    toggleDrawer,
}) => {
    const classes = useStyles();
    const postgrest = usePostgrest();
    const [state, setState] = useState<State>({ key: "loading" });
    const [sliderRange, setSliderRange] = useState<{ min: number, max: number }>({
        min: 0,
        max: 0,
    })

    const domainRef = useRef<RefProps>(null);
    const categoryRef = useRef<RefProps>(null);
    const severityRef = useRef<RefProps>(null);
    const durationRef = useRef<RefProps>(null);

    const loadData = useCallback(
        (query?: string) => {
            
            // If user has entered keywords for searching, we should also update the filter by data
            if (searchText) {
                query += `&%22lower_title%22=like.*${searchText}*`;
            }

            postgrest
                .GetTableSlice(
                    "issues",
                    [
                        "target_domain",
                        "category",
                        "severity",
                        "user_severity",
                        "duration",
                    ],
                    query
                )
                .then((response) => {
                    if (response.type === "error") {
                        setState({
                            key: "error",
                            error: response.message,
                        });
                    } else {
                        setState({
                            key: "ok",
                            data: response.data,
                        });

                        if (!query) {
                            getSliderRange(response.data);
                        }
                    }
                });
        },
        [postgrest, searchText]
    );

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const queryList: (string | undefined)[] = [
            domainRef.current?.getQuery(),
            categoryRef.current?.getQuery(),
            severityRef.current?.getQuery(),
            durationRef.current?.getQuery(),
        ];

        // Update issue table
        setFilterQuery(queryList.filter((query) => !!query).join("&"));

        // Update filter by data
        loadData(queryList.filter((query) => !!query).join("&"));

        // For filter section in drawer
        if (toggleDrawer) {
            toggleDrawer(false);
        }
    };

    const clearAll = () => {
        domainRef.current?.clear();
        categoryRef.current?.clear();
        severityRef.current?.clear();
        durationRef.current?.clear();
        
        // Reset issue table
        setFilterQuery("");
        
        // Reset filter by data
        loadData();
    };

    const getSliderRange = (data: DataRow[]) => {
        const daysDiff = data.map((record) => record.duration);
        const max = Math.max(...daysDiff);
        const min = Math.min(...daysDiff);

        if (Number.isFinite(min) && Number.isFinite(max)) {
            setSliderRange({
                min: min,
                max: max,
            })
        }
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <div className={`${classes.root} ${className}`}>
            <div className={classes.title} style={toggleDrawer && { marginTop: "1rem" }}>
                <Typography variant="h3">Filter By</Typography>
            </div>
            <div className={classes.groups}>
                {state.key === "ok" && (
                    <>
                        <IssueFilterByDomainGroup
                            ref={domainRef}
                            data={state.data}
                        />
                        <IssueFilterByCategoryGroup
                            ref={categoryRef}
                            data={state.data}
                        />
                        <IssueFilterBySeverityGroup
                            ref={severityRef}
                            data={state.data}
                        />
                        <IssueFilterByDurationGroup
                            ref={durationRef}
                            min={sliderRange.min}
                            max={sliderRange.max}
                        />
                    </>
                )}
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.applyButton}
                    onClick={handleOnClick}
                >
                    <Typography variant="h3">Apply</Typography>
                </Button>
                <Typography
                    variant="h3"
                    className={classes.clearButton}
                    onClick={() => clearAll()}
                >
                    Clear All
                </Typography>
            </div>
        </div>
    );
};
