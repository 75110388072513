import { Button, CircularProgress, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Log, User, UserManager, UserManagerSettings } from "oidc-client";
import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PageTitle } from "src/elements/PageTitle/PageTitle";
import { config } from "../../config";
import Splash from "../../features/splash/Splash";

const base = `${window.location.protocol}//${window.location.host}`

const configuration: UserManagerSettings = {
  client_id: 'web-client',
  redirect_uri: `${base}/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: `${base}/`,
  scope: 'openid',
  authority: `https://auth.${config.domain}/auth/realms/attackbound`,
  silent_redirect_uri: `${base}/authentication/silent_callback`,
  automaticSilentRenew: true,
  monitorSession: false,
  loadUserInfo: true,
};

type Props = {
    children: JSX.Element;
    debug?: boolean;
}

type State = { state: "init" }
           | { state: "unauthed" }
           | { state: "expired", user: User }
           | { state: "authed", user: User };

export const AuthProvider: React.FC<Props> = ({ children, debug = false }: Props) => {

    // Optionally enable debug logging for oidc-client library
    useEffect(() => {
        if (!debug) return;
        Log.logger = console;
        Log.level = Log.DEBUG;
        return () => Log.reset();
    }, [debug]);

    const [state, setState] = useState<State>({state: "init"});

    // Init the user manager. This holds the user info and maintains the
    // session, renewing access tokens in the background.
    const mgr = useMemo(() => new UserManager(configuration), []);

    // Track the current path so we can return the user back to the same
    // page after a login or logout.
    const location = useLocation();
    const url = location.pathname + location.search + location.hash;

    // Callback to sync our state with the user manager state.
    const syncState = useCallback(async () => {
        const user = await mgr.getUser();
        if (user === null) {
            setState({state: "unauthed"});
        } else if (user.expired) {
            setState({state: "expired", user: user});
        } else {
            setState({state: "authed", user: user});
        }
    }, [mgr]);

    // Sync state on mount
    useEffect(() => { syncState() }, [syncState]);

    // Sync state when meaningful changes occur within the user manager.
    useEffect(() => {
        mgr.events.addUserLoaded(syncState);
        mgr.events.addSilentRenewError(syncState);
        return () => {
            mgr.events.removeUserLoaded(syncState);
            mgr.events.removeSilentRenewError(syncState);
        }
    }, [syncState, mgr]);

    // Login and logout functions which maintain the URL.
    const login = useCallback(() => mgr.signinRedirect({state: {url}}), [mgr, url]);
    const logout = useCallback(() => mgr.signoutRedirect({
        post_logout_redirect_uri: window.location.protocol + "//" + window.location.host + url
    }), [mgr, url]);

    // Trigger login redirect if user hasn't logged in yet, and we are not currently
    // on a callback route.
    useEffect(() => {
        const authenticating = location.pathname.startsWith("/authentication")
        if (state.state === "unauthed" && !authenticating) {
            login();
        }
    }, [login, state, location]);

    return (
        <Routes>
            <Route path="/authentication/callback" element={
                <HandleCallback mgr={mgr}/>
            }/>
            <Route
                path="/*"
                element={((): React.ReactElement => {
                    switch (state.state) {
                        case "init":
                            return <Loading>Initializing auth...</Loading>;
                        case "unauthed":
                            return <Loading>Redirecting to login...</Loading>;
                        case "expired":
                            return <LoginAgain title="Session Expired" login={login}>Your login session has expired.</LoginAgain>;
                        case "authed":
                            return (
                                <authContext.Provider value={{ user: state.user, logout: logout }}>
                                    {children}
                                </authContext.Provider>
                            );
                    }
                })()}
            />
        </Routes>
    )
}

type CallbackProps = {
    mgr: UserManager;
}

type callbackState = { state: "init" }
                   | { state: "pending" }
                   | { state: "error", message: string }
                   | { state: "done", user: User }

const HandleCallback: FC<CallbackProps> = ({mgr}) => {
    const navigate = useNavigate();
    const [state, setState] = useState<callbackState>({state: "init"});
    useEffect(() => {
        if (state.state !== "init") return;
        setState({state: "pending"});

        // Exchange grant for an access token and user info.
        mgr.signinRedirectCallback().then((user) => {
            setState({state: "done", user: user});
            // Redirect user back to original page if it exists in session state.
            if (user.state && user.state.url) {
                navigate(user.state.url);
            } else {
                navigate("/");
            }
        }).catch((reason: Error) => {
            console.error("auth callback", reason);
            setState({ state: "error", message: reason.message });
        });
    }, [state, navigate, mgr]);

    switch (state.state) {
        case "done":
        case "init":
        case "pending":
            return <Loading>Retrieving auth token</Loading>;
        case "error":
            // Allow user to login again if the callback failed. Note that we lose track
            // of original URL here, so user will end up at the home page after login.
            return <LoginAgain title="Login failed" login={() => mgr.signinRedirect()}>Error retrieving auth token: {state.message}.</LoginAgain>;
    }
}

export type AuthContext = {
    user: User;
    logout: () => void;
}

export const authContext = createContext<AuthContext>({
    user: {} as User,
    logout: () => { return }
});

export const useAuth = (): AuthContext => {
    return useContext(authContext);
}

type LoadingProps = {
    children: string | string[];
}

const Loading: FC<LoadingProps> = ({children}): React.ReactElement => (
    <Splash>
        <CircularProgress />
        <Typography>{children}</Typography>
    </Splash>
);

type LoginAgainProps = {
    title: string;
    children: string | string[];
    login: () => void;
}

const LoginAgain: FC<LoginAgainProps> = ({title, children, login}) => {

    const classes = makeStyles((theme: Theme) => createStyles({
        spacing: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    }))();

    return (
        <Splash>
            <div>
                <PageTitle className={classes.spacing}>{title}</PageTitle>
                <Typography>{children}</Typography>
                <Typography>Click the button below to login again.</Typography>
                <Button className={classes.spacing} disableElevation onClick={login} variant="contained" color="primary">
                    Login
                </Button>
            </div>
        </Splash>
    );
}