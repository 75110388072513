import { CircularProgress, createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React, { useEffect, useState, useCallback } from "react";
import { capitalize } from "src/misc/format/capitalize";
import { ApiResult } from "src/services/api-provider";
import { LinkButton } from "../LinkButton/LinkButton";

type Props<S extends number, T> = {
    fetch: () => Promise<ApiResult<S, T>>;
    render: (data: T) => JSX.Element;
    callback?: (data: T) => void;
};

type State<T> =
    | { key: "loading" }
    | { key: "error", message: string }
    | { key: "ok", data: T }
    | { key: "reloading", data: T };

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    error: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(1),
        "& .help": {
            cursor: "help",
            display: "flex",
            alignItems: "flex-end",
            lineHeight: 1,
            gap: theme.spacing(0.5),
        }
    }
}));

export const Loader = <S extends number, T>({ fetch, render, callback }: Props<S, T>): JSX.Element => {

    const classes = useStyles();
    const [state, setState] = useState<State<T>>({ key: "loading" });

    const load = useCallback(async () => {
        const result = await fetch();
        if (result.type === "error") {
            setState({ key: "error", message: result.message });
        } else {
            setState({ key: "ok", data: result.data });
        }
    }, [fetch]);

    useEffect(() => { load() }, [load]);

    // Provide data to callback whenever it changes.
    useEffect(() => {
        if (!callback) return;
        if (state.key !== "ok") return;
        callback(state.data);
    }, [callback, state]);

    const wrap = (children: React.ReactChild) => (
        <div className={classes.root}>
            {children}
        </div>
    );

    switch (state.key) {
    case "loading":
        return wrap(
            <div>
                <CircularProgress size={24}/>
            </div>
        );
    case "error":
        return wrap(
            <div className={classes.error}>
                <Tooltip placement="top" title={capitalize(state.message)}>
                    <span className="help">
                        Error loading data <Info fontSize="small"/>
                    </span>
                </Tooltip>
                <LinkButton onClick={load}>Try again</LinkButton>
            </div>
        );
    case "ok":
    case "reloading":
        return wrap(render(state.data));
    }
}
