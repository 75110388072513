import {
    createStyles,
    debounce,
    Drawer,
    IconButton,
    Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { keys } from "fp-ts/lib/ReadonlyRecord";
import React, {useEffect, useState} from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs } from "src/elements/Tabs/Tabs";
import { IssueFilterBy } from "src/features/issue-summary/IssueFilterBy/IssueFilterBy";
import { IssueSearchInput } from "src/features/issue-summary/IssueSearchInput/IssueSearchInput";
import { IssueTable, Issue, Ordering } from "src/features/issue-summary/IssueTable/IssueTable";
import { tables } from "src/services/postgrest-provider/tables";
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            padding: theme.spacing(2),
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            height: "100%",
            minHeight: "100%",
            maxHeight: "100%",
        },
        table: {
            height: "100%",
            flex: "1 0 70%",
            "&>div": {
                flex: 1,
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
            },
            [theme.breakpoints.down("md")]: {
                flex: "1 0 100%",
            }
        },
        searchInputWrapper: {
            marginBottom: theme.spacing(2),
            height: "50px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        filters: {
            flex: "1 0 30%",
            [theme.breakpoints.down("md")]: {
                display: "none"
            },
        },
        tabs: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            overflow: "hidden",
            alignItems: "stretch",
            justifyContent: "center",
        },
        filterIcon: {
            display: "none",
            [theme.breakpoints.down("md")]: {
                display: "block"
            },
        },
        filterDrawer: {
            display: "none",
            [theme.breakpoints.down("md")]: {
                display: "block"
            },
            "& > *": {
                width: "50%",
            }
        }
    })
);

export const IssueTablePage: React.FC = () => {
    const classes = useStyles();

    const [search, setSearch] = useSearchParams();
    const [searchText, setSearchText] = useState<string>();
    const [filterQuery, setFilterQuery] = useState<string>();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const sort = checkSortParam(search.get("sort"));
    const order = checkOrderParam(search.get("order"));

    const changeSort = (ordering: Ordering) => setSearch({
        sort: ordering.column,
        order: ordering.direction,
    });

    const onSearch = (text: string) => {
        setSearchText(text.toLowerCase());
    }



    const toggleDrawer = (open: boolean) => {
        setDrawerOpen(open);
    };


    useEffect(() => {
        // Close drawer when resizing page
        window.addEventListener("resize", debounce(() => toggleDrawer(false), 300));
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.searchInputWrapper}>
                <IssueSearchInput searchData={onSearch} />
                <IconButton
                    aria-label="Filters"
                    color="primary"
                    className={classes.filterIcon}
                    onClick={() => toggleDrawer(true)}
                >
                    <FilterListIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
                <div className={classes.table}>
                    <Tabs
                        tabs={["All Task", "Opening", "Closed"]}
                        className={classes.tabs}
                    >
                        <IssueTable
                            state="all"
                            sort={{ column: sort, direction: order }}
                            onChangeSort={changeSort}
                            searchText={searchText}
                            filterQuery={filterQuery}
                        />
                        <IssueTable
                            state="open"
                            sort={{ column: sort, direction: order }}
                            onChangeSort={changeSort}
                            searchText={searchText}
                            filterQuery={filterQuery}
                        />
                        <IssueTable
                            state="closed"
                            sort={{ column: sort, direction: order }}
                            onChangeSort={changeSort}
                            searchText={searchText}
                            filterQuery={filterQuery}
                        />
                    </Tabs>
                </div>
                <div className={classes.filters}>
                    <IssueFilterBy
                        searchText={searchText}
                        setFilterQuery={setFilterQuery}
                    />
                </div>
            </div>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => toggleDrawer(false)}
                className={classes.filterDrawer}
            >
                <IssueFilterBy
                    searchText={searchText}
                    setFilterQuery={setFilterQuery}
                    toggleDrawer={toggleDrawer}
                />
            </Drawer>
        </div>
    );
};

const checkSortParam = (s: string | null): keyof Issue => {
    const key = keys(tables.issues.props).find(key => key === s);
    return key ?? "id";
}

const checkOrderParam = (s: string | null): "asc" | "desc" => {
    return s === "desc" ? "desc" : "asc";
}
