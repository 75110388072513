import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { WhatsNewRecord } from "./whats-new";
import { 
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  createStyles, 
  makeStyles, 
  Theme,
  Button, 
  Divider 
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";

type Props = {
  whatsNewRecords: WhatsNewRecord[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carousel: {
      width: "100%",
      height: "100%",
      color: theme.palette.text.primary
    },
    dialog: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary
    },
    titleText: {
      fontSize: "1rem",
      fontWeight: "bold",
      display: "block",
      height: "44px",
    },
    timestampText: {
      fontSize: "0.9rem",
      fontStyle: "italic",
      margin: "10px auto",
    },
    bodyText: {
      height: "54px",
      fontSize: "0.8rem",
      textOverflow: "ellipsis",
      overflow: "hidden !important",
      display: "-webkit-box",
      "-webkit-line-clamp": "3",
      "-webkit-box-orient": "vertical",
    },
    readMoreButton: {
      margin: "0",
      left: "100%",
      transform: "translateX(-100%)",
    },
  })
);

export const WhatsNewCarousel: React.FC<Props> = ({
  whatsNewRecords,
}: Props) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalRecord, setModalRecord] = useState<null | WhatsNewRecord>(null);

  const handleClick = (record: WhatsNewRecord) => {
    setModalOpen(true);
    setModalRecord(record);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Carousel
        className={classes.carousel}
        stopAutoPlayOnHover={true}
        interval={10000}
        navButtonsAlwaysInvisible={true}
      >
        {whatsNewRecords.map((whatsNewRecord, i) => (
          <div key={i}>
            <span className={classes.titleText}>{whatsNewRecord.title}</span>
            <span className={classes.timestampText}>
              {" —  " + whatsNewRecord.timestamp.toLocaleDateString()}
            </span>
            <Divider variant="middle" />
            <div className={classes.bodyText}>{whatsNewRecord.body}</div>
            <Button
              className={classes.readMoreButton}
              onClick={() => handleClick(whatsNewRecord)}
            >
              Read more
            </Button>
          </div>
        ))}
      </Carousel>
      <Dialog
        aria-labelledby="whats-new-dialog-title"
        open={modalOpen}
        onClose={handleClose}
      >
        <DialogTitle id="customized-dialog-title" className={classes.dialog}>
          {modalRecord ? modalRecord.title : ""}
        </DialogTitle>
        <DialogContent dividers className={classes.dialog}>
          <ReactMarkdown>
            {modalRecord ? modalRecord.body : ""}
          </ReactMarkdown>
        </DialogContent>
        <DialogActions className={classes.dialog}>
          <Button autoFocus onClick={handleClose} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
