import React, { useCallback } from "react";
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from "@material-ui/core";
import * as t from "io-ts";
import { IssueListTableHead } from "./IssueTableHead";
import { usePostgrest } from "src/services/postgrest-provider";
import { tables } from "src/services/postgrest-provider/tables";
import { Loader } from "src/elements/Loader/Loader";
import { Paging } from "src/elements/Paging/Paging";
import { capitalize } from "src/misc/format/capitalize";
import { useWindowSize } from "@react-hook/window-size";

/* 
 * TODO: Temporary using fixed height, calculated by header and footer space.
 *       Need to apply better fomular to calculate unwanted height dynamically.
 */
const UNWANTED_HEIGHT_SPACE = 196;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            color: theme.palette.text.primary,
            "& table": {
                width: "100%",
                borderCollapse: "collapse",
                borderRadius: theme.shape.borderRadius,
                background: theme.palette.background.component,
            },
            "& td": {
                padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
            },
            "& tbody tr + tr td": {
                borderTop: `1px solid ${theme.palette.divider}`,
            },
            "& thead th, tfoot td": {
                color: theme.palette.table.head.text,
                fontWeight: theme.typography.fontWeightMedium,
                background: theme.palette.table.head.background,
            },
            "& tbody tr": {
                display: "table",
                width: "100%",
                tableLayout: "fixed",
            }
        },
        tableWrapper: {
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
        },
        table: {
            borderCollapse: "separate",
            display: "table",
            borderSpacing: 0,
        }
    })
);

export type Issue = t.TypeOf<typeof tables.issues>;

export type Ordering = {
    column: keyof Issue;
    direction: "asc" | "desc";
}

type Props = {
    pageSize?: number;
    paged?: boolean;
    state?: "open" | "closed" | "all";
    footer?: JSX.Element;
    sort?: Ordering;
    searchText?: string;
    filterQuery?: string;
    onChangeSort?: (order: Ordering) => void;
}

export const IssueTable: React.FC<Props> = ({
    paged = true,
    pageSize = 30,
    state = "all",
    footer,
    sort = { column: "id", direction: "asc" },
    searchText,
    filterQuery,
    onChangeSort,
}) => {
    const classes = useStyles();
    const postgrest = usePostgrest();
    const theme = useTheme();

    // `useWindowSize` returns array of window dimensions [width, height]
    const [, windowHeight] = useWindowSize();
    const tableHeight = windowHeight - UNWANTED_HEIGHT_SPACE;

    let query = `order=${sort.column}.${sort.direction}&accepted=eq.false&irrelevant=eq.false`;
    
    if (state !== "all") {
        const resolved = state === "open" ? "is.null" : "not.is.null";
        query += `&resolved=${resolved}`;
    }

    if (searchText) {
        query += `&%22lower_title%22=like.*${searchText}*`
    }

    if (filterQuery) {
        query += `&${filterQuery}`;
    }

    const load = useCallback(
        () => postgrest.GetTablePaged("issues", pageSize, query),
        [postgrest, pageSize, query],
    );

    // Rendering issue rows
    const renderIssueRow = (issue: Issue, index: number) => {
        const display_severity = issue.user_severity !== null ? issue.user_severity : issue.severity; 

        return (
            <tr key={index}>
                <td style={{maxWidth: 0, width: "50%"}}>
                    {issue.title}
                </td>
                <td style={{ width: "20%" }}>
                    {issue.category}
                </td>
                <td style={{ width: "10%" }}>
                    {issue.type}
                </td>
                <td style={{ width: "10%" }}>
                    { issue.duration <= 1 ? "New" : `${issue.duration} Days` }
                </td>
                <td style={{ color: theme.palette.severity[severity(display_severity)].main, width: "10%" }}>
                    {capitalize(severity(display_severity))}
                </td>
            </tr>
        );
    }


    const heightStyle = footer ? {} : {
        height: `${tableHeight}px`,
        maxHeight: `${tableHeight}px`
    };


    const render = (issues: Issue[]) => (
        <div className={classes.tableWrapper}>
            <table className={classes.table}>
                <IssueListTableHead
                    sort={sort}
                    onRequestSort={onChangeSort}
                />
                <tbody style={{ display: "block", overflowX: "hidden", overflowY: "auto", ...heightStyle }}>
                    {issues.map(renderIssueRow)}
                    {issues.length === 0 && (
                        <tr>
                            <td colSpan={5}>
                                There are no {state === "all" ? "" : `${state} `}issues.
                            </td>
                        </tr>
                    )}
                </tbody>
                { footer && (
                    <tfoot>
                        <tr>
                            <td colSpan={5}>
                                { footer }
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    );

    return (
        <div className={classes.root}>
            <Loader fetch={load} render={pages => paged
                ? <Paging item="issue" pages={pages} render={render}/>
                : render(pages.current.data)
            }/>
        </div>
    );
};

type Severity = "low" | "medium" | "high" | "critical";

const severity = (severity: Issue["severity"]): Severity => {
    switch (severity) {
    case 1:
    case 2:
    case 3:
        return "low";
    case 4:
    case 5:
    case 6:
        return "medium";
    case 7:
    case 8:
        return "high";
    case 9:
        return "critical";
    }
}