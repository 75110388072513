/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { ApiContext } from "./ApiProvider";
import { Api } from "./Api";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PostgrestProvider } from "../postgrest-provider";
import { DecoratorFn } from "@storybook/react";

type Responder = (url?: string, data?: any) => Response;

type Response = {
    status?: number;
    headers?: Record<string, string>;
    data: any;
}

class MockAxios {

    private responder: Responder;
    private timeout: number;

    constructor(respond: Responder, timeout: number) {
        this.responder = respond;
        this.timeout = timeout;
    }

    request<T = unknown>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                try {
                    const response = this.responder(config.url, config.data);
                    resolve({
                        status: response.status ?? 200,
                        statusText: "",
                        headers: response.headers ?? {},
                        config: {},
                        data: response.data,
                    });
                } catch(e) {
                    reject({ message: e });
                }
            }, this.timeout);
        });
    }
}

export class MockApi extends Api {
    constructor(respond: Responder, timeout: number) {
        super("", "")
        this.client = new MockAxios(respond, timeout)
    }
}

/**
 * Creates a storybook decorator which wraps the story in a mocked ApiContext.
 * @param respond A function which returns mocked API response data.
 * @returns A decorator as expected by the react-storybook decorators property.
 */
export const mockApiDecorator = (respond: Responder, timeout?: number): DecoratorFn => (Story) => {
    const api = new MockApi(respond, timeout !== undefined ? timeout : 0);
    return (
        <MockApiProvider api={api}>
            <PostgrestProvider>
                <Story />
            </PostgrestProvider>
        </MockApiProvider>
    );
}

type Props = {
    api: MockApi
}

export const MockApiProvider: FC<Props> = ({ api, children }) => {
    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
}