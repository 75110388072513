import React, { useEffect, useState } from "react";
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";

type ReportErrorDialogProps = {
    hasError: boolean;
};

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    })
);

export const ReportErrorDialog: React.FC<ReportErrorDialogProps> = ({
    hasError,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (hasError) {
            handleOpen();
        }
    }, [hasError]);

    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={open}
            className={classes.modal}
            onClose={() => handleClose()}
        >
            <DialogTitle id="alert-dialog-title">
                {"Download Error"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Report cannot be downloaded at this moment. <br />
                    <br />
                    Please try again later.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
