import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DnsRecordWithApi } from './DnsRecordWithApi';
import { ExposedServicesWithApi } from './ExposedServicesWithApi';
import { ServiceProvidersWithApi } from './ServiceProvidersWithApi';
import { LeakCredentialsWithApi } from './LeakCredentialsWithApi';
import { ExposedUsersWithApi } from './ExposedUsersWithApi';
import { EmailConfigIssuesWithApi } from './EmailConfigIssuesWithApi';
import { useNavigate, useParams } from "react-router-dom";
import { Issues } from './Issues';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '100%',
      padding: theme.spacing(2),
      background: theme.palette.background.default,
    },
    title: {
      margin: '20px',
    },
    accordion: {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      boxShadow: 'none'
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.primary.main,
    },
    subheading: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    }
  }),
);

const categories = [
  {
    name: "Technical Vulnerabilities",
    reports: [
      { id: "dns-record", title: "DNS Records", element: <DnsRecordWithApi /> },
      { id: "tls", title: "TLS Issues", element: <Issues types={["cert_expired", "cert_trust"]} /> },
      { id: "exposed-services", title: "Exposed Services", element: <ExposedServicesWithApi /> },
      { id: "email-config", title: "Email Config Issues", element: <EmailConfigIssuesWithApi /> },
    ]
  },
  {
    name: "Digital Supply Chain",
    reports: [
      { id: "service-providers", title: "Service Providers", element: <ServiceProvidersWithApi /> },
    ]
  },
  {
    name: "People Threat Intelligence",
    reports: [
      { id: "leaked-credentials", title: "Leaked Credentials", element: <LeakCredentialsWithApi /> },
      { id: "exposed-users", title: "Exposed Users", element: <ExposedUsersWithApi /> },
    ]
  }
];

export const FullReport: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id: expanded } = useParams();

  return (
    <div className={classes.root}>
      {categories.map(({ name, reports }) => (
        <div key={name}>
          <Typography variant='h4' className={classes.heading}>{name}</Typography>
          {reports.map(({ id, title, element }) => (
            <Accordion
              key={id}
              className={classes.accordion} 
              expanded={expanded === id}
              onChange={() => navigate(expanded === id ? ".." : `../${id}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1aa-content"
                id="panel1aa-header"
              >
                <Typography className={classes.subheading}>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {element}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ))}
    </div>
  );
}