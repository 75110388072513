import { Card, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AcceptInvitation } from './AcceptInvitation';

type Props = {
  code: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        border: 'none',
        margin: theme.spacing(2)
    }
}))

export const AcceptInvitationWrapper: React.FC<Props> = ({ code }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.root}>
      <AcceptInvitation code={code} done={() => navigate("/")} />
    </Card>
  );
}