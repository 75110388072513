import React from "react";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@material-ui/lab";
import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import { WelcomeStepIndex } from "src/shared/enums";

type Props = {
    step: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            minWidth: "100%",
            maxWidth: "100%",
        },
        td: {
            height: "100%",
            verticalAlign: "top",
        },
        completedStyle: {
            backgroundColor: theme.palette.primary.dark,
        },
        checkIcon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        separator: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1.3),
        },
        description: {
            margin: `${theme.spacing(0.8)}px 0 ${theme.spacing(1.5)}px 0`,
            fontSize: "0.9rem",
        },
        button: {
            marginBottom: theme.spacing(1.5),
        },
        hidden: {
            display: "none",
        },
        inactive: {
            color: theme.palette.text.disabled,
        },
    })
);

export const WelcomeProvideTargets: React.FC<Props> = ({ step }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const addDomainAction = () => navigate("/target-domains");

    const isActiveStep = step === WelcomeStepIndex.ProviderTargets;
    const isCompletedStep = step > WelcomeStepIndex.ProviderTargets;

    return (
        <TimelineItem className={isActiveStep ? "" : classes.inactive}>
            <TimelineSeparator
                className={isCompletedStep ? "" : classes.separator}
            >
                <TimelineDot
                    color={isActiveStep || isCompletedStep ? "primary" : "grey"}
                    variant={
                        isActiveStep || isCompletedStep ? "default" : "outlined"
                    }
                >
                    {isCompletedStep && (
                        <CheckIcon className={classes.checkIcon} />
                    )}
                </TimelineDot>
                <TimelineConnector
                    className={
                        isActiveStep || isCompletedStep
                            ? classes.completedStyle
                            : ""
                    }
                />
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
                <table>
                    <tbody>
                        <tr>
                            <td className={classes.td}>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">1.</Box>
                                </Typography>
                            </td>
                            <td className={classes.td}>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">
                                        Provide targets
                                    </Box>
                                </Typography>
                            </td>
                        </tr>
                        <tr className={isCompletedStep ? classes.hidden : ""}>
                            <td></td>
                            <td>
                                <Typography className={classes.description}>
                                    Add a domain or a lists of domains
                                </Typography>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    onClick={addDomainAction}
                                >
                                    Add Target Domain
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TimelineContent>
        </TimelineItem>
    );
};
