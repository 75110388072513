import React, { FC, useState } from 'react';
import { Group } from '@visx/group';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useRef } from 'react';
import { useEffect } from 'react';
import './keyframes.css';
import { Spinner } from './Spinner';
import { TreeNode } from './TreeNode';
import { Size } from './Tree';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Default styles
    default: {
      cursor: "initial",
      color: theme.palette.text.primary,
      "& > rect": {
        fill: theme.palette.background.paper,
      },
      "& text": {
        fill: "currentColor",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        textAnchor: "left",
        pointerEvents: "none",
        fontVariant: "small-caps",
      }
    },
    // State specific overrides
    error: {
      color: theme.palette.error.main,
    },
    loading: {

    },
    collapsed: {
  
    },
    expanded: {

    },
    // Node type specific overrides
    root: {
      "& text": {
        fill: theme.palette.text.primary,
        fontSize: theme.typography.fontSize * 1.3,
        fontWeight: theme.typography.fontWeightBold,
      },
      "& rect": {
        fill: theme.palette.supplementary.main,
      }
    },
    category: {
      "& text": {
        fontSize: theme.typography.fontSize * 1.2,
        fontWeight: theme.typography.fontWeightBold,
      }
    },
    finding: {
      "& text": {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.fontSize * 0.9,
        fontVariant: "initial"
      },
    },
    expandable: {
      cursor: "pointer",
      "& text": {
        fontWeight: theme.typography.fontWeightBold,
      }
    }
  }),
);

export type NodeType = "root" | "category" | "finding";

type Settings = {
  padding: [number, number],
  rx: number
}

type SettingsMap = {
  [Property in NodeType]: Settings
}

/** Contains variables/settings for each NodeType. */
const nodeTypeSettings: SettingsMap = {
  "root": {
    padding: [32, 16],
    rx: 8,
  },
  "category": {
    padding: [4, 3],
    rx: 0,
  },
  "finding": {
    padding: [3, 1],
    rx: 0,
  }
}

type Props = {
  node: TreeNode;
  type?: NodeType,
}

export const Node: FC<Props> = ({ node, type = "finding" }: Props) => {

  const classes = useStyles();

  const settings = nodeTypeSettings[type];

  
  const contentRef = useRef<SVGGElement>(null);
  const [size, setSize] = useState({width: 0, height: 0});
  const [boxSize, setBoxSize] = useState<Size | null>(null);

  // Track content size so we can render an appropriately sized box.
  useEffect(() => {
    if (!contentRef.current) return;
    const size = {width: contentRef.current.getBBox().width, height: contentRef.current.getBBox().height};
    setSize(size);
    setBoxSize({width: size.width + settings.padding[0] * 2, height: size.height + settings.padding[1] * 2});
  }, [contentRef, settings]);

  // Set size on tree node, so it can be used in layout calculations.
  useEffect(() => {
    if (!boxSize) return;
    node.setSize(boxSize);
  }, [node, boxSize]);

 
  const state = node.state().state;

  const content = (() => {
    switch (state) {
      case "loading":
        return <Spinner x={size.width/2} radius={Math.max(size.height / 2, 6)} />;
      case "error":
        // fallthrough
      case "collapsed":
        // fallthrough
      case "expanded": {
        return <text x={settings.padding[0]} dy=".33em">{ type == "finding" ? node.name : node.name.toLowerCase() }</text>
      }
    }
  })();

  return (
    <Group className={`${classes.default} ${classes[state]} ${classes[type]} ${node.isExpandable() ? classes.expandable : ""}`}>
      { boxSize && 
        <rect
          height={boxSize.height}
          width={boxSize.width}
          y={-boxSize.height / 2}
          rx={settings.rx}
        /> 
      }
      <g ref={contentRef}>{content}</g>
    </Group>
  );
}
