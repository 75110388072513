import { createStyles, Dialog, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { PaymentMethodDialogContent } from "./PaymentMethodDialogContent";
import { PaymentHandler } from "../paymentHandler";

type Props = {
    open: boolean;
    handleClose: () => void;
    handlePayment: PaymentHandler;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            padding: theme.spacing(6),
            margin: theme.spacing(6),
            minHeight: "100vh",
            maxHeight: "100vh",
        },
    })
);

export const PaymentMethodDialog: React.FC<Props> = ({
    open,
    handleClose,
    handlePayment,
}) => {
    const classes = useStyles();

    return (
        <Dialog
            fullWidth
            maxWidth={"sm"}
            className={classes.dialog}
            onClose={handleClose}
            aria-labelledby="card-dialog"
            open={open}
        >
            <PaymentMethodDialogContent
                handleClose={handleClose}
                handlePayment={handlePayment}
            />
        </Dialog>
    );
};
