import React from 'react';
import { Divider, makeStyles, createStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => createStyles({
    loadingWrapper: {
      width: "100%",
      height: "100%",
      margin: "16px auto",
      borderRadius: "2px",
      "-webkit-box-shadow": "0px 1px 0px 0px rgba(0,0,0,0.08)",
      "-moz-box-shadow": "0px 1px 0px 0px rgba(0,0,0,0.08)",
      boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.08)",
    },
    titleTextPlaceholder: {
      height: "20px",
      width: "100%",
      marginBottom: '3px'
    },
    datetimeTextPlaceholder: {
      width: "92.28px",
      height: '16px',
      marginBottom: '3px'
    },
    contentTextPlaceholder: {
      width: "100%",
      height: '15px',
      marginTop: '3px'
    },
}));

export const WhatsNewLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingWrapper}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton width="50%" animation="wave" />
      <Divider variant="middle" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </div>
  )
}
