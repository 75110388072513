import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { Navigate, Route, Routes } from "react-router-dom";
import { NavLink } from "../NavLink/NavLink";

const useStyles = makeStyles((theme: Theme) => createStyles({
    nav: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(4),
        alignItems: "flex-end",
        "& > *": {
            lineHeight: 1,
            paddingBottom: theme.spacing(1),
            borderBottom: `solid 3px rgba(0,0,0,0)`,
        }
    },
    tabs: {
        paddingTop: theme.spacing(1)
    },
    active: {
        borderColor: theme.palette.primary.main,
        fontSize: "1.2em",
        "&:hover": {
            textDecoration: "none",
        }
    }
}));

export type Tab = {
    id: string;
    label: string;
}

type Tabs = string[];

type Children<T extends Tabs> = { [Property in keyof T]: JSX.Element };

type Props<T extends Tabs> = {
    tabs: T;
    children: Children<T>;
    className?: string;
}

export const Tabs = <T extends Tabs>({ tabs, children, className }: Props<T>): JSX.Element => {
    const classes = useStyles();

    const isActive = ({ isActive }: { isActive: boolean }) => isActive ? classes.active : undefined;

    const slugs = tabs.map(name => slug(name));

    return (
        <div className={className}>
            <div className={classes.nav}>
                {tabs.map((name, i) => (
                    <NavLink key={name} to={slugs[i]} className={isActive}>
                        {name}
                    </NavLink>
                ))}
            </div>
            <div className={classes.tabs}>
                <Routes>
                    {children.map((element, i) => (
                        <Route key={i} path={`${slugs[i]}/*`} element={element}/>
                    ))}
                    <Route path="*" element={<Navigate to={slugs[0]}/>}/>
                </Routes>
            </div>
        </div>
    );
}

const slug = (s: string) => s
    .normalize('NFKD')        // Normalize unicode
    .toLowerCase()            // Lower case
    .trim()                   // Remove whitespace from ends
    .replace(/\s+/g, '-')     // Spaces to dashes
    .replace(/[^\w-]+/g, '')  // Remove non-word chars
    .replace(/--+/g, '-');    // Collapse dashes
  