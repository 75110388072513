import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { Fragment, useContext } from "react";
import { useApi } from "../../services/api-provider/ApiProvider";
import { SubscriptionContext } from "../../services/subscription-provider/SubscriptionProvider";

export interface TargetDomainDeleteDialogProps {
    name: string;
    open: boolean;
    closeDialog: () => void;
    postAction: () => void;
}

export default function TargetDomainDeleteDialog({name, open, closeDialog, postAction}: TargetDomainDeleteDialogProps): JSX.Element {
    const api = useApi();

    const success = () => {
        handleClose()
        handleOpenSB()
        postAction()
    }

    const failed = () => {
        //
    }

    const [openSB, setOpenSB] = React.useState(false);

    const handleOpenSB = () => {
        setOpenSB(true)
    }

    const handleCloseSB = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSB(false);
    }

    const handleClose = () => {
        closeDialog()
    }

    const subscription = useContext(SubscriptionContext).current;

    const handleDelete = () => {
        api.Post("/api/targetdomain/remove", {
            "subscription": subscription.uuid,
            "domain": name
        })
        .then(res => {
            if (res.type === "error") {
                console.log(res.message);
            } else if (200 <= res.status && res.status <= 299) {
                success()
            } else {
                failed()
            }
        });
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle id="form-dialog-title">Delete Target Domain</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Permanently delete this Target Domain?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSB} autoHideDuration={6000} onClose={handleCloseSB}>
                <Alert onClose={handleCloseSB} severity="success">
                    Target Domain was deleted!
                </Alert>
            </Snackbar>
        </Fragment>
    )

}