import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LanguageIcon from "@material-ui/icons/Language";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { PlanExpandProduct } from "src/misc/codecs/stripe/Plan";
import { MakePricingTable } from "./planData";

type Props = {
    plans: PlanExpandProduct[];
    activePlan?: string;
};

// Material UI styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        category: {
            color: "#EA3D25",
            height: "5rem !important",
            "& td": {
                verticalAlign: "bottom",
                height: "100%",
                minHeight: "2.5rem",
                alignItems: "flex-end",
                paddingBottom: theme.spacing(1),
            },
        },
        active: {
            backgroundColor: theme.palette.action.disabledBackground,
        },
        iconAndText: {
            display: "flex",
            alignItems: "center",
            "& > * + *": {
                marginLeft: theme.spacing(1),
            }
        },
        noBorderBetween: {
            border: "none !important",
            "& > td": {
                borderTop: "none",
                borderBottom: "none",
            }
        }
    })
);


export const PlanTableBody: React.FC<Props> = ({ 
    plans,
    activePlan,
}) => {
    const classes = useStyles();
    const { users, domains, featureRows, featureCategoryRows } = MakePricingTable(plans);
    const activeIndex = plans.findIndex(plan => plan.id === activePlan);
    const active = (index: number) => index === activeIndex ? classes.active : "";
    return (
        <tbody>
            <tr className={classes.noBorderBetween}>
                <td>
                    <div className={classes.iconAndText}>
                        <PersonOutlineIcon />
                        <Typography variant="body2" component="span">Users</Typography>
                    </div>
                </td>
                {users.map((value, i) => (
                    <td key={i} className={active(i)}>{value}</td>
                ))}
            </tr>
            <tr className={classes.noBorderBetween}>
                <td>
                    <div className={classes.iconAndText}>
                    <LanguageIcon />
                    <Typography variant="body2" component="span">Domains</Typography>
                    </div>
                </td>
                {domains.map((value, i) => (
                    <td key={i} className={active(i)}>{value}</td>
                ))}
            </tr>
            {featureRows.map((row, i) => (
                <tr key={i}>
                    <td><Typography variant="body2" component="span">{row.name}</Typography></td>
                    {row.columns.map((col, i) => (
                        <td key={i} className={active(i)}>
                            { col && <CheckOutlinedIcon style={{fontSize: "1.2em"}} /> }
                        </td>
                    ))}
                </tr>
            ))}
            {featureCategoryRows.map((category, i) => (
                <Fragment key={i}>
                    <tr className={classes.category}>
                        <td>
                            <Typography variant="h3">
                                {category.category}
                            </Typography>
                        </td>
                        {plans.map((_, i) => (<td key={i} className={active(i)} />))}
                    </tr>
                    {category.rows.map((row, i) => (
                        <tr key={i}>
                            <td>
                                <Typography variant="body2" component="span">
                                    {row.name}
                                </Typography>
                            </td>
                            {row.columns.map((col, i) => (
                                <td key={i} className={active(i)}>
                                    { col && <CheckOutlinedIcon style={{fontSize: "1.2em"}} /> }
                                </td>
                            ))}
                        </tr>
                    ))}
                </Fragment>
            ))}
        </tbody>
    );
};

