import { createTheme, ThemeProvider } from "@material-ui/core";
import React, { createContext, useMemo } from "react";
import { dark, light } from "src/theme";
import createPersistedState from "use-persisted-state";

type LayoutInfo = {
    // Light/Dark Mode
    darkMode: boolean;
    setDarkMode: (value: boolean) => void;
    toggleDarkMode: () => void;
};

const defaultState = {
    darkMode: true,
};

const useDarkMode = createPersistedState("dark-mode");

export const LayoutContext = createContext<Partial<LayoutInfo>>(defaultState);

export const LayoutProvider: React.FC = ({ children }) => {
    const [darkMode, setDarkMode] = useDarkMode(defaultState.darkMode);

    const toggleDarkMode = () => setDarkMode(!darkMode);

    const themeOptions = darkMode ? dark : light;
    const theme = useMemo(() => createTheme(themeOptions), [themeOptions]);

    return (
        <LayoutContext.Provider
            value={{
                darkMode: darkMode,
                setDarkMode: setDarkMode,
                toggleDarkMode: toggleDarkMode,
            }}
        >
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </LayoutContext.Provider>
    );
};
