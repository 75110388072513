import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Subscription } from "src/misc/codecs/stripe/Subscription";
import { capitalize } from "src/misc/format/capitalize";
import { Price } from "src/elements/Price/Price";

type Props = {
    subscription: Subscription
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(3)
        },
    },
    label: {
    
    },
    value: {
        color: theme.palette.text.secondary,
        fontSize: ".8rem",
    },
    due: {
        color: theme.palette.primary.main,
    }
}));

type Interval = Subscription["plan"]["interval"];

const describeInterval = (interval: Interval, count: number): string => {
    if (count !== 1) {
        return `Every ${count} ${capitalize(interval)}s`;
    }
    switch (interval) {
    case "day":
        return "Daily";
    case "week":
        return "Weekly";
    case "month":
        return "Monthly";
    case "year":
        return "Yearly";
    }
}

export const PaymentSchedule: React.FC<Props> = ({ subscription }) => {
    const classes = useStyles();

    // Next invoice is generated and charged when the current period ends.
    const paymentDue = subscription.current_period_end;

    const frequency = describeInterval(subscription.plan.interval, subscription.plan.interval_count);

    return (
        <div className={classes.root}>
            <div>
                <span className={classes.label}>Frequency</span>
                <br/>
                <span className={classes.value}>{frequency}</span>
            </div>
            <div>
                <span className={classes.label}>Invoice Date</span>
                <br/>
                <span className={classes.value}>{paymentDue.toLocaleDateString()}</span>
            </div>
            <div>
                <span className={classes.label}>Invoice Amount</span>
                <br/>
                <span className={classes.value}>
                    <Price amount={subscription.latest_invoice.amount_due} currency={subscription.latest_invoice.currency}/>
                </span>
            </div>
        </div>
    );
}

