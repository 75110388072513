import React, { useCallback, useEffect, useState } from "react";
import {
    Collapse,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import { PlanTableHeader } from "./PlanTableHeader";
import { PlanTableBody } from "./PlanTableBody";
import { useApi, parseData } from "src/services/api-provider";
import { Skeleton } from "@material-ui/lab";
import { PlanCodecExpandProduct, PlanExpandProduct } from "src/misc/codecs/stripe/Plan";
import * as t from "io-ts";
import { LinkButton } from "src/elements/LinkButton/LinkButton";

type Props = {
    activePlan?: string,
    checkout: (priceId: string) => void;
};

// Material UI styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
        },
        table: {
            width: "100%",
            textAlign: "center",
            borderCollapse: "collapse",
            fontWeight: theme.typography.fontWeightBold,
            "& td,th,tr": {
                border: `1px solid ${theme.palette.action.disabledBackground}`,
                padding: theme.spacing(1),
            },
            "& td:nth-child(1),th:nth-child(1)": {
                textAlign: "left",
                paddingLeft: theme.spacing(2),
                fontWeight: theme.typography.fontWeightRegular,
            }
        },
        toggleTableButton: {
            width: "100%",
            height: "40px",
            color: theme.palette.fold.text,
            backgroundColor: theme.palette.fold.background,
            lineHeight: "40px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
                cursor: "pointer",
            },
        },
        loading: {
            width: "100%",
            height: "390px",
            transform: "none",
        },
        error: {
            height: "390px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
    })
);

type State = "loading" | "error" | PlanExpandProduct[];

export const PlanTable: React.FC<Props> = ({
    activePlan,
    checkout,
}) => {
    const classes = useStyles();
    const api = useApi();
    const [planVisibility, setPlanVisibility] = useState(false);
    const [state, setState] = useState<State>("loading");

    // Fetch data from API
    const fetchPlans = useCallback(() => {
        setState("loading");
        api.Get("/api/stripe/plans").then(parseData(t.array(PlanCodecExpandProduct))).then((res) => {
            if (res.type === "success") {
                // Set state with plans sorted by price
                setState(res.data.sort((a, b) => a.amount - b.amount));
            } else {
                setState("error");
            }
        });
    }, [api]);

    useEffect(() => fetchPlans(), [fetchPlans]);

    // Loading placeholder
    if (state === "loading") {
        return (
            <Skeleton className={classes.loading} />
        );
    } else if (state === "error") {
        return (
            <div className={`${classes.error} ${classes.root}`}>
                <Typography>
                    Error loading plans. <LinkButton onClick={() => fetchPlans()}>Retry</LinkButton>.
                </Typography>
            </div>
        )
    } else {
        return (
            <div className={classes.root}>
                <Collapse
                    in={planVisibility}
                    collapsedSize={350}
                    style={{ width: "100%" }}
                >
                    <div>
                        <table className={classes.table}>
                            <PlanTableHeader
                                plans={state}
                                activePlan={activePlan}
                                checkout={checkout}
                            />
                            <PlanTableBody
                                plans={state}
                                activePlan={activePlan}
                            />
                        </table>
                    </div>
                </Collapse>
                <div
                    className={classes.toggleTableButton}
                    onClick={() => {
                        setPlanVisibility(!planVisibility);
                    }}
                >
                    {planVisibility ? (
                        <>
                            <ArrowDropDownCircleOutlinedIcon
                                style={{ transform: "scaleY(-1)" }}
                            />
                            <Typography variant="body2">Hide</Typography>
                        </>
                    ) : (
                        <>
                            <ArrowDropDownCircleOutlinedIcon />
                            <Typography variant="body2">
                                View More Features
                            </Typography>
                        </>
                    )}
                </div>
            </div>
        );
    }
};
