import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import React, { FC } from "react";
import { NavLink } from "src/elements/NavLink/NavLink";
import { IssueTable } from "./IssueTable";

type Props = {
    count?: number;
    state?: "all" | "open" | "closed";
}


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        justifyContent: "center",
    },
    link: {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.table.head.text,
        display: "flex",
        alignItems: "center",
    },
}));

export const IssueTableWidget: FC<Props> = ({ count = 12, state = "all" }) => {
    const { root, link } = useStyles();
    return (
        <IssueTable paged={false} pageSize={count} state={state} footer={(
            <div className={root}>
                <NavLink className={link} to={`/issues?state=${state}`}>
                    View All <NavigateNext fontSize="small"/>
                </NavLink>
            </div>
        )}/>
    );
}