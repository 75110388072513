import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { NavLink as RouterNavLink, NavLinkProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => createStyles({
    default: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.palette.text.primary,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        }
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
    }
}));

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(function NavLink(props: NavLinkProps, ref) {
    const { className, ...rest } = props;
    const classes = useStyles();

    const makeClasses = ({ isActive }: { isActive: boolean }) => {
        const names = [classes.default];
        if (isActive) {
            names.push(classes.active);
        }
        if (className !== undefined) {
            if (typeof className === "string") {
                names.push(className);
            } else {
                const name = className({ isActive });
                if (name !== undefined) {
                    names.push(name);
                }
            }
        }
        return names.join(" ");
    }

    return (
        <RouterNavLink ref={ref} className={makeClasses} {...rest}/>
    );
});