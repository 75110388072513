import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageTitle } from "src/elements/PageTitle/PageTitle";
import { usePostgrest } from "src/services/postgrest-provider";
import createPersistedState from "use-persisted-state";
import { useAuth } from "../../services/auth-provider/AuthProvider";
import { SubscriptionContext } from "../../services/subscription-provider/SubscriptionProvider";
import { WelcomeImage } from "./WelcomeImage";
import { WelcomeSteps } from "./WelcomeSteps";

export type DomainStat = {
    total: number;
    verified: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            padding: theme.spacing(3),
        },
        fullHeight: {
            height: "100%",
        },
    })
);

const useInviteSkipped = createPersistedState("inviteSkipped");

export enum Step {
    Provide = 1,
    Verify = 2,
    Invite = 3,
    Completed = 4,
}

export const Welcome: React.FC = () => {
    const postgrest = usePostgrest();
    const auth = useAuth();
    const classes = useStyles();
    const [inviteSkipped, setInviteSkipped] = useInviteSkipped(false);
    const [domainStat, setDomainStat] = useState<DomainStat>({
        total: 0,
        verified: 0,
    });
    const userFullName = auth.user.profile.name;
    const subscriptionId = useContext(SubscriptionContext).current.uuid;
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(() => {
        setLoading(true);
        postgrest.GetTable(
            "target_domains_validity",
            "order=passed.desc,domain&subscription=eq." + subscriptionId
        ).then((res) => {
            if (res.type === "success") {
                setDomainStat({
                    total: res.data.length,
                    verified: res.data.filter(domain => domain.passed).length,
                });
            }
            setLoading(false);
        });
    }, [postgrest, subscriptionId]);

    useEffect(() => fetchData(), [fetchData]);

    const step: Step = (() => {
        if (domainStat.total < 1) {
            return Step.Provide;
        } else if (domainStat.verified < 1) {
            return Step.Verify;
        } else if (!inviteSkipped) {
            return Step.Invite;
        } else {
            return Step.Completed;
        }
    })();

    // Reset skipped invitations if we go back to earlier steps.
    useEffect(() => {
        if (!loading && step < Step.Invite) {
            setInviteSkipped(false);
        }
    }, [loading, step, setInviteSkipped]);

    return (
        <Grid data-testid="welcome" container spacing={3} className={classes.root}>
            { loading && <span data-testid="welcome_loading"/> }
            <Grid item xs={12}>
                <PageTitle>
                    {userFullName
                        ? "Welcome"
                        : `Welcome, ${userFullName?.split(" ")[0]}`}
                </PageTitle>
            </Grid>
            <Grid item xs={6} className={classes.fullHeight}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    How it works
                </Typography>
                <Typography variant="body2">
                    Use the insights AttackBound provides to take action,
                    putting controls and precautions in place to prevent your
                    organization from potential critical threats before any
                    damaging hack, leak or security breach can occur.
                </Typography>
                <WelcomeSteps
                    step={step}
                    skipInvite={() => setInviteSkipped(true)}
                    domainStat={domainStat}
                />
            </Grid>
            <Grid item xs={6} className={classes.fullHeight}>
                <WelcomeImage />
            </Grid>
        </Grid>
    );
};
