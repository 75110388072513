import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {
    StripeTextFieldCVC,
    StripeTextFieldExpiry,
    StripeTextFieldNumber,
} from "./commonTextFields";


const useStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: theme.spacing(2),
        "& > *": {
            flexGrow: 1,
            width: "auto"
        }
    }
}));

type Props = {
    disabled: boolean;
}

export const StripeCardElements: React.FC<Props> = ({ disabled }) => {
    const classes = useStyle();
    const [state, setState] = useState({
        cardNumberComplete: false,
        expiredComplete: false,
        cvcComplete: false,
        cardNumberError: undefined,
        expiredError: undefined,
        cvcError: undefined,
        submitError: "",
    });

    const onElementChange = (field: string, errorField: string) => ({
        complete,
        error = { message: null },
    }: {
        complete: boolean;
        error?: { message: null | string };
    }) => {
        setState({ ...state, [field]: complete, [errorField]: error.message });
    };

    const { cardNumberError, expiredError, cvcError } = state;

    return (
        <div className={classes.root}>
            <StripeTextFieldNumber
                style={{ width: "100%" }}
                error={Boolean(cardNumberError)}
                labelErrorMessage={cardNumberError}
                onChange={onElementChange(
                    "cardNumberComplete",
                    "cardNumberError"
                )}
                disabled={disabled}
            />
            <StripeTextFieldExpiry
                error={Boolean(expiredError)}
                labelErrorMessage={expiredError}
                onChange={onElementChange(
                    "expiredComplete",
                    "expiredError"
                )}
                disabled={disabled}
            />
            <StripeTextFieldCVC
                error={Boolean(cvcError)}
                labelErrorMessage={cvcError}
                onChange={onElementChange("cvcComplete", "cvcError")}
                disabled={disabled}
            />
        </div>
    );
};

