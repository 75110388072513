import { Card, CardContent, Chip, createStyles, IconButton, makeStyles, Popover, TableCell, TableRow, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import TargetDomainDeleteDialog from "./TargetDomainDeleteDialog";
import { CheckCircle, Error, VerifiedUser, Warning, Delete } from "@material-ui/icons";
import { TinyColor } from "@ctrl/tinycolor";
import { TargetDomain } from "./TargetDomainsPage";

interface TargetDomainsListItemProps {
    targetDomain: TargetDomain;
    postDeleteAction: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        code: {
            padding: `${theme.spacing(0.2)}px ${theme.spacing(0.4)}px`,
            background: theme.palette.background.default,
            border: `solid 1px ${theme.palette.divider}`,
            fontFamily: "monospace",
        },
        verificationCard: {
            maxWidth: "30rem",
        },
        info: {
            padding: theme.spacing(0.3),
            fontSize: "0.9rem",
            '& > svg': {
                verticalAlign: 'text-bottom',
                fontSize: "1rem"
            }
        },
        warning: {
            backgroundColor: new TinyColor(theme.palette.warning.main).setAlpha(0.3).toPercentageRgbString(),
            '& > svg': {
                color: theme.palette.warning.main,
            }
        },
        success: {
            backgroundColor: new TinyColor(theme.palette.success.main).setAlpha(0.3).toPercentageRgbString(),
            '& > svg': {
                color: theme.palette.success.main
            }
        },
        verified: {
            color: theme.palette.success.contrastText,
            backgroundColor: theme.palette.success.main,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.success.dark,
            }
        },
        unverified: {
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.main,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.warning.dark,
            }
        },
        icon: {
            color: theme.palette.warning.contrastText,
        },
        row: {
            "& > td, & > th": {
                borderBottom: `1px solid ${theme.palette.divider}`,
                borderTop: `1px solid ${theme.palette.divider}`,
            }
        }
    })
);

export const TargetDomainsListItem: React.FC<TargetDomainsListItemProps> = ({targetDomain, postDeleteAction}: TargetDomainsListItemProps): JSX.Element => {

    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
   

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [verificationDetailsOpen, setVerificationDetailsOpen] = useState(false);
    const toggleVerificationDetails = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setVerificationDetailsOpen(!verificationDetailsOpen);
    };

    const { passed, code, domain, verified_until } = targetDomain;
    const label = passed ? "Verified" : "Unverified";
    const icon = passed ? <VerifiedUser/> : <Error/>;
    const verifiedClass = passed ? "verified" : "unverified";
   
    const verification = <>
        <Chip icon={icon} label={label} classes={{ root: classes[verifiedClass], icon: classes.icon }} size="small" onClick={toggleVerificationDetails} />
        <Popover 
            anchorEl={anchorEl}
            open={verificationDetailsOpen}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            transformOrigin={{vertical: "top", horizontal: "center"}}
            onClose={() => setVerificationDetailsOpen(false)}
        >
            <Card className={classes.verificationCard}>
                <CardContent>
                    <Typography variant="h5">Verification Code</Typography>
                    <pre className={classes.code}>{code}</pre>
                    <Typography gutterBottom>
                        Ensure a <span className={classes.code}>TXT</span> record exists for <span className={classes.code}>{domain}</span> containing the verification code.
                        { !passed && (
                            <>
                                <span> The record will typically be detected within a minute of publishing.</span>
                                <Typography variant="body2" style={{"marginTop": "2rem"}}>
                                    * Note: TXT Records are applied immediately but may take up to 2 hours to propagate across the internet.
                                </Typography>
                            </>
                        )}

                    </Typography>
                    { passed && verified_until && (
                        <Typography className={`${classes.success} ${classes.info}`}>
                            <CheckCircle /> Verified until {verified_until.toLocaleString()}.
                        </Typography>
                    )}
                    { !passed && verified_until && (
                        <Typography className={`${classes.warning} ${classes.info}`}>
                            <Warning /> Verification expired {verified_until.toLocaleString()}.
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </Popover>
    </>;


    return (
        <TableRow className={classes.row}>
            <TableCell component="th" scope="row">
                <Typography variant="h3" className={classes.code}>{domain}</Typography>
            </TableCell>
            <TableCell>
                {verification}
            </TableCell>
            <TableCell align="right">
                <IconButton size="small" onClick={handleOpen}>
                    <Delete fontSize="small" />
                </IconButton>
                <TargetDomainDeleteDialog name={targetDomain.domain} open={open} closeDialog={handleClose} postAction={postDeleteAction} />
            </TableCell>
        </TableRow>
    )
}