import { Button,  TextField, Typography, Card, CardContent, CardActions, makeStyles, Theme, createStyles, CardHeader, LinearProgress} from "@material-ui/core";
import React, { useState } from "react";
import { useApi } from "../../services/api-provider/ApiProvider";
import { InvitationPage } from './InvitationPage';
import { AcceptInvitation } from "../invitations/AcceptInvitation";
import { PageTitle } from "src/elements/PageTitle/PageTitle";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            '& > * + *': {
                marginTop: theme.spacing(3),
            }
        },
        box: {
            [theme.breakpoints.down("sm")]: {
                flexDirection: 'column',
            },
            display: 'flex',
            height: '100%',
            alignItems: 'stretch',
            '& > *': {
                flexGrow: 1,
            }
        },
        or: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 0,
            background: 'none',
            padding: theme.spacing(1),
        },
        card: {
            padding: theme.spacing(2)
        }
    })
);

type Props = {
    reload: () => void
}

type state = "default" | "pending" | "error" | "done";
type step = "subscription" | "invitation";

const isValidUrl = (urlString: string) => {
    let url;

    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export default function CreateSubscriptionWizard({ reload }: Props): JSX.Element {

    const [name, setName] = useState("");
    const [state, setState] = useState<state>("default");
    const [step, setStep] = useState<step>("subscription");
    const [code, setCode] = useState("");

    const api = useApi();

    const handleCreate = () => {
        if (name.length === 0) {
            return // TODO: Validation in UI
        }
        setState("pending");
        api.Post("/api/subscription/add", {
            "name": name
        }).then(res => {
            if (res.type === "error") {
                setState("error");
                console.error("error adding subscription", res.message);
            } else if (200 <= res.status && res.status <= 299) {
				// Jump to add invitation page after subscription is created successfully
				setStep('invitation');
            } else {
                setState("error");
            }
        });
    }

    const content = () => {
        switch (state) {
        case "default":
            return <>
                 <Typography>
                    Enter a name for the new subscription.
                </Typography>
                <TextField
                    label="Name"
                    onChange={e => setName(e.target.value)}
                />
            </>;
        case "pending":
            return <>
                 <Typography>Your subscription is being created.</Typography>
                 <LinearProgress/>
            </>;
        case "error":
            return <Typography>
                There was an error creating your subscription.
                Confirm to reload and try again.
            </Typography>;
        case "done":
            return <Typography>
                Your subscription has been created.
                You can now continue into the app.
            </Typography>;
        }
    }

    const actions = () => {
        switch (state) {
        case "default":
            return <Button onClick={handleCreate}>Create</Button>;
        case "pending":
            return <Button disabled>Create</Button>;
        case "error":
            return <Button onClick={() => {
                reload();
                setState("default");
            }}>Confirm</Button>;
        case "done":
            return <Button onClick={() => {
                reload();
                setState("default");
            }}>Continue</Button>;
        }
    }

    const classes = useStyles();

	const updateInvitationCode = (newCode: string) => {
        if (newCode && newCode.trim() !== "") {
            if (isValidUrl(newCode)) {
                const link = new URL(newCode);
                const { pathname } = link;
                const pathnameList = pathname.split('/');

                if (pathnameList.length >= 3) {
                    if (pathnameList[1] === 'invitation') {
                        setCode(pathnameList[2])
                    }
                }
            } else {
                setCode(newCode);
            }
		}
	}
    return <div className={classes.root}>
        <PageTitle>Welcome to Attack Bound</PageTitle>
		<Typography>
			You do not have a subscription. 
			To use AttackBound, you must either create a new subscription, 
			or be invited to an existing subscription.
		</Typography>
		<div className={classes.box}>
			<Card className={classes.card} variant="outlined" style={{ flex: 2 }}>
				{
					step === "subscription" ? 
						<>
							<CardHeader title="Create Subscription"></CardHeader>
							<CardContent>{content()}</CardContent>
							<CardActions>{actions()}</CardActions>
						</>
						:
						<>
							{/* Add invitation page */}
							<InvitationPage reload={reload} />
						</>
				}
			</Card>
			<div className={classes.or} style={{ flex: 0 }}>
				<Typography>OR</Typography>
			</div>
            <Card className={classes.card} variant="outlined" style={{ flex: 2 }}>
                <AcceptInvitation code={code} done={reload}>
                    <TextField label="Invite Code or Link" style={{width: '50%'}} onChange={e => updateInvitationCode(e.target.value)} />
                </AcceptInvitation>
            </Card>
		</div>
    </div>
}