import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { PageData } from "src/services/postgrest-provider";
import { LinkButton } from "../LinkButton/LinkButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: theme.spacing(1),
            alignItems: "flex-end",
            padding: theme.spacing(1),
        },
        total: {
            lineHeight: 1,
        },
        pages: {
            display: "flex",
            gap: theme.spacing(1),
            flexWrap: "wrap",
            alignItems: "flex-end",
        },
        page: {
           lineHeight: 1,
        },
        active: {
            fontSize: "1.1rem",
            textDecoration: "underline",
            color: theme.palette.primary.main,
        },
    })
);

type Page<T> = PageData<T>["pages"][number];

type Props<T> = {
    data: PageData<T>;
    pageSelected: (page: Page<T>) => void;
    item?: string;
};

export const PageControl = <T,>({ data, pageSelected, item = "item" }: Props<T>): JSX.Element => {
    const classes = useStyles();

    // TODO: Add logic for displaying large pages.
    const paginationList = (
        <>
            {data.pages.map((page) => (
                <LinkButton
                    key={page.number}
                    className={`${classes.page} ${
                        page.number === data.current.number
                            ? classes.active
                            : ""
                    }`}
                    color="inherit"
                    onClick={() => pageSelected(page)}
                >
                    {page.number}
                </LinkButton>
            ))}
        </>
    );

    return (
        <div className={classes.root}>
            <span className={classes.total}>
                {data.total} {item}{data.total !== 1 && "s"} in total
            </span>
            <div className={classes.pages}>
                { paginationList }
            </div>
        </div>
    );
};
