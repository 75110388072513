import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react"

type Props = {
    children: React.ReactNode
    padding?: number
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    }
}));
  

export const Splash: FC<Props> = ({ children, padding = 0 }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    return <div className={classes.root} style={{ padding: theme.spacing(padding) }}>
        {children}
    </div>
}

export default Splash