import React, { useCallback, useState } from "react";
import {
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@material-ui/lab";
import {
    Box,
    Button,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { WelcomeStepIndex } from "src/shared/enums";
import { useSubscription } from "../../services/subscription-provider/SubscriptionProvider";
import { useApi } from "../../services/api-provider/ApiProvider";

type Props = {
    step: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            minWidth: "100%",
            maxWidth: "100%",
        },
        completedStyle: {
            backgroundColor: theme.palette.supplementary.dark,
        },
        separator: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        description: {
            margin: `${theme.spacing(0.8)}px 0 ${theme.spacing(1.5)}px 0`,
            wordWrap: "break-word",
            textOverflow: "ellipsis",
            width: "90%",
            fontSize: "0.9rem",
        },
        hidden: {
            display: "none",
        },
        inactive: {
            color: theme.palette.text.disabled,
        },
        footer: {
            fontSize: "0.8rem",
            color: theme.palette.text.disabled,
            marginTop: theme.spacing(9),
            "& a, & a:visited": {
                color: `${theme.palette.text.disabled} !important`,
            },
        },
        buttonRow: {
            display: "flex",
            marginTop: theme.spacing(1),
            alignItems: "center",
            "& > * + *": {
                marginLeft: theme.spacing(1),
            }
        }
    })
);

export const WelcomeCompleted: React.FC<Props> = ({ step }) => {
    const classes = useStyles();
    const isActiveStep = step === WelcomeStepIndex.Completed;
    const subscription = useSubscription();
    const api = useApi();
    const [dismissState, setDismissState] = useState<"none" | "pending" | "failed">("none");

    const dismiss = useCallback(() => {
        setDismissState("pending");
        api.Patch(`/subscriptions?uuid=eq.${subscription.current.uuid}`, {
            show_welcome: false,
        }).then(res => {
            if (res.type === "success") {
                subscription.refresh();
            } else {
                console.error("Update set_welcome", res.message);
                setDismissState("failed");
            }
        });
    }, [subscription, api]);

    return (
        <TimelineItem className={isActiveStep ? "" : classes.inactive}>
            <TimelineSeparator className={classes.separator}>
                <TimelineDot
                    color={isActiveStep ? "primary" : "grey"}
                    variant={isActiveStep ? "default" : "outlined"}
                ></TimelineDot>
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
                <Typography variant="h6" component="div">
                    <Box component="span" fontWeight="fontWeightBold">
                        Continuous Threat Monitoring
                    </Box>
                </Typography>
                <Typography className={classes.description}>
                    On a continual basis, our predictive threat engine gathers the
                    latest threat exposure information for your organization, allowing
                    you to gauge your current and historical risk.
                </Typography>
                { isActiveStep && <>
                     <Typography>
                        Depending on your organization&apos;s current threat exposure, you will begin
                        seeing issues in your dashboard shortly.
                    </Typography>
                    
                    <div className={classes.buttonRow}>
                        <Button disabled={dismissState === "pending"} color="primary" variant="contained" onClick={dismiss}>
                            Continue
                        </Button>
                        { dismissState === "pending" && <CircularProgress size="1rem" /> }
                        { dismissState === "failed" && (
                            <Typography variant="body2">Request failed, please try again.</Typography>
                        )}
                    </div>
                    <Typography className={classes.footer}>
                        Or would you like to{" "}
                        <Link to="/target-domains">add another domain</Link>.
                    </Typography>
                </>}
            </TimelineContent>
        </TimelineItem>
    );
};
