import { createStyles, makeStyles, Theme } from "@material-ui/core"
import React, { FC } from "react"
import { Route, Routes } from "react-router-dom";
import { Tabs } from "src/elements/Tabs/Tabs";
import { useSubscription } from "src/services/subscription-provider/SubscriptionProvider";
import { Welcome } from "../welcome/Welcome";
import { MostRecent } from "./MostRecent";
import {WeeklyReport} from "./WeeklyReport";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            minHeight: "100%",
        },
        content: {
            overflowX: "hidden",
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(2),
            alignItems: "stretch",
            "& > *:first-child": {
                display: "flex",
                flexGrow: 1,
            },
            "& > *:last-child": {
                display: "flex",
                gap: theme.spacing(2),
            }
        }
    })
);

export const Dashboard: FC = ({ children = <DashboardContent/> }) => {

    const subscription = useSubscription();

    if (subscription.current.show_welcome) {
        return (
            <Welcome/>
        );
    }

    return (
        <>
            {children}
        </>
    );
}

const DashboardContent: FC = () => {
    return (
        <Routes>
            <Route path="*" element={<DashboardContentElement/>}/>
        </Routes>
    );
}

const DashboardContentElement: FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Tabs tabs={["Most Recent", "7 Days"]}>
                <MostRecent className={classes.content} />
                <WeeklyReport />
            </Tabs>
        </div>
    );
}

export default Dashboard
