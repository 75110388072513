import {
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import React from "react";
import { LeakCredentialsWithApi } from "./LeakCredentialsWithApi";
import { ExposedUsersWithApi } from "./ExposedUsersWithApi";
import { Tabs } from "src/elements/Tabs/Tabs";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            minHeight: "100%",
            padding: theme.spacing(2),
            background: theme.palette.background.default,
        },
    })
);

export const SocialThreatIntelligenceReport: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Tabs tabs={["Leaked Credentials", "Exposed Users"]}>
                <LeakCredentialsWithApi />
                <ExposedUsersWithApi />
            </Tabs>
        </div>
    );
};
