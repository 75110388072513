import React from "react";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@material-ui/lab";
import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import { WelcomeStepIndex } from "src/shared/enums";

type Props = {
    step: number;
    skipInvite: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            minWidth: "100%",
            maxWidth: "100%",
        },
        td: {
            height: "100%",
            verticalAlign: "top",
        },
        completedStyle: {
            backgroundColor: theme.palette.primary.dark,
        },
        checkIcon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        separator: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1.3),
        },
        description: {
            margin: `${theme.spacing(0.8)}px 0 ${theme.spacing(1.5)}px 0`,
            fontSize: "0.9rem",
        },
        button: {
            marginBottom: theme.spacing(1.5),
        },
        hidden: {
            display: "none",
        },
        inactive: {
            color: theme.palette.text.disabled,
        },
    })
);

export const WelcomeInviteUsers: React.FC<Props> = ({ step, skipInvite }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const isActiveStep = step === WelcomeStepIndex.InviteUsers;
    const isCompletedStep = step > WelcomeStepIndex.InviteUsers;

    const sendInvitationAction = () => {
        skipInvite();
        navigate("/users");
    };

    return (
        <TimelineItem className={isActiveStep ? "" : classes.inactive}>
            <TimelineSeparator
                className={isCompletedStep ? "" : classes.separator}
            >
                <TimelineDot
                    color={isActiveStep || isCompletedStep ? "primary" : "grey"}
                    variant={
                        isActiveStep || isCompletedStep ? "default" : "outlined"
                    }
                >
                    {isCompletedStep && (
                        <CheckIcon className={classes.checkIcon} />
                    )}
                </TimelineDot>
                <TimelineConnector
                    className={
                        isActiveStep || isCompletedStep
                            ? classes.completedStyle
                            : ""
                    }
                />
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
                <table>
                    <tbody>
                        <tr>
                            <td className={classes.td}>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">3.</Box>
                                </Typography>
                            </td>
                            <td className={classes.td}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    display="inline"
                                >
                                    <Box
                                        fontWeight="fontWeightBold"
                                        display="inline"
                                    >
                                        Invite others
                                    </Box>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    display="inline"
                                >
                                    <Box
                                        fontWeight="fontWeightRegular"
                                        display="inline"
                                    >
                                        {" "}
                                        * optional
                                    </Box>
                                </Typography>
                            </td>
                        </tr>
                        <tr className={isCompletedStep ? classes.hidden : ""}>
                            <td></td>
                            <td>
                                <Typography className={classes.description}>
                                    Invite more people to manage and review the
                                    threat reports.
                                </Typography>
                                <Button
                                    className={`${classes.button} ${
                                        isActiveStep ? "" : classes.hidden
                                    }`}
                                    variant="contained"
                                    color="primary"
                                    onClick={sendInvitationAction}
                                >
                                    Send The Invitation
                                </Button>
                                <Button
                                    className={`${classes.button} ${
                                        isActiveStep ? "" : classes.hidden
                                    }`}
                                    color="primary"
                                    onClick={skipInvite}
                                >
                                    Skip Now
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TimelineContent>
        </TimelineItem>
    );
};
