import React, { FC } from "react";
import { HeroWidget } from "../issue-summary/hero-widget/HeroWidget";
import { ScanDetailsWithApi } from "../side-widgets/scan-details/organisms/ScanDetailsWithApi";
import { WhatsNewWithApi } from "../side-widgets/whats-new/WhatsNewWithApi";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Tabs } from "src/elements/Tabs/Tabs";
import { IssueTableWidget } from "../issue-summary/IssueTable/IssueTableWidget";
import { IssuesByDomain } from "../issue-summary/IssuesByDomain/IssuesByDomain";

const useStyles = makeStyles((theme: Theme) => createStyles({
    row: {
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(2),
        alignItems: "stretch",
    },
    col: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        alignItems: "stretch",
    },
    issues: {
        flexGrow: 1,
    }
}));

type Props = {
    className?: string;
}

const ISSUE_COUNT = 13;

export const MostRecent: FC<Props> = ({ className }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.row} ${className}`}>
            <div className={classes.col}>
                <HeroWidget />
                <Tabs tabs={["Open", "Closed"]} className={classes.issues}>
                    <IssueTableWidget state="open" count={ISSUE_COUNT}/>
                    <IssueTableWidget state="closed" count={ISSUE_COUNT}/>
                </Tabs>
            </div>
            <div className={classes.col}>
                <div>
                    <Typography component="h2" variant="h6">By Domain</Typography>
                    <IssuesByDomain/>
                </div>
                <div>
                    <Typography component="h2" variant="h6">What&apos;s New</Typography>
                    <WhatsNewWithApi />
                </div>
                <div>
                    <Typography component="h2" variant="h6">Analysis Details</Typography>
                    <ScanDetailsWithApi />
                </div>
            </div>
        </div>
    );
}
