import {
    Button,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import React from "react";
import { Price } from "src/elements/Price/Price";
import { PlanExpandProduct } from "src/misc/codecs/stripe/Plan";

// Material UI styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& tr > th": {
                verticalAlign: "top",
                padding: theme.spacing(2),
            },
        },
        selectButton: {
            width: "100%",
        },
        activePlanTableCell: {
            backgroundColor: theme.palette.action.disabledBackground,
        },
        disable: {
            pointerEvents: "none",
            cursor: "default",
        },
        detail: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1.5),
        },
        productPrice: {
            color: theme.palette.primary.main,
        },
    })
);

/***************************************
 *  Table header with select button
 ***************************************/
type ButtonProps = {
    activePlan: string | undefined;
    price_id: string;
    checkout: (priceId: string) => void;
};

const PlanHeaderButton: React.FC<ButtonProps> = ({
    activePlan,
    price_id,
    checkout,
}) => {
    const classes = useStyles();
    
    const hasActivePlan = activePlan !== undefined;
    const active = activePlan === price_id;

    // Handle button onClick to create subscription if it doesn't exist
    const handleOnClick = (priceId: string) => {
        if (!hasActivePlan) {
            checkout(priceId);
        }
    };

    return (
        <Button
            size="small"
            variant={active ? "contained" : "outlined"}
            color="primary"
            className={`${classes.selectButton} ${hasActivePlan && active ? classes.disable : ""}`}
            disabled={hasActivePlan && !active}
            onClick={() => handleOnClick(price_id)}
        >
            {active ? "Current Plan" : "Select"}
        </Button>
    );
};

type Props = {
    plans: PlanExpandProduct[];
    activePlan?: string;
    checkout: (priceId: string) => void;
};

export const PlanTableHeader: React.FC<Props> = ({
    plans,
    activePlan,
    checkout,
}) => {
    const classes = useStyles();
    const active = (id: string) => id === activePlan ? classes.activePlanTableCell : undefined;
    return (
        <thead className={classes.root}>
            <tr>
                <th>
                    <Typography variant="h2">Plans & pricing</Typography>
                </th>
                {plans.map(plan => (
                    <th key={plan.id} className={active(plan.id)}>
                        <div className={classes.detail}>
                            <Typography variant="h2">
                                {plan.product.name}
                            </Typography>
                            <Typography variant="h2" className={classes.productPrice}>
                                <Price amount={plan.amount} currency={plan.currency}/> <span style={{fontSize: "0.8rem"}}>/ {plan.interval}</span>
                            </Typography>
                            <PlanHeaderButton
                                activePlan={activePlan}
                                price_id={plan.id}
                                checkout={checkout}
                            />
                        </div>
                        
                    </th>
                ))}
            </tr>
        </thead>
    );
};
