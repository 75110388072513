import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FullReportLoading } from '../molecules/FullReportLoading';
import { Error } from '../molecules/Error';
import { Row, RowSlice, usePostgrest } from 'src/services/postgrest-provider';
import { Fade, Typography } from '@material-ui/core';
import { DownloadReportBtn } from './DownloadReportBtn';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: theme.palette.table.head.background,
      color: theme.palette.table.head.text,
    }
  })
);

type IssueType = Row<"issues">["type"];

type Props = {
  types: IssueType[];
}

type DataRow = RowSlice<"issues", "title" | "desc" | "last_seen" | "severity">;

type State = { key: "loading" }
           | { key: "ok", data: DataRow[] }
           | { key: "error", error: string }

export const Issues: React.FC<Props> = ({ types }) => {

  const postgrest = usePostgrest();
  const classes = useStyles();
  const [state, setState] = useState<State>({ key: "loading" });

  const typeParam = encodeURIComponent(`in.(${types.join(",")})`);

  useEffect(() => {
    postgrest.GetTableSlice(
      "issues",
      ["title", "severity", "desc", "last_seen"],
      `type=${typeParam}&resolved=is.null`
    ).then(res => {
      if (res.type === "error") {
        setState({ key: "error", error: res.message });
      } else {
        setState({ key: "ok", data: res.data });
      }
    });
  }, [postgrest, typeParam]);

  if (state.key === "loading") {
    return <FullReportLoading />
  }

  if (state.key === "error") {
    return <Error message={state.error} />;
  }

  return (
      <Fade in={!!state.data}>
          <>
              <DownloadReportBtn reportName="attack_surface"/>
              <TableContainer>
                  <Table className={classes.table} aria-label="TLS table">
                      <TableHead className={classes.head}>
                          <TableRow>
                              <TableCell>
                                  <Typography variant="subtitle2">
                                      Issue
                                  </Typography>
                              </TableCell>
                              <TableCell>
                                  <Typography variant="subtitle2">
                                      Severity
                                  </Typography>
                              </TableCell>
                              <TableCell>
                                  <Typography variant="subtitle2">
                                      Description
                                  </Typography>
                              </TableCell>
                              <TableCell>
                                  <Typography variant="subtitle2">
                                      Last Seen
                                  </Typography>
                              </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {state.data.map((row, index) => (
                              <TableRow key={index}>
                                  <TableCell>{row.title}</TableCell>
                                  <TableCell>{row.severity}</TableCell>
                                  <TableCell>{row.desc}</TableCell>
                                  <TableCell>
                                      {row.last_seen.toLocaleDateString()}
                                  </TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                  </Table>
              </TableContainer>
          </>
      </Fade>
  );
}
