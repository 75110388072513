import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => createStyles({
    link: {
       border: "none",
       background: "none",
       padding: 0,
       color: theme.palette.primary.main,
       font: "inherit",
       letterSpacing: "inherit",
       display: "inline",
       boxSizing: "content-box",
       height: "fit-content",
       cursor: "pointer",
       textAlign: "start",
       "&:hover": {
           textDecoration: "underline",
       }
    },
    "color-primary": {
        color: theme.palette.primary.main,
    },
    "color-secondary": {
        color: theme.palette.supplementary.main,
    },
    "color-initial": {
        color: "blue",
    },
    "color-inherit": {
        color: "inherit",
    },
    "color-error": {
        color: theme.palette.error.main,
    }
}));

type Color = "primary" | "secondary" | "initial" | "inherit" | "error";

type Props = {
    color?: Color;
    onClick: () => void;
    className?: string;
}

export const LinkButton: React.FC<Props> = ({ onClick, color = "primary", children, className }) => {
    const styles = useStyles();
    const classes = [
        styles.link,
        styles[`color-${color}`],
    ];
    if (className) {
        classes.push(className);
    }
    return (
        <button onClick={onClick} className={classes.join(" ")}>
            {children}
        </button>
    );
}