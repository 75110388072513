import { createStyles, makeStyles, TableSortLabel } from "@material-ui/core";
import React from "react";
import { Issue, Ordering } from "./IssueTable";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

type HeadCell = {
    id: keyof Issue;
    label: string;
    width: string;
};

type Props = {
    sort: Ordering;
    onRequestSort?: (order: Ordering) => void;
};

const headCells: HeadCell[] = [
    { id: "title", label: "Name", width: "50%" },
    { id: "category", label: "Category", width: "20%" },
    { id: "type", label: "Type", width: "10%" },
    { id: "first_seen", label: "Age", width: "10%" }, // TODO: Temporary using "first_seen", will update to "age" after column is created in "Issues" table
    { id: "severity", label: "Severity", width: "10%" },
];

const useStyles = makeStyles(() =>
    createStyles({
        thead: {
            display: "table",
            tableLayout: "fixed",
            width: "100%"
        }
    })
);
export const IssueListTableHead: React.FC<Props> = ({
    sort,
    onRequestSort,
}) => {
    const classes = useStyles();
    return (
        <thead className={classes.thead}>
            <tr>
                {headCells.map((head) => (
                    <th
                        key={head.id}
                        style={{ top: "0", position: "sticky", width: `${head.width}` }}
                        scope="col"
                    >
                        { onRequestSort ? (
                            <TableSortLabel
                                IconComponent={ArrowDropDownIcon}
                                active={sort.column === head.id}
                                direction={sort.column === head.id ? sort.direction : "asc"}
                                onClick={() =>
                                    onRequestSort({
                                        column: head.id,
                                        direction: sort.column === head.id && sort.direction === "asc"
                                            ? "desc"
                                            : "asc"
                                    })
                                }
                            >
                                { head.label }
                            </TableSortLabel>
                        ) : (
                            head.label
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    );
};
