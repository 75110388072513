import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { ServiceProvidersWithApi } from "./ServiceProvidersWithApi";
import { Tabs } from "src/elements/Tabs/Tabs";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            minHeight: "100%",
            padding: theme.spacing(2),
            background: theme.palette.background.default,
        },
    })
);

export const DigitalSupplyChainReport: React.FC = () => {
    const classes = useStyles();

    const children: JSX.Element[] = [
        <ServiceProvidersWithApi key="service-provider"/>
    ];

    return (
        <div className={classes.root}>
            <Tabs tabs={["Service Provider"]}>
                {children}
            </Tabs>
        </div>
    );
};
