import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { createStyles, makeStyles, Theme, Typography, Link, Table, TableHead, TableBody, TableRow, TableCell, useTheme, TableFooter, ButtonGroup, Button, CircularProgress } from '@material-ui/core';
import { useApi, parseData } from '../../../services/api-provider';
import { CurrencyCodec, date } from "src/misc/codecs";
import { format } from 'date-fns';
import * as t from "io-ts";
import { capitalize } from 'src/misc/format/capitalize';
import { LinkButton } from 'src/elements/LinkButton/LinkButton';
import { Price } from 'src/elements/Price/Price';
import { Subscription } from 'src/misc/codecs/stripe/Subscription';


const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        "& > p": {
            padding: theme.spacing(2),
        },
        "& th": {
            background: theme.palette.table.head.background,
        },
        "& td,th": {
            borderBottom: "none",
        },
        "& td > a": {
            color: theme.palette.info.main,
        }
    },
    refreshing: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    tableHeader: {
        color: theme.palette.table.head.text,
        background: theme.palette.table.head.background,
    },
}));

const formatDate = (date: Date) => format(date, "MMM d, y");

export const InvoiceCodec = t.interface({
    amount: t.number,
    amountRemaining: t.number,
    currency: CurrencyCodec,
    date: date,
    hostedInvoiceUrl: t.string,
    invoiceId: t.string,
    invoicePdf: t.string,
    periodEnd: date,
    periodStart: date,
    status: t.union([t.literal("deleted"), t.literal("draft"), t.literal("open"), t.literal("paid"), t.literal("uncollectible"), t.literal("void")])
});

type Invoice = t.TypeOf<typeof InvoiceCodec>;

type State = { key: "loading" }
           | { key: "error", message: string }
           | { key: "none" }
           | { key: "ok", invoices: Invoice[][], page: number }
           | { key: "refreshing", invoices: Invoice[][], page: number };

type Props = {
    subscription: Subscription | null; // Invoices will be refreshed when subscription changes.
}

export const InvoiceList: FC<Props> = ({ subscription }) => {

    const api = useApi();
    const classes = useStyles();
    const theme = useTheme();
    const [state, setState] = useState<State>({ key: "loading" });

    const load = useCallback(() => {
        setState(s => s.key === "ok" ? { ...s, key: "refreshing" } : { key: "loading" });
        api.Get("/api/stripe/invoices").then(parseData(t.array(InvoiceCodec))).then(res => {
            if (res.type === "error") {
                console.error("error fetching invoices", res.message);
                setState({ key: "error", message: res.message });
            } else if (res.data.length < 1) {
                setState({ key: "none" });
            } else {
                setState({ key: "ok", invoices: toPages(10, res.data), page: 0 });
            }
        });
    }, [api]);

    const setPage = (page: number) => setState(state => {
        if (state.key !== "ok") return state;
        if (page < 0 || state.invoices.length <= page) return state;
        return { ...state, page: page };
    });

    // Load on mount and whenever the subscription changes
    useEffect(() => load(), [load, subscription]);

    const content: JSX.Element = useMemo(() => {
        switch (state.key) {
        case "loading":
            return <Typography>Loading...</Typography>;
        case "error":
            return (
                <Typography>
                    {"Couldn't load invoices. "}
                    <LinkButton onClick={() => load()}>Try again.</LinkButton>
                </Typography>
            );
        case "none":
            return <Typography>No invoices found.</Typography>
        case "refreshing":
        case "ok":
            return (
                <Table>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell><Typography variant="subtitle2">Transaction</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">Date</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">Billing Period</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">Amount</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">Status</Typography></TableCell>
                            <TableCell className={classes.refreshing}>
                                <Typography variant="subtitle2">Action</Typography>
                                { state.key === "refreshing" && (
                                    <CircularProgress color="inherit" size={16} />
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.invoices[state.page].map(invoice => (
                            <TableRow key={invoice.invoiceId}>
                                <TableCell>{invoice.invoiceId}</TableCell>
                                <TableCell>{formatDate(invoice.date)}</TableCell>
                                <TableCell>{formatDate(invoice.periodStart)} - {formatDate(invoice.periodEnd)}</TableCell>
                                <TableCell>
                                    <Price amount={invoice.amount} currency={invoice.currency}/>
                                    {invoice.amountRemaining > 0 && invoice.status == "open" && (
                                        <div style={{fontSize:"0.7rem", color: theme.palette.text.secondary}}>
                                            <Price amount={invoice.amountRemaining} currency={invoice.currency}/> unpaid
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <span style={{color: theme.palette[statusColour(invoice.status)].main}}>
                                        {capitalize(invoice.status)}
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <Link target="_blank" href={invoice.hostedInvoiceUrl}>View</Link>
                                    {" / "}
                                    <Link target="_blank" href={invoice.invoicePdf}>Download</Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={5}>
                                { state.invoices.length > 1 && (
                                    <ButtonGroup disableElevation>
                                        {state.invoices.map((_, i) => (
                                            <Button 
                                                color={state.page === i ? "primary" : "default"} 
                                                variant={state.page === i ? "contained" : "text"}
                                                onClick={() => setPage(i)} 
                                                key={i}
                                            >
                                                {i+1}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                )}
                            </TableCell>
                            <TableCell>
                                {state.invoices.reduce((total, v) => total + v.length, 0)} invoices in total
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            );
        }
    }, [state, classes.tableHeader, classes.refreshing, load, theme.palette]);

    return (
        <div className={classes.container}>
            {content}
        </div>
    );
}

type ThemeColour = "primary" | "secondary" | "success" | "warning" | "error" | "info";

const statusColour = (status: Invoice["status"]): ThemeColour => {
    switch (status) {
    case "draft":
    case "deleted":
    case "void":
        return "info";
    case "open":
        return "warning";
    case "uncollectible":
        return "error";
    case "paid":
        return "success";
    }
}

function toPages<T = unknown>(size: number, xs: T[]): T[][] {
    const result: T[][] = [];
    while (xs.length > 0) {
        result.push(xs.splice(0, 10));
    }
    return result;
}
