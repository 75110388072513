import React from "react";
import { useTheme } from "@material-ui/core";
import { LabelData } from "./const";
import { formatCount, RADIUS } from "./utils";

/** @typedef {object} ValueLabelProps React props for `<Label />` component*/
type ValueLabelProps = {
    /**
     * Color for svg polyline stroke
     */
    stroke?: string;
    /**
     * Width of svg polyline
     */
    strokeWidth?: string;
    /**
     * Circle's fill color
     */
    circleFill: string;
    /**
     * Label's color
     */
    textFill: string;
    /**
     * Display data value
     */
    data: LabelData[];
}

/**
 * Label on svg line chart
 *
 * @param {ValueLabelProps} {
 *     stroke,
 *     strokeWidth = "1",
 *     circleFill,
 *     textFill,
 *     data,
 * }
 * @return {JSX.Element} 
 */
export const Label: React.FC<ValueLabelProps> = ({
    stroke,
    strokeWidth = "1",
    circleFill,
    textFill,
    data,
}) => {
    const theme = useTheme();

    return (
        <g>
            { data.map((item, index) => {
                return (
                    <g key={index}>
                        {/** Circles */}
                        <circle
                            cx={item.x}
                            cy={item.y}
                            r={RADIUS}
                            stroke={stroke || theme.palette.text.primary}
                            strokeWidth={strokeWidth}
                            fill={
                                index === data.length - 1 ? textFill : circleFill
                            }
                        />
                        {/** Value labels */}
                        <text
                            x={item.x}
                            y={item.y - 8}
                            fontSize="1.125rem"
                            fill={textFill}
                            textAnchor="middle"
                            alignmentBaseline="auto"
                        >
                            {typeof item.value === "number"
                                ? formatCount(Number(item.value))
                                : item.value}
                        </text>
                    </g>
                );
            })}
        </g>
    )
}

