import React from "react";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@material-ui/lab";
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { DomainStat } from "./Welcome";
import { WelcomeStepIndex } from "src/shared/enums";

type Props = {
    step: number;
    domainStat: DomainStat;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            minWidth: "100%",
            maxWidth: "100%",
        },
        td: {
            height: "100%",
            verticalAlign: "top",
        },
        completedStyle: {
            backgroundColor: theme.palette.primary.dark,
        },
        checkIcon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        separator: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1.3),
        },
        description: {
            margin: `${theme.spacing(0.8)}px 0 ${theme.spacing(1.5)}px 0`,
            wordWrap: "break-word",
            textOverflow: "ellipsis",
            width: "90%",
            fontSize: "0.9rem",
        },
        hidden: {
            display: "none",
        },
        inactive: {
            color: theme.palette.text.disabled,
        },
        redText: {
            color: theme.palette.primary.main,
        },
    })
);

export const WelcomeVerifyDomain: React.FC<Props> = ({ step, domainStat }) => {
    const classes = useStyles();

    const isActiveStep = step === WelcomeStepIndex.VerifyDomain;
    const isCompletedStep = step > WelcomeStepIndex.VerifyDomain;

    return (
        <TimelineItem className={isActiveStep ? "" : classes.inactive}>
            <TimelineSeparator
                className={isCompletedStep ? "" : classes.separator}
            >
                <TimelineDot
                    color={isActiveStep || isCompletedStep ? "primary" : "grey"}
                    variant={
                        isActiveStep || isCompletedStep ? "default" : "outlined"
                    }
                >
                    {isCompletedStep && (
                        <CheckIcon className={classes.checkIcon} />
                    )}
                </TimelineDot>
                <TimelineConnector
                    className={
                        isActiveStep || isCompletedStep
                            ? classes.completedStyle
                            : ""
                    }
                />
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
                <table>
                    <tbody>
                        <tr>
                            <td className={classes.td}>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">2.</Box>
                                </Typography>
                            </td>
                            <td className={classes.td}>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">
                                        Verify domain ownership (
                                        {domainStat.verified}/{domainStat.total}
                                        )
                                    </Box>
                                </Typography>
                            </td>
                        </tr>
                        <tr className={isCompletedStep ? classes.hidden : ""}>
                            <td></td>
                            <td>
                                <Typography className={classes.description}>
                                    Add TXT record to the target domain. Once*
                                    it&lsquo;s published, AttackBound will start
                                    daily analyses on overall threat exposure
                                    for your organization.
                                </Typography>
                                <Typography className={classes.description}>
                                    *TXT Records are applied immediately, but{" "}
                                    <Box
                                        component="span"
                                        fontWeight={
                                            isActiveStep
                                                ? "fontWeightBold"
                                                : "fontWeightRegular"
                                        }
                                        display="inline"
                                    >
                                        it may take up to 2 hours
                                    </Box>{" "}
                                    to propagate across the internet.
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TimelineContent>
        </TimelineItem>
    );
};
