import {
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import React from "react";
import { DnsRecordWithApi } from "./DnsRecordWithApi";
import { EmailConfigIssuesWithApi } from "./EmailConfigIssuesWithApi";
import { ExposedServicesWithApi } from "./ExposedServicesWithApi";
import { Issues } from "./Issues";
import { Tabs } from "src/elements/Tabs/Tabs";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            minHeight: "100%",
            padding: theme.spacing(2),
            background: theme.palette.background.default,
        },
    })
);


export const AttackSurfaceReport: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Tabs tabs={["DNS Records", "TLS Issues", "Exposed Services", "Email Config Issues"]}>
                <DnsRecordWithApi />
                <Issues types={["cert_expired", "cert_trust"]} />
                <ExposedServicesWithApi />
                <EmailConfigIssuesWithApi />
            </Tabs>
        </div>
    );
};
