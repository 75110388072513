import React, { FC } from 'react';
import { Group } from '@visx/group';
import { Pack, hierarchy } from '@visx/hierarchy';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { SVGContainer } from './SVGContainer';
import { TinyColor } from '@ctrl/tinycolor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
     
    },
    target: {
      cursor: "pointer",
      '& > circle': {
        fill: theme.palette.supplementary.main,
      },
      '& > text': {
        fill: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        textAnchor: "middle",
        pointerEvents: "none",
        fontVariant: "small-caps",
        fontWeight: theme.typography.fontWeightBold
      },
      '&:hover': {
        '& > circle': {
          fill: new TinyColor(theme.palette.supplementary.main).darken(2).toHexString(),
        },
      }
    },
  }),
);

export type Bubble = {
  name: string;
  size: number;
}

export type Props = {
  bubbles: Bubble[];
  select: (selected: Bubble) => void
}

export const Bubbles: FC<Props> = ({bubbles, select}: Props) => {
  const pack = { children: bubbles, name: "root", size: 1 };
  const root = hierarchy<Bubble>(pack).sum(d => d.size);
  const classes = useStyles();

  return (
    <SVGContainer back={() => {null}}>
      {({width, height}) => (
        <Pack<Bubble> root={root} size={[width, height]}>
          {packData => {
            const circles = packData.descendants().slice(1);
            return (
              <Group top={0} left={0}>
                {circles.map((circle, i) => (
                  <Group
                    key={`circle-${i}`}
                    top={circle.y}
                    left={circle.x}
                    className={classes.target}
                  >
                    <circle onClick={() => select(circle.data)} r={circle.r} />
                    <text dy=".33em">{circle.data.name}</text>
                  </Group>
                ))}
              </Group>
            );
          }}
        </Pack>
      )}
    </SVGContainer>
  )
}