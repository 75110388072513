import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { format } from "date-fns";
import React from "react";
import { PaymentMethod } from "src/misc/codecs/stripe/PaymentMethod";
import { cardType } from "../Card/Card";
import { CardIcon } from "../Card/CardIcon";

type Props = {
    method: PaymentMethod;
};

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexWrap: "nowrap",
        },
        cardNumber: {
            flexGrow: 2,
            marginLeft: "1rem",
        },
        cardExpiryDate: {
            flexGrow: 2,
            textAlign: "right",
        },
    })
);

export const CardMenuItem: React.FC<Props> = ({ method }) => {
    const classes = useStyles();

    return (
        <>
        { 
            method && method.object === "payment_method" && method.type === "card" ? (
                <div className={classes.root}>
                    <CardIcon height={40} type={cardType(method)} />
                    <p className={classes.cardNumber}>**** **** **** {method.card.last4}</p>
                    <p className={classes.cardExpiryDate}>
                        {format(new Date(method.card.exp_year, method.card.exp_month-1), "MMM yyyy")}
                    </p>
                </div>
            ) : <></>
        }
        </>
    );
};
