import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, CircularProgress, createStyles, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { RowSlice, usePostgrest } from "src/services/postgrest-provider";
import { formatRFC3339, startOfYesterday } from "date-fns";
import { IssuesByDomainList } from "./IssuesByDomainList";
import { Skeleton } from "@material-ui/lab";
import { useApi } from "src/services/api-provider";
import { SubscriptionContext } from "src/services/subscription-provider/SubscriptionProvider";
import { ReportErrorDialog } from "./ReportErrorDialog";

export type DataRow = RowSlice<
    "issues",
    "target_domain" | "severity" | "target_domain_id"
>;

type DownloadState =
    | { key: "downloading" }
    | { key: "done" }
    | { key: "error"; error: string };

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        background: theme.palette.background.paper,
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        "& button": {
            alignSelf: "flex-start",
        }
    },
    actionGroup: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
    },
    downloadButton: {
        flex: 0.75,
        maxHeight: "2.25rem",
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
    },
    circular: {
        width: "100%",
        color: theme.palette.info.main,
    }
}));

export const IssuesByDomain: FC = () => {
    const postgrest = usePostgrest();
    const navigate = useNavigate();
    const { root, actionGroup, downloadButton, circular } = useStyles();
    const api = useApi();
    const subscriptionId = useContext(SubscriptionContext).current.uuid;

    type State =
        | { key: "loading" }
        | { key: "ok"; data: DataRow[] }
        | { key: "error"; error: string };

    const [state, setState] = useState<State>({ key: "loading" });
    const [downloadStatus, setDownloadStatus] = useState<DownloadState>({
        key: "done",
    });

    const getIssues = useCallback(async () => {
        // Most Recent issues
        const yesterday = encodeURIComponent(formatRFC3339(startOfYesterday()));

        postgrest
            .GetTableSlice(
                "issues",
                ["target_domain", "severity", "target_domain_id"],
                `or=(resolved.is.null,resolved.gt.${yesterday})&order=target_domain`
            )
            .then((res) => {
                if (res.type === "error") {
                    setState({ key: "error", error: res.message });
                } else {
                    setState({ key: "ok", data: res.data });
                }
            });
    }, [postgrest]);

    useEffect(() => {
        const fetchData = async () => {
            setState({ key: "loading" });
            await getIssues();
        }

        fetchData().catch(error => console.error(error));
    }, [getIssues]);


    const onDownload = () => {
        setDownloadStatus({ key: "downloading" });
        api.Request({
            url: "/api/report",
            method: "POST",
            data: {
                report_name: "detailed",
                subscription: subscriptionId,
            },
            headers: {
                "content-type": "application/pdf",
            },
            responseType: "arraybuffer",
        }).then((res) => {
            if (res.type === "success") {
                setDownloadStatus({ key: "done" });
                if (res.data instanceof ArrayBuffer) {
                    const a = window.document.createElement("a");
                    const file = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    a.href = window.URL.createObjectURL(file);
                    a.download = `detailed_${subscriptionId}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            } else if (res.type === "error") {
                setDownloadStatus({ key: "error", error: res.message });
            }
        });
    };

    return (
        <div className={root}>
            { state.key === "loading" && <Skeleton animation="wave" style={{ height: "120px", transform: "none"}} /> }
            { state.key === "ok" && <IssuesByDomainList data={state.data} /> }
            { state.key === "error" && <Typography variant="body1">{ state.error }</Typography>}
            <div className={actionGroup}>
                <Button color="primary" variant="outlined" onClick={() => navigate("/target-domains")}>
                    Add Domains
                </Button>
                <Tooltip title="Download Detailed Report">
                    <Button 
                        variant="outlined"
                        className={downloadButton}
                        onClick={() => onDownload()}
                    >
                        { downloadStatus.key === "downloading" ? <CircularProgress size="1.5rem" className={ circular }/> : "Report" }
                    </Button>
                </Tooltip>
            </div>
            <ReportErrorDialog hasError={downloadStatus.key === "error"} />
        </div>
    );
}