import React, { useState } from "react";
import {
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import {
    Button,
    CircularProgress,
    createStyles,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    Link,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { CardSelector, CardSelection } from "./CardSelector";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { LinkButton } from "src/elements/LinkButton/LinkButton";
import { PaymentHandler } from "../paymentHandler";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            width: "100%",
            padding: theme.spacing(2),
        },
        cardNumber: {
            width: "200px",
        },
        checkoutButton: {
            width: "100%",
            marginTop: theme.spacing(4),
            textTransform: "none",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        submitErrorMsg: {
            textAlign: "center",
            marginTop: "0.5rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
        },
    })
);

type Props = {
    handleClose: () => void;
    handlePayment: PaymentHandler;
};


type State =
    | { key: "initial" }
    | { key: "submitting" }
    | { key: "complete" }
    | { key: "error", message: string };

export const PaymentMethodDialogContent: React.FC<Props> = ({
    handleClose,
    handlePayment,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();

    const [paymentMethod, setPaymentMethod] = useState<CardSelection>({ type: "none" });

    const [state, setState] = useState<State>({ key: "initial" });

    if (!stripe || !elements) {
        return <Typography>Payment system unavailable. Please try again later.</Typography>;
    }

    const onDialogClose = () => {
        handleClose();
        setState({ key: "initial" });
    };

    // Handle submit/checkout action
    const handleSubmit = async () => {

        setState({ key: "submitting" });

        if (paymentMethod.type === "none") {
            return; // Button shouldn't be enabled, so do nothing.
        }

        const card = await paymentMethod.id();
        if (card.type === "error") {
            setState({ key: "error", message: card.message });
            return;
        }

        const res = await handlePayment(card.id);

        if (res.type === "error") {
            setState({ key: "error", message: res.message });
        } else {
            setState({ key: "complete" });
        }
    };

    

    const formDisabled = state.key === "submitting" || state.key === "complete";
    const submitDisabled = formDisabled || paymentMethod.type === "none";

    return (
        <div style={{ overflow: "hidden", width: "100%", height: "100%" }}>
            <DialogTitle id="card-dialog">
                Choose payment method
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onDialogClose}
                >
                    <HighlightOffIcon />
                </IconButton>
            </DialogTitle>
            <Grid container>
                <Grid item container xs={12}>
                    <Grid item xs={1}></Grid>
                    <Grid item container xs={10}>
                        <FormControl
                            variant="outlined"
                            className={classes.form}
                        >
                            <CardSelector
                                onSelect={setPaymentMethod}
                                disabled={formDisabled}
                            />
                            <Button
                                size="large"
                                variant="outlined"
                                color="primary"
                                className={classes.checkoutButton}
                                disabled={submitDisabled}
                                onClick={() => handleSubmit()}
                            >
                                {state.key === "submitting" ? (
                                    <CircularProgress
                                        size={25}
                                        color="primary"
                                    />
                                ) : state.key === "complete" ? (
                                    <CheckCircleOutlineIcon
                                        color="primary"
                                        style={{
                                            width: "25px",
                                            color: "green",
                                        }}
                                    />
                                ) : "Confirm"}
                            </Button>
                            {state.key === "complete" && (
                                <Typography variant="body2" color="textSecondary" style={{ marginTop: "1em" }}>
                                    Subscription updated.
                                    You may now <LinkButton onClick={onDialogClose}>close this dialog</LinkButton>.
                                </Typography>
                            )}
                            {state.key === "error" && (
                                <Typography
                                    variant="body1"
                                    component={"article"}
                                    className={classes.submitErrorMsg}
                                >
                                    Error: {state.message}.
                                </Typography>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid item xs={12} style={{ padding: "1rem" }}>
                    <Typography variant="body2" component={"article"}>
                        By clicking the &quot;Confirm&quot; button above, you are agreeing to our{" "}
                        <Link href="https://attackbound.com/terms-of-service/">
                            Terms of Service
                        </Link>{" "}
                        and acknowledge that you have read our{" "}
                        <Link href="https://attackbound.com/privacy-policy/">
                            Privacy Policy
                        </Link>
                        .
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};
