import { Button, CircularProgress, Typography, useTheme, CardHeader, CardContent, CardActions, makeStyles, Theme, createStyles } from '@material-ui/core'
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi, handleMany, handle } from '../../services/api-provider';
import * as t from "io-ts";


// Error messages
const INVALID_CODE_DESC = 'Please make sure your code/link is correct, or request a new invitation link from the person who invited you and try again.';
const CODE_IS_USED_DESC = 'Request a new invitation link from the person who invited you, and try again with the new link.';
const INCORRECT_EMAIL_DESC = 'Request a new invitation link from the person who invited you, and try again with the new link.';
const EMAIL_NOT_VERIFIED_DESC = 'Please verify your email address and try again.';
const TRY_AGAIN_DESC = 'Please try again in a few minutes.';


export enum ErrorCode {
    NoError,
    InvalidCode,
    CodeIsUsed,
    AlreadyMember,
    IncorrectEmail,
    EmailNotVerified,
    ServerError,
    ClientError,
}

const rejected = t.interface({
    code: t.union([
        t.literal(ErrorCode.NoError),
        t.literal(ErrorCode.InvalidCode),
        t.literal(ErrorCode.CodeIsUsed),
        t.literal(ErrorCode.AlreadyMember),
        t.literal(ErrorCode.IncorrectEmail),
        t.literal(ErrorCode.EmailNotVerified),
        t.literal(ErrorCode.ServerError),
        t.literal(ErrorCode.ClientError),
    ]),
    description: t.string,
});

type ErrResponse = t.TypeOf<typeof rejected>;


type State = { state: "initial" }
           | { state: "accepting" }
           | { state: "error", error: ErrResponse }
           | { state: "accepted" }

type Props = {
    code: string;
    done: () => void;
    children?: JSX.Element | Array<JSX.Element>;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    hint: {
        margin: `${theme.spacing(2)}px 0`
    },
}))

export const AcceptInvitation: React.FC<Props> = ({ code, done, children }: Props): JSX.Element => {

    const api = useApi();
    const [state, setState] = useState<State>({state: "initial"});

    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();

    const spinner = <CircularProgress size="18px" color="inherit" style={{padding: 3, color: theme.palette.action.active}}/>;

    const accept = useCallback(async () => {
        const result = await api.Post("/api/invitation/accept", { secret: code }).then(handleMany(
            handle(202, t.unknown, t.unknown),
            handle(400, rejected, t.unknown)
        ));
        if (result.type === "error") {
            setState({ state: "error", error: { code: ErrorCode.ServerError, description: "error making request" }});
        } else if (result.status === 400) {
            setState({ state: "error", error: result.data });
        } else {
            setState({ state: "accepted" });
        }
    }, [api, code]);

    const restart = () => setState({state: "initial"});
    const goToDashboard = () => navigate("/");
    

    const contentInitial = <>
        <Typography>You have been invited to join AttackBound. Press the button below to accept.</Typography>
    </>;

    const contentError = (err: ErrResponse) => {
        let hint = "";

        switch (err.code) {
            case ErrorCode.InvalidCode:
                hint = INVALID_CODE_DESC;
                break;
            case ErrorCode.CodeIsUsed:
                hint = CODE_IS_USED_DESC;
                break;
            case ErrorCode.IncorrectEmail:
                hint = INCORRECT_EMAIL_DESC;
                break;
            case ErrorCode.EmailNotVerified:
                hint = EMAIL_NOT_VERIFIED_DESC;
                break;
            case ErrorCode.ServerError:
            case ErrorCode.ClientError:
                hint = TRY_AGAIN_DESC;
                break;
        }

        return (
            <>
                <Typography variant="body1">There was an error accepting the invitation: {err.description}.</Typography>
                { hint !== "" && <Typography variant="body1" className={classes.hint}>{hint}</Typography>}
            </>
        )
    };

    const contentAccepted = <>
        <Typography>You have accepted the invitation. You may now continue into the app.</Typography>
    </>;

    const actionInitial = (loading: boolean) => <>
        <Button disabled={loading} onClick={accept}>Accept Invitation</Button>
        {loading && spinner}
    </>;

    const actionError = (code: number) => {
        switch (code) {
            case ErrorCode.InvalidCode:
            case ErrorCode.EmailNotVerified:
            case ErrorCode.ServerError:
            case ErrorCode.ClientError:
                return (
                    <>
                        <Button variant='outlined' onClick={restart}>Try Again</Button>
                        <Button variant='contained' onClick={goToDashboard}>Return to Dashboard</Button>
                    </>
                )
            case ErrorCode.CodeIsUsed:
            case ErrorCode.AlreadyMember:
            case ErrorCode.IncorrectEmail:
                return (
                    <Button variant='contained' onClick={goToDashboard}>Return to Dashboard</Button>
                )
        }

    }

    const actionAccepted = <>
        <Button onClick={done}>Continue</Button>
    </>;

    const content = (() => {
        switch (state.state) {
        case "initial":
        case "accepting":
            return contentInitial;
        case "error":
            return contentError(state.error);
        case "accepted":
            return contentAccepted;
        }
    })();

    const action = (() => {
        switch (state.state) {
        case "initial":
            return actionInitial(false);
        case "accepting":
            return actionInitial(true);
        case "error":
            return actionError(state.error.code);
        case "accepted":
            return actionAccepted;
        }
    })();

    return (
      <>
        <CardHeader title="Accept Invite"></CardHeader>
        <CardContent>
          {content}
          {children}
        </CardContent>
        <CardActions>{action}</CardActions>
      </>
    );
}